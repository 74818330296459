<template>
  <HelloDashboard />
</template>

<script>
import HelloDashboard from "../components/HelloDashboard";

export default {
  components: {
    HelloDashboard,
  },
};
</script>
