<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout align-start>
      <v-flex>
        <template v-if="esAdministrador || esCaja">
          <v-row>
            <v-col cols="6" md="3" sm="3">
              <v-card class="mx-auto text-center" color="green lighten-2" dark max-width="600">
                <v-card-text>
                  <v-sheet color="rgba(0, 0, 0, .12)">
                    <div class="text-lg-h6">{{ common.roundOut(ingresos,2) | formatearNumero }}</div>
                  </v-sheet>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn block text x-small @click="irMovimiento()">Ingresos</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="6" md="3" sm="3">
              <v-card class="mx-auto text-center" color="red lighten-2" dark max-width="600">
                <v-card-text>
                  <v-sheet color="rgba(0, 0, 0, .12)">
                    <div class="text-lg-h6">{{ common.roundOut(egresos,2) | formatearNumero }}</div>
                  </v-sheet>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn block text x-small @click="irMovimiento()">Egresos</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="6" md="3" sm="3">
              <v-card class="mx-auto text-center" color="blue-grey lighten-2" dark max-width="600">
                <v-card-text>
                  <v-sheet color="rgba(0, 0, 0, .12)">
                    <div class="text-lg-h6">{{ cantidadCPE }}</div>
                  </v-sheet>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn block text x-small @click="irVentas()">Cantidad CPE</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="6" md="3" sm="3">
              <v-card class="mx-auto text-center" color="brown lighten-2" dark max-width="600">
                <v-card-text>
                  <v-sheet color="rgba(0, 0, 0, .12)">
                    <div class="text-lg-h6">{{ common.roundOut(montoCPE,2) | formatearNumero }}</div>
                  </v-sheet>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn block text x-small @click="irVentas()">Montos CPE</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </template>
        <v-row align="center" justify="center">
          <v-col cols="12" md="6">
            <v-card class="elevation-3">
              <v-card-text>
                <div>
                  <canvas id="ventasBar"></canvas>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="elevation-3">
              <v-card-text>
                <div>
                  <canvas id="comprasBar"></canvas>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6">
            <v-card class="elevation-3">
              <v-card-text>
                <div>
                  <canvas id="productosDoughnut"></canvas>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import Chart from "chart.js";
import { mapState, mapGetters } from "vuex"; //Importamos para llamar a los elementos

export default {
  data() {
    return {
      // Variables total dia
      resumen_dia: [],
      ingresos: 0,
      egresos: 0,
      // variables total meses
      ventas: [],
      compras: [],
      productos: [],

      nombreMesesVenta: [],
      totalMesesVenta: [],
      nombreMesesCompra: [],
      totalMesesCompra: [],
      nombreMesesProducto: [],
      totalMesesProducto: [],

      cantidadCPE: 0,
      montoCPE: 0,
    };
  },
  computed: {
    ...mapState(["usuario"]), // Llamamos a state para usuario
    ...mapGetters(["esAdministrador", "esCaja"]),
  },

  created() {
    this.totalDia();
    this.comprobantesEmitidos();
    this.anualComprasVentas();
    this.productosMasVendidos();
  },

  methods: {
    irMovimiento() {
      this.$router.push({ name: "movimiento_diario" }).catch(() => { });
    },

    irVentas() {
      this.$router.push({ name: "ventas" }).catch(() => { });
    },

    comprobantesEmitidos() {
      let me = this;
      var cantidadCPEArray = [];
      axios.get("api/Movimientos/totalCPE", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
        },
      })
        .then(function (response) {
          cantidadCPEArray = response.data;
          for (var i = 0; i < cantidadCPEArray.length; i++) {
            me.cantidadCPE = me.cantidadCPE + cantidadCPEArray[i].cantidad;
            me.montoCPE = me.montoCPE + cantidadCPEArray[i].monto;
          }
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    totalDia() {
      let me = this;
      var valorDiaArray = [];

      axios.get("api/Movimientos/MovimientoDia", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
        },
      })
        .then(function (response) {
          valorDiaArray = response.data;
          for (var i = 0; i < valorDiaArray.length; i++) {
            me.ingresos =
              me.ingresos +
              (valorDiaArray[i].es == "E" ? valorDiaArray[i].valor : 0);
            me.egresos =
              me.egresos +
              (valorDiaArray[i].es == "S" ? valorDiaArray[i].valor : 0);
          }
          //console.log(me.ingresos,me.egresos);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    cargarGraficoBar() {
      let me = this;
      let mesnv = "";
      let mesnc = "";

      me.compras.map(function (x) {
        switch (parseInt(x.mes)) {
          case 1:
            mesnc = "Enero";
            break;
          case 2:
            mesnc = "Febrero";
            break;
          case 3:
            mesnc = "Marzo";
            break;
          case 4:
            mesnc = "Abril";
            break;
          case 5:
            mesnc = "Mayo";
            break;
          case 6:
            mesnc = "Junio";
            break;
          case 7:
            mesnc = "Julio";
            break;
          case 8:
            mesnc = "Agosto";
            break;
          case 9:
            mesnc = "Setiembre";
            break;
          case 10:
            mesnc = "Octubre";
            break;
          case 11:
            mesnc = "Noviembre";
            break;
          case 12:
            mesnc = "Diciembre";
            break;
          default:
            mesnc = "Error";
        }
        me.nombreMesesCompra.push(mesnc);
        me.totalMesesCompra.push(x.valor);
      });

      me.ventas.map(function (x) {
        switch (parseInt(x.mes)) {
          case 1:
            mesnv = "Enero";
            break;
          case 2:
            mesnv = "Febrero";
            break;
          case 3:
            mesnv = "Marzo";
            break;
          case 4:
            mesnv = "Abril";
            break;
          case 5:
            mesnv = "Mayo";
            break;
          case 6:
            mesnv = "Junio";
            break;
          case 7:
            mesnv = "Julio";
            break;
          case 8:
            mesnv = "Agosto";
            break;
          case 9:
            mesnv = "Setiembre";
            break;
          case 10:
            mesnv = "Octubre";
            break;
          case 11:
            mesnv = "Noviembre";
            break;
          case 12:
            mesnv = "Diciembre";
            break;
          default:
            mesnv = "Error";
        }
        me.nombreMesesVenta.push(mesnv);
        me.totalMesesVenta.push(x.valor);
      });

      var ctx = document.getElementById("comprasBar");
      var comprasBar = new Chart(ctx, {
        type: "bar",
        data: {
          labels: me.nombreMesesCompra,
          datasets: [
            {
              data: me.totalMesesCompra,
              label: "Compras",
              backgroundColor: "#199EC7",
            },
          ],
        },
        options: {
          title: { display: true, position: "bottom", text: "COMPRAS MESES" },
        },
      });

      var ctx = document.getElementById("ventasBar");
      var ventasBar = new Chart(ctx, {
        type: "bar",
        data: {
          labels: me.nombreMesesVenta,
          datasets: [
            {
              data: me.totalMesesVenta,
              label: "Ventas",
              backgroundColor: "#40BC86",
            },
          ],
        },
        options: {
          title: { display: true, position: "bottom", text: "VENTAS MESES" },
        },
      });
    },

    cargarGraficoDoughnut() {
      let me = this;
      me.productos.map(function (x) {
        me.nombreMesesProducto.push(x.producto);
        me.totalMesesProducto.push(x.cantidad);
      });

      // And for a doughnut chart
      var ctx = document.getElementById("productosDoughnut");
      var productosDoughnut = new Chart(ctx, {
        type: "pie",
        data: {
          labels: me.nombreMesesProducto,
          datasets: [
            {
              data: me.totalMesesProducto,
              backgroundColor: [
                "#83D6DE", // celeste
                "#EB9532", // naranja
                "#F5C700", // amarillo
                "#E6567A", // rosado
                "#B86A54", // cafe
                "#B0A4BE", // morado claro
                "#710301", // granate
                "#FF6633",
                "#99CC33",
                "#FFFF00",
              ],
            },
          ],
        },
        options: {
          title: { display: true, position: "bottom", text: "MÁS VENDIDOS" },
          maintainAspectRatio: true,
          responsive: true,
          legend: {
            display: true,
            position: "right",
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
        },
      });
    },

    anualComprasVentas() {
      let me = this;
      var valorMesesArray = [];

      axios.get("api/Movimientos/MovimientoMes", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
        },
      })
        .then(function (response) {
          valorMesesArray = response.data;

          for (var i = 0; i < valorMesesArray.length; i++) {
            if (valorMesesArray[i].movimiento == 1) {
              me.compras.push({
                mes: valorMesesArray[i].mes,
                valor: me.common.roundOut(valorMesesArray[i].valor, 2),
              });
            } else if (valorMesesArray[i].movimiento == 2) {
              me.ventas.push({
                mes: valorMesesArray[i].mes,
                valor: me.common.roundOut(valorMesesArray[i].valor, 2),
              });
            }
          }
          me.cargarGraficoBar();
          //console.log(me.compras, me.ventas);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    productosMasVendidos() {
      let me = this;

      axios.get("api/Movimientos/MovimientoProductoAnual", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
        },
      })
        .then(function (response) {
          me.productos = response.data;
          me.cargarGraficoDoughnut();
          //console.log(me.productos);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
  },
};
</script>