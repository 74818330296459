<template>
  <HelloSolucion />
</template>

<script>
  import HelloSolucion from '../components/HelloSolucion'

  export default {
    components: {
      HelloSolucion,
    },
  }
</script>
