<template>
  <v-container fluid style="max-width: 1920px;">
    <v-layout align-start>
      <v-flex>
        <!-- Modal persona -->
        <v-dialog v-model="verPersona" max-width="700px" persistent>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>Nuevo Proveedor</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="guardarPersona" :loading="desabilitarPer" :disabled="desabilitarPer || !isValidPer">
                <v-icon>save</v-icon>
              </v-btn>
              <v-btn icon @click="closePersona">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="isValidPer">
                <v-row class="mt-2">
                  <v-col cols="6" sm="3">
                    <v-select dense v-model="idtipo_documento_pers" :items="tipo_documentos_cli" label="Tipo Documento"
                      :rules="[v => !!v || 'Tipo documento requerido']" @change="num_documento = ''" hide-details>
                    </v-select>
                  </v-col>
                  <v-col cols="10" sm="5">
                    <v-text-field dense v-model.trim="num_documento" label="Numero Documento"
                      :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento_pers == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                      :maxlength="idtipo_documento_pers == 1 ? 8 : 11"
                      :disabled="idtipo_documento_pers == 1 ? false : idtipo_documento_pers == 6 ? false : true"
                      @keypress="common.isNum($event)" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="1">
                    <div class="text-center">
                      <v-btn x-small fab color="primary" class="white--text" :loading="desabilitarBuscar"
                        :disabled="(idtipo_documento_pers == 1 && num_documento.length == 8 ? false : idtipo_documento_pers == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                        @click="extraerDocumento(num_documento)">
                        <v-icon>search</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model.trim="nombre"
                      :label="idtipo_documento_pers == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                      :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 200) || 'Nombre debe tener entre 3 a 200 caracteres']"
                      maxlength="100" prepend-inner-icon="person" v-uppercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo" v-model="idubigeo"
                      :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                      prepend-inner-icon="pin_drop" clearable hide-details></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model.trim="direccion" label="Direccion"
                      :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres']"
                      maxlength="200" prepend-inner-icon="place" v-uppercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field dense v-model.trim="email" label="Email"
                      :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                      maxlength="60" prepend-inner-icon="mail" v-lowercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field dense v-model.trim="telefono" label="Telefono"
                      :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                      maxlength="30" prepend-inner-icon="phone" v-uppercase hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal persona -->

        <!-- Producto-->
        <v-dialog v-model="verProductos" max-width="1200px">
          <v-card>
            <v-toolbar dark dense class="primary mb-1" flat>
              <v-toolbar-title>Productos</v-toolbar-title>
              <v-spacer />
              <v-text-field dense solo-inverted v-model.trim="busqueda_producto" label="Buscar Productos"
                prepend-inner-icon="search" @keyup="listarProducto()" clearable hide-details></v-text-field>
              <v-spacer />
              <v-btn icon @click="ocultarProductos()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <!-- Mostrar imagen de producto -->
              <v-dialog v-model="selectedZoom" max-width="350px">
                <v-avatar tile size="350">
                  <img :src="'data:image/png;base64,' + imagen_zoom" />
                </v-avatar>
              </v-dialog>
              <!-- Fin Mostrar imagen de producto -->
              <v-data-table :headers="cabeceraProductos" :items="productos" :items-per-page="15" item-key="idproducto"
                show-expand class="elevation-3" @dblclick:row="dblClickRepuesto" dense>
                <template v-slot:item.seleccionar="{ item }">
                  <v-icon class="mr-2" @click="agregarDetalle(item)" color="primary">control_point</v-icon>
                </template>
                <template v-slot:item.imagen="{ item }">
                  <template>
                    <v-avatar tile size="30">
                      <img :src="'data:image/png;base64,' + item.imagen" @click="verImagen(item)" />
                    </v-avatar>
                  </template>
                </template>
                <template v-slot:item.marca="{ item }">
                  <strong class="error--text">{{ item.marca }}</strong>
                </template>
                <template v-slot:item.precio_unit_comprad="{ item }">
                  <div class="text-right">
                    <span class="font-weight-bold">{{ item.precio_unit_comprad | formatearNumero }}</span>
                  </div>
                </template>
                <template v-slot:item.precio_unit_compras="{ item }">
                  <div class="text-right">
                    <span class="font-weight-bold">{{ item.precio_unit_compras | formatearNumero }}</span>
                  </div>
                </template>

                <template v-slot:item.stockactual="{ item }">
                  <template v-if="item.stockactual == item.stock_minimo">
                    <v-chip color="warning" small dark>{{ item.stockactual }}</v-chip>
                  </template>
                  <template v-else-if="item.stockactual > item.stock_minimo">
                    <v-chip color="success" small dark>{{ item.stockactual }}</v-chip>
                  </template>
                  <template v-else>
                    <v-chip color="error" small dark>{{ item.stockactual }}</v-chip>
                  </template>
                </template>

                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <v-row class="mt-0 mb-0">
                      <v-col cols="6" md="4" sm="4">
                        <v-select dense v-model="item.idtipo_afectacion" :items="tipo_afectacion"
                          label="Tipo Afectación" hide-details></v-select>
                      </v-col>
                    </v-row>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Producto-->

        <!-- Listado Pedidos-->
        <v-data-table :loading="loading" :headers="cabeceraListarPedido" :items="pedidos" :items-per-page="15"
          class="elevation-3" dense v-if="verNuevoPedido == 0">
          <template v-slot:top>
            <!-- Titulo y busqueda -->
            <v-toolbar flat v-if="verNuevoPedido == 0">
              <v-toolbar-title class="hidden-sm-and-down">PEDIDO A PROVEEDORES</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Formulario busqueda de pedido -->
              <v-text-field dense class="mr-1" v-model.trim="buscar_pedido" label="Búsqueda" @keyup="listar()"
                single-line hide-details></v-text-field>
              <v-spacer />
              <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listar()" class="mr-1" style="max-width: 220px;"></v-text-field>
                </template>
                <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                  @change="listar()" range></v-date-picker>
              </v-menu>
              <v-spacer />
              <!-- Fin Formulario busqueda de pedido -->
              <v-btn @click="mostrarNuevoPedido" color="primary" class="hidden-sm-and-down" small outlined>Nuevo</v-btn>
              <v-btn @click="mostrarNuevoPedido" color="primary" class="hidden-md-and-up" fab x-small outlined>
                <v-icon>add</v-icon>
              </v-btn>
            </v-toolbar>
            <!-- Fin Titulo y busqueda -->
          </template>
          <!-- Opciones -->
          <template v-slot:item.usuario="{ item }">
            <span>{{ (item.usuario).substr(0, 12) }}</span>
          </template>
          <template v-slot:item.proveedor="{ item }">
            <span>{{ (item.proveedor).substr(0, 20) }}</span>
          </template>
          <template v-slot:item.estado="{ item }">
            <template v-if="item.estado == 'Pendiente'">
              <span class="orange--text">Pendiente</span>
            </template>
            <template v-else>
              <span class="success--text">{{ item.estado }}</span>
            </template>
          </template>
          <template v-slot:item.moneda="{ item }">
            <div class="text-right">
              <strong>{{ item.moneda == "S" ? "S/" : "$" }}</strong>
            </div>
          </template>
          <template v-slot:item.importe_total="{ item }">
            <div class="text-right">
              <strong>{{ item.importe_total | formatearNumero }}</strong>
            </div>
          </template>
          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>
          <template v-slot:item.opciones="{ item }">
            <template v-if="esAdministrador || esCaja">
              <template v-if="item.estado == 'Recibido'">
                <v-btn small icon>
                  <v-icon color="success" @click="irCompras(item)">shopping_cart</v-icon>
                </v-btn>
              </template>
            </template>
            <template v-if="item.estado == 'Pendiente'">
              <v-btn small icon>
                <v-icon color="print" @click="mostrarComprobante(item)">print</v-icon>
              </v-btn>
            </template>
            <v-btn small icon>
              <v-icon color="primary" @click="verDetallesPedidos(item)">edit</v-icon>
            </v-btn>
            <template v-if="item.estado == 'Pendiente'">
              <v-btn small icon>
                <v-icon color="error" @click="deleteItemPedido(item)">delete_forever</v-icon>
              </v-btn>
            </template>
          </template>
          <!-- Opciones Fin -->
        </v-data-table>
        <!--Fin Listado Pedidos-->

        <!-- Ventana pedido  -->
        <v-card v-if="verNuevoPedido == 1" class="elevation-3">
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <v-spacer />
            <v-text-field dense solo-inverted @keyup.enter="buscarCodigoBarra()" v-model="codigo_barra"
              label="Código Barra" maxlength="30" append-icon="qr_code_scanner" style="max-width: 220px;" hide-details>
            </v-text-field>
          </v-toolbar>
          <v-card-text grid-list-sm v-if="verNuevoPedido">
            <v-form v-model="isValid">
              <v-row>
                <v-col cols="6" md="2" sm="3">
                  <v-select dense v-model="tipo_estado" :items="estados" label="Estado"
                    :rules="[v => !!v || 'Estado requerido']" hide-details></v-select>
                </v-col>
                <v-col cols="6" md="2" sm="3">
                  <v-select dense v-model="idtipo_servicio" :items="tipo_servicios" label="Moneda"
                    :rules="[v => !!v || 'Servicio requerido']" @change="detalles = []" hide-details></v-select>
                </v-col>
                <v-col cols="10" md="7" sm="5">
                  <v-autocomplete dense @keyup="selectProveedor()" :search-input.sync="buscar_proveedor"
                    v-model="idpersona" :items="proveedores" label="Proveedor"
                    :rules="[v => !!v || 'Proveeedor requerido']" clearable append-outer-icon="person_add"
                    @click:append-outer="mostrarPersona" hide-details></v-autocomplete>
                </v-col>
                <v-col cols="2" sm="1">
                  <div class="text-center">
                    <v-btn @click="mostrarProductos()" x-small fab color="primary" outlined>
                      <v-icon>list</v-icon>
                    </v-btn>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-data-table :loading="loadingDetalles"
                    :headers="numserie == false ? cabeceraDetallesPedidos : cabeceraDetallesPedidosLote"
                    :items="detalles" disable-pagination hide-default-footer dense class="elevation-3">
                    <template v-slot:header.descripcion="{ header }">
                      {{ header.text }}<v-icon class="ml-2" @click="numserie = !numserie">pin</v-icon>
                    </template>
                    <template v-slot:item.num="{ item }">
                      <td>{{ detalles.indexOf(item) + 1 }}</td>
                    </template>
                    <template v-slot:item.borrar="{ item }">
                      <v-icon small color="error" class="mr-2" @click="eliminarDetallePedido(detalles, item)">
                        delete_forever</v-icon>
                    </template>
                    <template v-slot:item.unidad="{ item, index }">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip small color="primary" @click="verPresentaciones(item.idproducto)" v-bind="attrs"
                            v-on="on"><strong>{{ item.unidad }}</strong></v-chip>
                        </template>
                        <v-list>
                          <v-list-item v-for="(item, i) in detallePresentaciones" :key="i" link>
                            <v-list-item-title @click="selectUnidad(item.idunidad_medida, index)">{{
          item.unidad
        }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                    <template v-slot:item.lote="{ item }">
                      <v-text-field dense type="text" v-model.trim="item.lote" maxlength="80" v-uppercase hide-details>
                      </v-text-field>
                    </template>
                    <template v-slot:item.fecha_vencimiento_prod="{ item }">
                      <v-text-field dense type="date" v-model="item.fecha_vencimiento_prod"
                        :rules="[v => v === '' || /^\d{4}-\d{2}-\d{2}$/.test(v) || 'Fecha no válida']"
                        hide-details></v-text-field>
                    </template>
                    <template v-slot:item.xcantidad="{ item }">
                      <v-text-field dense type="text" @keyup.up="item.xcantidad++" @keyup.down="item.xcantidad--"
                        v-model.number="item.xcantidad" :rules="[v => (!!v && v > 0) || 'Requerido']" maxlength="5"
                        @keypress="common.isNum($event)" onClick="this.select()" class="center-input"
                        style="max-width: 50px;" hide-details></v-text-field>
                      <v-text-field dense type="text" v-model.number="item.stock_ingreso = item.xcantidad * item.factor"
                        :rules="[v => (!!v && v > 0) || 'Requerido']" maxlength="20" class="center-input"
                        style="max-width: 50px;" v-show="false" hide-details></v-text-field>
                    </template>
                    <template v-slot:item.valor_unit_compra="{ item }">
                      <v-text-field dense type="text" v-model="item.valor_unit_compra"
                        :rules="[v => !!v || 'Requerido', item.valor_unit_compra > 0 || 'Error']" maxlength="10"
                        @keypress="common.numDec($event, item.valor_unit_compra, 4)" onClick="this.select()"
                        hide-details></v-text-field>
                    </template>
                    <template v-slot:item.descto_item="{ item }">
                      <v-text-field dense type="text" v-model="item.descto_item" maxlength="10"
                        @keypress="common.numDec($event, item.descto_item, 4)" onClick="this.select()"
                        hide-details></v-text-field>
                    </template>
                    <template v-slot:item.otros_item="{ item }">
                      <v-text-field dense type="text" v-model="item.otros_item" maxlength="10"
                        @keypress="common.numDec($event, item.otros_item, 4)" onClick="this.select()"
                        hide-details></v-text-field>
                    </template>

                    <template v-slot:item.subtotal="{ item }">
                      <div class="text-right">
                        <span>{{ common.roundOut((common.valInt(item.xcantidad) *
          common.valDec(item.valor_unit_compra) -
          common.valDec(item.descto_item)), 2)
          | formatearNumero
                          }}</span>
                      </div>
                    </template>
                  </v-data-table>
                  <v-container>
                    <v-row no-gutters align="end" justify="end">
                      <v-col cols="10" align="end">
                        <strong>Descuento Global (-) {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense v-model="descto_global" maxlength="9"
                          @keypress="common.numDec($event, descto_global, 4)" onClick="this.select()"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Descuento por Item (-) {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="descto_item_total = common.roundOut(common.valDec(calcularDescuentoItem), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Seguro {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense v-model="seguro" maxlength="9" @keypress="common.numDec($event, seguro, 4)"
                          onClick="this.select()" class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Gravada {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="subtotal_gravado = common.roundOut((common.valDec(calcularTotal) - common.valDec(descto_global) + common.valDec(seguro)), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>IGV {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <!-- Hallamos el igv no mover sale exacto -->
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="igv_gravado = common.roundOut((((common.valDec(calcularTotal) - common.valDec(descto_global) + common.valDec(seguro)) * igv)), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Importe Total {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="importe_total = common.roundOut((common.valDec(subtotal_gravado) + common.valDec(igv_gravado)), 2).toFixed(2)"
                          :rules="[v => v > 0 || 'Importe mayor a cero']" class="right-input" hide-details>
                        </v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Gratuita {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="gratuito = common.roundOut(calcularGratuitoTotal, 2).toFixed(2)" class="right-input"
                          hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Otros Item {{ idtipo_servicio == 3 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="otros_item_total = common.roundOut(common.valDec(calcularOtrosItem), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 justify-center">
            <v-btn color="error" @click="ocultarNuevo()" small outlined>
              <v-icon left>close</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarPedido()" :loading="desabilitar" :disabled="desabilitar || !isValid"
              small outlined>
              <v-icon left>check</v-icon>Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Fin Ventana pedido  -->

        <!-- Modal Impresion -->
        <v-dialog v-model="comprobanteModal" max-width="800px" scrollable>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>Pedido Proveedor Nº {{ idpedido }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobante()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf v-for="i in numPages" :key="i" :src="srcPdf" :page="i"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal Impresion -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";

import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      pedidos: [],
      detalles: [], // Detalles pedido
      detalle_compras: [], // Detalles formateado

      cabeceraListarPedido: [
        { text: "CdPd", value: "idpedido", sortable: false },
        { text: "USUARIO", value: "usuario", sortable: false },
        { text: "PROVEEDOR", value: "proveedor", sortable: false },
        { text: "TIPO", value: "tipo_pedido", sortable: false },
        { text: "M", value: "moneda", sortable: false },
        { text: "IMPORTE", value: "importe_total", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      cabeceraDetallesPedidos: [
        { text: "", value: "borrar", sortable: false },
        { text: "#", value: "num", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "U/M", value: "unidad", sortable: false, align: "center" },
        { text: "MARCA", value: "marca", sortable: false },
        { text: "LOTE", value: "lote", sortable: false, align: ' d-none' },
        { text: "FEC. VENCIMIENTO", value: "fecha_vencimiento_prod", sortable: false, align: ' d-none' },
        { text: "CANT.", value: "xcantidad", align: "center", sortable: false },
        { text: "P/V/UNIT.", value: "valor_unit_compra", sortable: false, align: ' d-none' },
        { text: "DSCTO", value: "descto_item", sortable: false, align: ' d-none' },
        { text: "OTROS", value: "otros_item", sortable: false, align: ' d-none' },
        { text: "TOTAL", value: "subtotal", sortable: false, align: ' d-none' },
      ],
      cabeceraDetallesPedidosLote: [
        { text: "", value: "borrar", sortable: false },
        { text: "#", value: "num", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "U/M", value: "unidad", sortable: false, align: "center" },
        { text: "MARCA", value: "marca", sortable: false },
        { text: "LOTE", value: "lote", sortable: false },
        { text: "FEC. VENCIMIENTO", value: "fecha_vencimiento_prod", sortable: false },
        { text: "CANT.", value: "xcantidad", align: "center", sortable: false },
        { text: "P/V/UNIT.", value: "valor_unit_compra", sortable: false },
        { text: "DSCTO", value: "descto_item", sortable: false },
        { text: "OTROS", value: "otros_item", sortable: false },
        { text: "TOTAL", value: "subtotal", sortable: false },
      ],

      cabeceraProductos: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", align: "center", sortable: false },
        { text: "IMG", value: "imagen", align: "center", sortable: false },
        { text: "NOMBRE", value: "descripcion", sortable: false },
        { text: "U/M", value: "unidad", sortable: false },
        { text: "MARCA", value: "marca", sortable: false, divider: true },
        { text: "P/U/C $", value: "precio_unit_comprad", sortable: false },
        { text: "P/U/C S/", value: "precio_unit_compras", sortable: false },
        { text: "STOCK", value: "stockactual", sortable: false, align: "center" },
      ],
      loading: false,
      loadingDetalles: false,

      numserie: false,
      buscar_pedido: "",
      buscar_proveedor: "",
      buscar_ubigeo: "",
      codigo_barra: "",
      busqueda_producto: "",
      productos: [],

      idpedido: "",
      idpersona: "",
      proveedores: [],
      idtipo_servicio: 3, // Compra ME
      tipo_servicios: [],

      igv: 0,
      tc: 0,

      descto_global: 0,
      descto_item_total: 0,
      otros_item_total: 0,
      seguro: 0,
      subtotal_gravado: 0,
      gratuito: 0,
      igv_gravado: 0,
      importe_total: 0,

      tipo_estado: "Pendiente",
      estados: ["Pendiente", "Recibido"],

      verNuevoPedido: 0,
      verProductos: 0,

      sucursal: "",
      proveedor: "",
      fecha: "",

      moneda: "",

      menuFecha: false,

      comprobanteModal: 0,

      desabilitar: false, // Desabilitamos
      isValid: true, // Validación

      stringPdf: "",
      srcPdf: "",
      blobPdf: "",
      numPages: undefined,

      editedIndex: -1, // Titulo pedidos

      selectedZoom: false,
      imagen_zoom: "",

      // Persona
      nombre: "",
      idtipo_documento_pers: "",
      tipo_documentos_cli: [],
      num_documento: "",
      idubigeo: "",
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",
      verPersona: false,
      desabilitarBuscar: false,
      desabilitarPer: false,
      isValidPer: true,

      tipo_afectacion: [],

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      detallePresentaciones: [],
      
      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),
    ...mapGetters(["esAdministrador", "esCaja"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    // Titulo pedidos
    formTitle() {
      return this.editedIndex === -1
        ? "Nuevo Pedido Proveedor"
        : "Actualizar Pedido Proveedor";
    },

    calcularTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        if (me.detalles[i].idtipo_afectacion == "10") {
          resultado = resultado + me.common.roundOut(parseInt(me.detalles[i].xcantidad == "" ? 0.0 : me.detalles[i].xcantidad) * parseFloat(me.detalles[i].valor_unit_compra == "" ? 0.0 : me.detalles[i].valor_unit_compra) - parseFloat(me.detalles[i].descto_item == "" ? 0.0 : me.detalles[i].descto_item), 4);
        }
      }
      return resultado;
    },

    calcularGratuitoTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        if (me.detalles[i].idtipo_afectacion == "31") {
          resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].xcantidad) * me.common.valDec(me.detalles[i].valor_unit_compra) - me.common.valDec(me.detalles[i].descto_item), 4);
        }
      }
      return resultado;
    },

    calcularDescuentoItem: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado = resultado + me.common.roundOut(me.common.valDec(me.detalles[i].descto_item), 4);
      }
      return resultado;
    },

    calcularOtrosItem: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].xcantidad) * me.common.valDec(me.detalles[i].otros_item), 4);
      }
      return resultado;
    },
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobante();
    },
    busqueda_producto(newVal) {
      if (newVal === null) {
        this.busqueda_producto = "";
      }
    },
    verProductos(val) {
      val || this.ocultarProductos();
    },
  },

  created() {
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
    this.listar();
    this.select();
  },

  methods: {
    ...mapActions(["snackBar"]),

    // Descargar pdf de blob
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = "Pedido Proveedor Nro" + this.idpedido;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace(
          "data:application/pdf;filename=generated.pdf;base64,",
          ""
        ),
        type: "pdf",
        base64: true,
      });
    },

    //#region SELECT
    selectProveedor(id) {
      let me = this;

      var proveedoresArray = [];

      if (id) {
        axios.get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            proveedoresArray = response.data;
            proveedoresArray.map(function (x) {
              me.proveedores.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Personas/SelectPersonasFiltro", {
          params: {
            buscar: me.buscar_proveedor == "" ? "-" : me.buscar_proveedor == null ? "-" : me.buscar_proveedor,
            tipo_doc: "-",
          },
        })
          .then(function (response) {
            proveedoresArray = response.data;
            proveedoresArray.map(function (x) {
              me.proveedores.push({
                text: x.num_documento + " - " + x.nombre,
                value: x.idpersona,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios.get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Ubigeos/SelectUbigeosFiltro", {
          params: {
            buscar:
              me.buscar_ubigeo == "" ? "-" : me.buscar_ubigeo == null ? "-" : me.buscar_ubigeo,
          },
        })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    select() {
      let me = this;
      var tipo_serviciosArray = [];
      axios.get("api/Tipo_Servicios/SelectMonCompra")
        .then(function (response) {
          tipo_serviciosArray = response.data;
          tipo_serviciosArray.map(function (x) {
            me.tipo_servicios.push({
              text: x.nombre,
              value: x.idtipo_servicio,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_documentosArray = [];
      axios.get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos_cli.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_afectacionArray = [];
      axios.get("api/Tipo_Afectacion/Select")
        .then(function (response) {
          tipo_afectacionArray = response.data;
          tipo_afectacionArray.map(function (x) {
            me.tipo_afectacion.push({
              text: x.descripcion,
              value: x.idtipo_afectacion,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion SELECT

    //#region PEDIDO
    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Pedidos/ListarFiltroPedidoCompras", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          buscar: me.buscar_pedido == "" ? "-" : me.buscar_pedido,
          fechaInicio: me.dates[0],
          fechaFin: me.dates[1] == undefined ? me.dates[0] + " 23:59:59" : me.dates[1] + " 23:59:59",
        },
      })
        .then(function (response) {
          me.pedidos = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    guardarPedido() {
      let me = this;
      me.detalle_compras = [];
      // Validamos detalles
      if (me.detalles.length <= 0) {
        return me.snackBar({ cl: "warning", msg: "Ingrese al menos un producto al detalle." });
      }
      me.convertirDetalle(); // Formateamos Detalle
      me.desabilitar = true; // Desabilitamos

      if (me.editedIndex > -1) {
        // Editar
        axios.put("api/Pedidos/ActualizarPedidoProveedor", {
          idpedido: me.idpedido,
          idsucursal: parseInt(me.usuario.idsucursal),
          idpersona: me.idpersona,
          idusuario: parseInt(me.usuario.idusuario),
          tipo_pedido: "Pedido",
          idtipo_servicio: me.idtipo_servicio,
          igv: me.igv,
          descto_global: me.common.valDec(me.descto_global),
          descto_item_total: me.common.valDec(me.descto_item_total),
          otros_item_total: me.common.valDec(me.otros_item_total),
          seguro: me.common.valDec(me.seguro),
          inafecto: 0,
          exonerado: 0,
          gratuito: parseFloat(me.gratuito),
          subtotal_gravado: parseFloat(me.subtotal_gravado),
          igv_gravado: parseFloat(me.igv_gravado),
          importe_total: parseFloat(me.importe_total),
          estado: me.tipo_estado,

          detalle_compras: me.detalle_compras,
        })
          .then(function (response) {
            me.snackBar({ cl: "success", msg: response.data });
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
            //console.log(error);
          });
      } else {
        // Guardar
        axios.post("api/Pedidos/CrearPedidoProveedor", {
          idsucursal: parseInt(me.usuario.idsucursal),
          idpersona: me.idpersona,
          idusuario: parseInt(me.usuario.idusuario),
          tipo_pedido: "Pedido",
          idtipo_servicio: me.idtipo_servicio,
          igv: me.igv,
          descto_global: parseFloat(me.descto_global == "" ? 0 : me.descto_global),
          descto_item_total: parseFloat(me.descto_item_total == "" ? 0 : me.descto_item_total),
          otros_item_total: parseFloat(me.otros_item_total == "" ? 0 : me.otros_item_total),
          seguro: parseFloat(me.seguro == "" ? 0 : me.seguro),
          inafecto: 0,
          exonerado: 0,
          gratuito: parseFloat(me.gratuito),
          subtotal_gravado: parseFloat(me.subtotal_gravado),
          igv_gravado: parseFloat(me.igv_gravado),
          importe_total: parseFloat(me.importe_total),
          estado: me.tipo_estado,

          detalle_compras: me.detalle_compras,
        })
          .then(function (response) {
            me.snackBar({ cl: "success", msg: response.data });
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
            //console.log(error);
          });
      }
    },

    convertirDetalle() {
      let me = this;
      for (var i = 0; i < me.detalles.length; i++) {
        me.detalle_compras.push({
          idproducto: me.detalles[i].idproducto,
          idunidad_medida: me.detalles[i].idunidad_medida,
          factor: parseInt(me.detalles[i].factor),
          xcantidad: parseInt(me.detalles[i].xcantidad),
          lote: me.detalles[i].lote,
          fecha_vencimiento_prod: me.detalles[i].fecha_vencimiento_prod || null,
          stock_ingreso: parseInt(me.detalles[i].stock_ingreso),
          stock_actual: parseInt(me.detalles[i].stock_ingreso),
          valor_unit_compra: parseFloat(me.detalles[i].valor_unit_compra),
          precio_unit_compra: me.igv == 0 ? parseFloat(me.detalles[i].valor_unit_compra) : me.common.roundOut(parseFloat(me.detalles[i].valor_unit_compra) * me.igv + parseFloat(me.detalles[i].valor_unit_compra), 4),
          descto_item: me.common.valDec(me.detalles[i].descto_item),
          otros_item: me.common.valDec(me.detalles[i].otros_item),
          idtipo_afectacion: me.detalles[i].idtipo_afectacion,
        });
      }
    },

    verDetallesPedidos(item) {
      this.limpiar();

      this.igv = parseFloat(this.datos.igv);
      this.tc = parseFloat(this.datos.tc);

      this.idpedido = item.idpedido;
      this.listarDetallePedidos(item.idpedido);
      this.tipo_estado = item.estado;
      this.idtipo_servicio = item.idtipo_servicio;
      this.idpersona = item.idpersona;
      this.selectProveedor(item.idpersona);

      this.descto_global = parseFloat(item.descto_global).toFixed(2);
      this.seguro = parseFloat(item.seguro).toFixed(2);

      this.verNuevoPedido = 1;
      this.editedIndex = 1;
    },

    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];
      me.loadingDetalles = true;

      axios.get("api/Pedidos/ListarDetallesCompras/" + id)
        .then(function (response) {
          detallesArray = response.data; // Recorremos y formateamos
          detallesArray.map(function (x) {
            me.detalles.push({
              idproducto: x.idproducto,
              codigo_producto: x.codigo_producto,
              idunidad_medida: x.idunidad_medida,
              unidad: x.unidad,
              factor: x.factor,
              xcantidad: x.xcantidad,
              //categoria: x.categoria,
              marca: x.marca,
              descripcion: x.descripcion,
              lote: x.lote,
              fecha_vencimiento_prod: x.fecha_vencimiento_prod ? x.fecha_vencimiento_prod.substring(0, 10) : "",
              stock_ingreso: x.stock_ingreso,
              valor_unit_compra: x.valor_unit_compra,
              precio_unit_compra: x.precio_unit_compra,
              descto_item: x.descto_item,
              otros_item: x.otros_item,
              idtipo_afectacion: x.idtipo_afectacion,
            });
          });
          me.loadingDetalles = false;
          me.comprobanteModal == 1 ? me.crearPDF() : "";
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    irCompras(item) {
      this.$router.push({
        name: "compras",
        params: {
          depedido: true,
          deidtipo_servicio: item.idtipo_servicio,
          deidpersona: item.idpersona,
          deidpedido: item.idpedido,
          dedescto_global: item.descto_global,
          deseguro: item.seguro,
        },
      });
    },

    mostrarNuevoPedido() {
      this.verNuevoPedido = 1;
    },

    ocultarNuevo() {
      this.verNuevoPedido = 0;
      this.desabilitar = false;
      this.listar();
      this.limpiar();
    },

    deleteItemPedido(item) {
      const respta = confirm(
        "¿Estás seguro de Eliminar el pedido Nº " + item.idpedido + " ?"
      );
      let me = this;

      if (respta) {
        axios.delete("api/Pedidos/Eliminar/" + item.idpedido)
          .then((response) => {
            me.snackBar({ cl: "info", msg: response.data });
            this.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    // Eliminar detalle pedido
    eliminarDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },
    //#endregion PEDIDO

    //#region IMPRESIÓN
    mostrarComprobante(item) {
      this.limpiar();
      this.idpedido = item.idpedido;
      this.sucursal = item.sucursal;
      this.proveedor = item.proveedor;
      this.num_documento = item.num_documento;
      this.fecha = item.fecha;
      this.igv = item.igv;
      this.moneda = item.moneda;
      this.idpedido = item.idpedido;
      this.descto_global = item.descto_global;
      this.descto_item_total = item.descto_item_total;
      this.otros_item_total = item.otros_item_total;
      this.seguro = item.seguro;
      this.subtotal_gravado = item.subtotal_gravado;
      this.gratuito = item.gratuito;
      this.igv_gravado = item.igv_gravado;
      this.importe_total = item.importe_total;

      this.comprobanteModal = 1;
      this.listarDetallePedidos(item.idpedido);
    },

    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    //#endregion IMPRESIÓN

    //#region PRODUCTOS
    buscarCodigoBarra() {
      let me = this;

      axios.get("api/Productos/BuscarCodigoBarraCompra/" + this.codigo_barra)
        .then(function (response) {
          //console.log(response);
          me.agregarDetalle(response.data);
          me.snackBar({ cl: "info", msg: "Se agrego " + response.data.descripcion + " al detalle." });
          me.codigo_barra = "";
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "warning", msg: "No existe registro de código de barra para el producto." });
          me.codigo_barra = "";
        });
    },

    listarProducto() {
      let me = this;
      axios.get("api/Productos/ListarCompra", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          buscar: me.busqueda_producto == "" ? "-" : me.busqueda_producto,
        },
      })
        .then(function (response) {
          me.productos = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    dblClickRepuesto(event, { item }) {
      this.agregarDetalle(item);
    },

    agregarDetalle(data = []) {
      let me = this;

      me.detalles.push({
        idproducto: data["idproducto"],
        idunidad_medida: data["idunidad_medida"],
        unidad: data["unidad"],
        factor: data["factor"],
        xcantidad: 1,
        descripcion: data["descripcion"],
        lote: "",
        fecha_vencimiento_prod: "",
        marca: data["marca"],
        stock_ingreso: 1,
        valor_unit_compra: me.common.roundOut((me.idtipo_servicio == "3" ? data["precio_unit_compras"] : me.idtipo_servicio == "4" ? data["precio_unit_comprad"] : 0) / (1 + me.igv), 4),
        descto_item: 0,
        otros_item: 0,
        idtipo_afectacion: data["idtipo_afectacion"],
      });
    },

    mostrarProductos() {
      this.verProductos = 1;
    },

    ocultarProductos() {
      this.verProductos = 0;
      //this.busqueda_producto = "";
      //this.productos = [];
    },

    verImagen(item) {
      this.imagen_zoom = item.imagen;
      this.selectedZoom = true;
    },

    verPresentaciones(id) {
      let me = this;
      var detallesArray = [];
      me.detallePresentaciones = [];

      axios.get("api/Productos/SelectPresentaciones/" + id)
        .then(function (response) {
          detallesArray = response.data;
          detallesArray.map(function (x) {
            me.detallePresentaciones.push({
              idproducto: x.idproducto,
              idunidad_medida: x.idunidad_medida,
              unidad: x.unidad,
              factor: x.factor
            });
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    selectUnidad(idunidad, index) {
      const selectId = this.detallePresentaciones.find((elemento) => elemento.idunidad_medida === idunidad);

      if (selectId) {
        // Actulizamos valores de unidad
          this.detalles[index].idunidad_medida = selectId.idunidad_medida;
          this.detalles[index].unidad = selectId.unidad;
          this.detalles[index].factor = selectId.factor;
          this.snackBar({ cl: "info", msg: "Se cambio la Unidad de medida: " + selectId.unidad + " X " + selectId.factor });
      }
    },
    //#endregion PRODUCTOS

    //#region PERSONA
    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;

      var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento_pers == 1) {
        fetch("https://dniruc.apisperu.com/api/v1/dni/" + num_documento + "?token=" + token)
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo);
              me.nombre =
                data.apellidoPaterno +
                " " +
                data.apellidoMaterno +
                " " +
                data.nombres;
            } else {
              me.nombre = "";
              me.snackBar({ cl: "info", msg: data.message }); // Sin resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.snackBar({ cl: "error", msg: "Error en el Nº DNI." });
          });
      } else if (me.idtipo_documento_pers == 6) {
        axios.post("api/Personas/Padron", {
          ruc: me.num_documento,
        })
          .then(function (response) {
            me.desabilitarBuscar = false;
            me.idubigeo = response.data.ubigeo;
            me.selectUbigeo(me.idubigeo);
            me.nombre = response.data.razon;
            me.direccion = response.data.direccion == null ? "" : response.data.direccion.trim();
            me.snackBar({ cl: "info", msg: "Contribuyente con estado " + response.data.estado + " y condición " + response.data.condicion });
          })
          .catch(function (error) {
            me.desabilitarBuscar = false;
            me.nombre = "";
            me.direccion = "";
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    guardarPersona() {
      let me = this;
      me.desabilitarPer = true;

      axios.post("api/Personas/Crear", {
        tipo_persona: "Cliente",
        nombre: me.nombre,
        idtipo_documento: me.idtipo_documento_pers,
        num_documento: me.num_documento,
        idubigeo: me.idubigeo,
        direccion: me.direccion,
        telefono: me.telefono,
        email: me.email,
      })
        .then((response) => {
          me.idpersona = response.data.idpersona;
          me.selectProveedor(me.idpersona);
          me.desabilitarPer = false;
          me.snackBar({ cl: "success", msg: response.data.mensaje });
          me.closePersona();
        })
        .catch(function (error) {
          me.desabilitarPer = false;
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    mostrarPersona() {
      this.verPersona = true;
    },

    closePersona() {
      this.verPersona = false;
      this.limpiarPersona();
    },

    limpiarPersona() {
      this.idtipo_documento_pers = "";
      this.num_documento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.direccion = "";
      this.email = "";
      this.telefono = "";
    },
    //#endregion PERSONA

    limpiar() {
      this.moneda = "";
      this.idpedido = "";
      this.idpersona = "";
      this.num_documento = "";
      this.idtipo_servicio = 3; // Compra ME
      this.tipo_pedido = "";

      this.descto_global = 0;
      this.descto_item_total = 0;
      this.otros_item_total = 0;
      this.seguro = 0;
      this.subtotal_gravado = 0;
      this.gratuito = 0;
      this.igv_gravado = 0;
      this.importe_total = 0;

      this.tipo_estado = "Pendiente";

      this.detalles = [];
      this.detalle_compras = [];
      this.proveedores = [];

      this.codigo_barra = "";

      this.stringPdf = "";
      this.srcPdf = "";
      this.blobPdf = "";

      this.editedIndex = -1;
    },

    crearPDF() {
      var imgData = "data:image/png;base64," + this.logo;
      var ancho = 45;
      var alto = 28.45;
      var doc = new jsPDF("p", "mm", [210, 297]);
      var centro = doc.internal.pageSize.width / 2;

      var columns = [
        { header: "ITEM", dataKey: "item" },
        { header: "CANT.", dataKey: "xcantidad" },
        { header: "U/M", dataKey: "unidad" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion" },
        { header: "MARCA", dataKey: "marca" },
        /* { header: "V.UNITARIO", dataKey: "valor_unit_compra" },
          { header: "DSCTO", dataKey: "descto_item" }, 
          { header: "TOTAL", dataKey: "subtotal" }, */
      ];
      var body = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          item: i+1,
          xcantidad: this.detalles[i].xcantidad,
          unidad: this.detalles[i].unidad,
          descripcion: this.detalles[i].descripcion,
          marca: this.detalles[i].marca,
          valor_unit_compra: numeral(this.detalles[i].valor_unit_compra).format("###,##0.0000"),
          descto_item: numeral(this.detalles[i].descto_item).format("###,##0.0000"),
          subtotal: numeral(this.common.roundOut(this.detalles[i].valor_unit_compra * this.detalles[i].stock_ingreso - this.detalles[i].descto_item, 2)).format("###,##0.00"),
        });
      }

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 15, 15, ancho, alto);
          // Datos empresa
          doc.autoTable({
            startY: 17,
            theme: "plain",
            margin: { left: 60, right: 60},
            styles: { overflow: "ellipsize", cellWidth: "wrap" },
            bodyStyles: {
              cellPadding: 0.5,
              font: "helvetica",
              fontStyle: "normal",
              fontSize: 6,
              textColor: 0,
              halign: "center",
            },
            body: [
              [{ content: this.datos.empresa, styles: { fontSize: 8,fontStyle: "bold" } }],
              [{ content: this.datos.emp_comercial, styles: { fontSize: 7,fontStyle: "bold" } }],
              (this.datos.eslogan ? [{ content: this.datos.eslogan }] : undefined),
              [{ content: "Email: " + this.datos.mailsucursal + " Tel: " + this.datos.telsucursal }],
              [{ content: this.datos.dirsucursal }],
              [{ content: this.datos.dptosucursal + " - " + this.datos.provsucursal + " - " + this.datos.dttosucursal }]
            ].filter(item => item !== undefined),
          });

         // DATOS DOCUMENTO
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text("PEDIDO PROVEEDOR", centro + 70, 27, "center");
          doc.text("N°" + this.idpedido, centro + 70, 32, "center");
 
          // DESTINATARIO
          doc.autoTable({
            startY: 44,
            theme: "plain",
            tableLineColor: [82, 86, 89],
            tableLineWidth: 0.2,
            margin: { left: 15, right: 15},
            bodyStyles: {
              cellPadding: 1,
              font: "helvetica",
              fontStyle: "normal",
              fontSize: 7,
              textColor: 0,
            },
            columnStyles: {
              0: { cellWidth: 18 },
              1: { cellWidth: 106 },
              2: { cellWidth: 23 },
              3: { cellWidth: 33 }
            },
            body: [
              [{ content: "Doc. Nº", styles: { fontStyle: "bold" }  }, { content: ": " + this.num_documento}, { content: "Fecha", styles: { fontStyle: "bold" } }, { content: ": " + moment(this.fecha).format("DD/MM/YYYY") }],
              [{ content: "Proveedor:", styles: { fontStyle: "bold" } }, { content: ": " + this.proveedor }, { content: "Establecimiento", styles: { fontStyle: "bold" } }, { content: ": " + this.sucursal }],
            ],
          }); 
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(6);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("Página " + String(i) + " de " + String(pageCount), centro, 285, "center");
        }
      };

       // DETALLE DE COMPRA
      doc.autoTable({
          theme: "grid",
          columns,
          body,
          margin: { left: 15, right: 15, top: 56, bottom: 20 },
          //styles: { overflow: "ellipsize", cellWidth: "wrap" },
          headStyles: {
            cellPadding: 0.5,
            font: "helvetica",
            fontStyle:"bold",
            fontSize: 7,
            halign: "center",
            fillColor: [82, 86, 89],
            textColor: 255,
            lineColor: [82, 86, 89],
            lineWidth: 0.2,
          },
          bodyStyles: {
            cellPadding: 0.5,
            font: "helvetica",
            fontStyle: "normal",
            fontSize: 7,
            textColor: 0,
            lineColor: [82, 86, 89],
            lineWidth: 0.2,
          },
          footStyles: {
            cellPadding: 0.5,
            font: "helvetica",
            fontStyle: "normal",
            fillColor: 255,
            textColor: 0,
            fontSize: 7,
            halign: "right",
          },
        columnStyles: {
          item:{cellWidth: 7, halign: "center"},
          xcantidad: { cellWidth: 10, halign: "center" },
          unidad: { cellWidth: 7, halign: "center" },
          descripcion: { cellWidth: "auto" },
          marca: { cellWidth: 20, halign: "center" },
          valor_unit_compra: { cellWidth: 20, halign: "right" },
          descto_item: { cellWidth: 20, halign: "right" },
          subtotal: { cellWidth: 20, halign: "right" },
        },
      });

      addHeaders(doc);
      addFooters(doc);

      doc.setProperties({
        title: "PedidoproveedorNro" + this.idpedido + ".pdf",
      });

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      // Para visualizar todas las paginas del pdf
      this.srcPdf = pdf.createLoadingTask(this.stringPdf);
      this.srcPdf.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });
    },
  },
};
</script>
<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>