import Vue from 'vue'
import Vuex from 'vuex'
import decode from 'jwt-decode'
import router from './router'

import axios from "axios"; // notificaciones y susursales

Vue.use(Vuex)

export default new Vuex.Store({
  // solo lectura para modificar valores de estate
  state: {
    token: '',
    usuario: '', // Datos del usuario
    refreshtoken: '',
    setting: '',
    datos: '', // datos de empresa y sucursales
    avatar: '', // para avatar de usuario
    logo: '', // Logo de la empresa
    notificaciones: [], // notificaciones array
    notifcpes: [], // notifcpes array
    snackbar: { active: false, color: "", message: "", timeout: -1, icon: "" }
  },

  // Cuatro acciones, logica para cambiar state 
  mutations: {
    setSnackbar(state, snackbar) {
      state.snackbar = snackbar;
    },
    //decodificamos payload/token datos del usuario
    setToken(state, payload) {
      state.token = payload;
      if (payload === '') {
        state.usuario = ''
      } else {
        state.usuario = decode(payload)
      }
    },
    setRefreshToken(state, refreshpayload) {
      state.refreshtoken = refreshpayload;
    },
    setSetting(state, settingpayload) {
      state.setting = settingpayload;
      if (settingpayload === '') {
        state.datos = ''
      } else {
        state.datos = decode(settingpayload)
      }
    },
    setAvatar(state, avatarpayload) {
      state.avatar = avatarpayload;
    },
    setLogo(state, logopayload) {
      state.logo = logopayload;
    },
    // notificaciones
    setNotificaciones(state, notificaciones) {
      state.notificaciones = notificaciones
    },
    // notifcpes
    setNotifCpes(state, notifcpes) {
      state.notifcpes = notifcpes
    },
  },

  // Realizando mutaciones
  actions: {
    snackBar({ commit }, obj) {
      commit("setSnackbar", {
        active: true,
        color: obj.cl,
        message: obj.msg,
        timeout: 8000,
        icon: obj.cl == "success" ? "check_circle" : obj.cl
      });
    },

    //Guardamos token, refreshtoken, guardaravatar, autologin y salir
    guardarToken({ commit }, payload) {
      localStorage.setItem("token", payload);
      commit("setToken", payload) //ejecutamos el payload=token
    },
    guardarRefreshToken({ commit }, refreshpayload) {
      localStorage.setItem("refreshtoken", refreshpayload);
      commit("setRefreshToken", refreshpayload)
    },
    // Guardar setting en local Storage
    guardarSetting({ commit }, settingpayload) {
      localStorage.setItem("setting", settingpayload);
      commit("setSetting", settingpayload)
    },
    // Guardar avatar en local Storage
    guardarAvatar({ commit }, avatarpayload) {
      localStorage.setItem("avatar", avatarpayload);
      commit("setAvatar", avatarpayload)
    },
    // Guardar logo en local Storage
    guardarLogo({ commit }, logopayload) {
      localStorage.setItem("logo", logopayload);
      commit("setLogo", logopayload)
    },

    // Verificamos si tiene token y si no salimos del sistema
    autoLogin({ commit }) {
      let token = localStorage.getItem("token")
      let refreshtoken = localStorage.getItem("refreshtoken")
      let avatar = localStorage.getItem("avatar")
      let setting = localStorage.getItem("setting")
      let logo = localStorage.getItem("logo")
      if (token) {
        commit("setToken", token)
        commit("setRefreshToken", refreshtoken)
        commit("setSetting", setting)
        commit("setAvatar", avatar)
        commit("setLogo", logo)
        // Si actualizamos pagina
        this.dispatch("cargarNotificaciones");
        this.dispatch("cargarNotifCpes");
        this.dispatch("cargarSettings");
      } else {
        commit('setToken', '');
        commit("setRefreshToken", '')
        commit('setAvatar', '');
        commit('setSetting', '');
        commit('setLogo', '');
      }
    },

    salir({ commit }) {
      commit("setToken", '')
      commit("setRefreshToken", '')
      commit('setSetting', '');
      commit("setAvatar", '')
      commit('setLogo', '');
      localStorage.removeItem("token")
      localStorage.removeItem("refreshtoken")
      localStorage.removeItem("setting")
      localStorage.removeItem("avatar")
      localStorage.removeItem("logo")
      router.push({ name: 'login' })
    },
    // Accion para llamar la API 
    cargarNotificaciones({ commit }) {
      axios
        .get("api/Detalle_Cuentas/ListarDetalle", {
          params: {
            idsucursal: parseInt(this.state.usuario.idsucursal),
          },
        })
        .then(response => response.data)
        .then(notificaciones => {
          //console.log("Notificación Actualizado");
          commit('setNotificaciones', notificaciones)
        })
        .catch(function (error) {
          //console.log("Notificación Erroneo");
        });
    },

    cargarNotifCpes({ commit }) {
      axios
        .get("api/Movimientos/ListarNotifCpes", {
          params: {
            idsucursal: parseInt(this.state.usuario.idsucursal),
          },
        })
        .then(response => response.data)
        .then(notifcpes => {
          //console.log("Cpe Actualizado");
          commit('setNotifCpes', notifcpes)
        })
        .catch(function (error) {
          //console.log("Cpe Erroneo");
        });
    },

    cargarSettings() {
      const tokens = {
        token: localStorage.getItem("token"),
      };

      axios.post("api/Usuarios/Setting", tokens)
        .then(({ data }) => {
          const setting = data.setting;
          const avatar = data.avatar;
          const logo = data.logo;
          this.dispatch("guardarSetting", setting);
          this.dispatch("guardarAvatar", avatar);
          this.dispatch("guardarLogo", logo);
          console.log("Setting Actualizado");
        })
        .catch((err) => {
          console.log("Setting Erroneo");
          //this.dispatch("salir"); // Genera error
        })
    },
  },

  getters: {
    estaActivo: state => !!state.token,
    esAdministrador: state => state.usuario.rol == "Administrador",
    esAlmacenero: state => state.usuario.rol == "Almacenero",
    esVendedor: state => state.usuario.rol == "Vendedor",
    esCaja: state => state.usuario.rol == "Caja",
    // captador notificaciones y notifcpes
    notificaciones: state => {
      return state.notificaciones;
    },
    notifcpes: state => {
      return state.notifcpes;
    },
  }
})
