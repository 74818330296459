<template>
  <HelloInicio />
</template>

<script>
  import HelloInicio from '../components/HelloInicio'

  export default {
    components: {
      HelloInicio,
    },
  }
</script>
