<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <!-- Listado baja -->
        <v-data-table :loading="loading" :headers="headers" :items="detalles" :items-per-page="15"
          item-key="idmovimiento" show-expand class="elevation-3" dense>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">ANULACIONES</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Formulario busqueda de baja -->
              <v-text-field class="mr-1" v-model.trim="buscar" dense label="Búsqueda" @keyup="listar()" single-line
                hide-details>
              </v-text-field>
              <v-spacer />
              <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
                offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details class="mr-1"
                    append-icon="update" @click:append="listar()" style="max-width: 220px;"></v-text-field>
                </template>
                <v-date-picker v-model="dates"
                  @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listar()"
                  range></v-date-picker>
              </v-menu>
              <v-spacer />
              <!-- Fin Formulario Busqueda de baja -->

              <!-- Modal anular baja -->
              <v-dialog v-model="adModal" max-width="330">
                <v-card>
                  <v-card-title class="justify-center">¿Anular Baja?
                  </v-card-title>
                  <v-card-text>
                    Estás a punto de
                    <span>Anular</span>
                    la Baja: {{ adBaja }}
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="info" @click="anularCerrar" small outlined>Cancelar</v-btn>
                    <v-btn color="error" @click="anular" small outlined>Anular</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal anular baja -->
            </v-toolbar>
          </template>
          <!-- Opciones -->
          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>
          <template v-slot:item.fecha_comprobante="{ item }">
            <span>{{ item.fecha_comprobante | formatearFecha }}</span>
          </template>
          <template v-slot:item.comp_referencia="{ item }">
            <strong v-for="item, i in JSON.parse(item.ref_comprobante)" :key="i" text>{{ item.serie_comprobante }}-{{
          item.num_comprobante
        }}</strong>
          </template>
          <template v-slot:item.identificador="{ item }">
            <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
          </template>

          <template v-slot:item.estado="{ item }">
            <template v-if="item.estado == 'Aceptado'">
              <v-chip small color='success' class="white--text font-weight-bold" label>{{ item.estado }}</v-chip>
            </template>
            <template v-else-if="item.estado == 'Rechazado'">
              <v-chip small color='error' class="white--text font-weight-bold" label>{{ item.estado }}</v-chip>
            </template>
            <template v-else-if="item.estado == 'Enviado'">
              <span class="orange--text">{{ item.estado }}</span>
            </template>
            <template v-else-if="item.estado == 'Por enviar'">
              <span class="brown--text">{{ item.estado }}</span>
            </template>
            <template v-else-if="item.estado == 'En proceso'">
              <span class="pink--text">{{ item.estado }}</span>
            </template>
            <template v-else>
              <span>{{ item.estado }}</span>
            </template>
          </template>

          <template v-slot:item.descargas="{ item }">
            <template v-if="item.estado == 'Enviado' || item.estado == 'Aceptado'">
              <v-chip color="cyan" class="white--text" x-small @click="descargarXml(item)">XML</v-chip>
            </template>
            <template v-if="item.estado == 'Aceptado'">
              <v-chip color="teal" class="white--text" x-small @click="descargarCdr(item)">CDR</v-chip>
            </template>
          </template>
          <template v-slot:item.opciones="{ item }">
            <template v-if="item.estado == 'Enviado' && !isNaN(item.nro_ticket) || item.estado == 'En proceso'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn x-small v-bind="attrs" v-on="on" color="orange" class="white--text"
                    @click="consultarTicket(item)" :loading="consultar" :disabled="consultar" label>Enviar Baja</v-btn>
                </template>
                <span>Completar anulación</span>
              </v-tooltip>
            </template>
            <template v-if="item.estado == 'Por enviar' || item.estado == 'Creado'">
              <v-btn x-small color="brown" class="white--text" @click="reenviarBaja(item)" :loading="consultar"
                :disabled="consultar" label>Reenviar</v-btn>
            </template>

            <template
              v-if="usuario.idusuario == 1 && item.estado == 'Enviado' && !isNaN(item.nro_ticket) || item.estado == 'Creado' || item.estado == 'Por enviar' || item.estado == 'En proceso'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small icon v-bind="attrs" v-on="on">
                    <v-icon color="error" @click="anularMostrar(item)">delete</v-icon>
                  </v-btn>
                </template>
                <span>Anular</span>
              </v-tooltip>
            </template>
          </template>
          <!-- Fin Opciones -->
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Registrado por:</strong>
              {{ item.usuario }}
              <br />
              <strong v-if="item.msj_cdr.length > 0">SUNAT:</strong>
              {{ item.msj_cdr }}
            </td>
          </template>
        </v-data-table>
        <!-- Fin Listado baja -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      detalles: [],
      dialog: false,
      headers: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "FEC. EMISIÓN", value: "fecha", sortable: false },
        { text: "FEC. REF.", value: "fecha_comprobante", sortable: false },
        { text: "COMPROB. REF.", value: "comp_referencia", sortable: false, width: 140 },
        { text: "IDENTIFICADOR", value: "identificador", sortable: false, width: 140 },
        { text: "TICKET", value: "nro_ticket", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "", value: "descargas", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      buscar: "",

      fecha_emision_formateada: "",
      fecha_emision: moment().format("YYYY-MM-DD"),
      menuFecha: false,

      consultar: false,
      loading: false,

      adModal: 0,
      adId: "",
      adBaja: "",

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,
    };
  },

  computed: {
    ...mapState(["usuario", "datos"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  created() {
    this.fechaseleccionada(this.fecha_emision);
    this.listar();
  },

  methods: {
    ...mapActions(["snackBar", "cargarNotifCpes"]),

    //#region Baja
    listar() {
      let me = this;
      me.loading = true;

      if (me.buscar == "") {
        axios.get("api/Movimientos/ListarBaja", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            idusuario: parseInt(me.usuario.idusuario),
            buscar: me.buscar == "" ? "-" : me.buscar,
            fechaInicio: me.dates[0],
            fechaFin: me.dates[1] == undefined ? me.dates[0] + " 23:59:59" : me.dates[1] + " 23:59:59",
          },
        })
          .then(function (response) {
            me.detalles = response.data;
            me.loading = false;
            me.cargarNotifCpes();// Actualizamos Notificacion Cpes
          })
          .catch((err) => {
            //console.log(err.response);
          });
      } else {
        axios.get("api/Movimientos/ListarBajaTotal", {
          params: {
            idsucursal: parseInt(me.usuario.idsucursal),
            buscar: me.buscar == "" ? "-" : me.buscar,
            fechaInicio: me.dates[0],
            fechaFin: me.dates[1] == undefined ? me.dates[0] + " 23:59:59" : me.dates[1] + " 23:59:59",
          },
        })
          .then(function (response) {
            me.detalles = response.data;
            me.loading = false;
          })
          .catch((err) => {
            //console.log(err.response);
          });
      }

    },

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_emision_formateada = ddmmyy;
    },

    reenviarBaja(item) {
      let me = this;
      me.consultar = true;

      if (item.idtipo_comprobante == "81") {
        axios.put("api/Movimientos/ReenviarResumenDiarioSunat/" + item.idmovimiento)
          .then(function (response) {
            me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta });
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.limpiar();
            me.snackBar({ cl: "error", msg: error.response.data }); // Error de Pc
          });
      } else if (item.idtipo_comprobante == "82") {
        axios.put("api/Movimientos/ReenviarComunicacionBajaSunat/" + item.idmovimiento)
          .then(function (response) {
            me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta });
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.limpiar();
            me.snackBar({ cl: "error", msg: error.response.data }); // Error de Pc
          });
      }
    },

    consultarTicket(item) {
      let me = this;
      me.consultar = true;

      axios.put("api/Movimientos/ConsultarTicket/" + item.idmovimiento)
        .then(function (response) {
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta });
          me.listar();
          me.limpiar();
        })
        .catch(function (error) {
          me.limpiar();
          me.snackBar({ cl: "error", msg: error.response.data }); // Error de Pc
        });
    },

    descargarXml(item) {
      let me = this;

      axios.get("api/Movimientos/DescargarXmlResumen/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", me.datos.ruc + "-" + item.serie_comprobante + "-" + item.num_comprobante + ".xml");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    descargarCdr(item) {
      let me = this;

      axios.get("api/Movimientos/DescargarCdrResumen/" + item.idmovimiento)
        .then(function (response) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute( "download", "R-" + me.datos.ruc + "-" + item.serie_comprobante +"-" +item.num_comprobante +".xml");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    /* Anular */
    anularMostrar(item) {
      this.adModal = 1;
      this.adId = item.idmovimiento;
      this.adBaja = item.num_comprobante;
    },

    anularCerrar() {
      this.limpiarAnular();
    },

    anular() {
      let me = this;

      axios.put("api/Movimientos/AnularRdiarioCbaja/" + me.adId)
        .then(function (response) {
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta }); // Respuesta de sunat
          me.limpiarAnular();
          me.listar();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
          me.limpiarAnular();
        });
    },

    limpiarAnular() {
      this.adModal = 0;
      this.adId = "";
      this.adBaja = "";
    },
    /* Fin Anular */

    limpiar() {
      this.consultar = false;

      this.fecha_emision = moment().format("YYYY-MM-DD");
      this.fechaseleccionada(this.fecha_emision); // Cargamos fecha actual a fecha_comprobante y fecha_comprobate_formateada
    },
    //#endregion Baja
  },
};
</script>
