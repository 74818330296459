<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <v-data-table :loading="loading" :headers="cabeceraVentaDetallada" :items="ventasDetalle" :items-per-page="15"
          :search="buscar" dense class="elevation-3">
          <template v-slot:top>
            <v-form v-model="isValid">
              <v-toolbar flat>
                <v-toolbar-title class="hidden-sm-and-down">VENTAS DETALLADAS</v-toolbar-title>
                <v-divider class="mx-3 hidden-sm-and-down" inset vertical></v-divider>
                <!-- Formulario busqueda -->
                <v-btn icon small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid" class="mr-1">
                  <v-icon>description</v-icon>
                </v-btn>
                <v-spacer />
                <v-autocomplete dense v-model="idsucursal" :items="selectSucursales"
                  :search-input.sync="buscar_sucursal" label="Establecimiento" :rules="[v => !!v || 'Requerido']"
                  class="mr-1" @change="ventasDetalle = []" hide-details style="max-width: 130px;"></v-autocomplete>
                <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                  min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                  <template v-slot:activator="{ on }">
                    <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details class="mr-1"
                      style="max-width: 190px;">
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                    range>
                  </v-date-picker>
                </v-menu>
                <v-select dense v-model="idtipo_pago" :items="tipo_pagos" label="Tipo Pago" hide-details
                  class="mr-1" style="max-width: 130px;"></v-select>
                <v-text-field v-model.trim="buscar" dense label="Búsqueda" single-line hide-details class="mr-1"
                  :disabled="desabilitar || !isValid"></v-text-field>
                <v-btn icon small color="primary" @click="listar" :loading="desabilitar"
                  :disabled="desabilitar || !isValid">
                  <v-icon>search</v-icon>
                </v-btn>
                <v-spacer />
                <!-- Fin Formulario Busqueda -->
              </v-toolbar>
            </v-form>
          </template>
          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>
          <template v-slot:item.descripcion_adicional="{ item }">
            <span>{{ item.descripcion_adicional }}</span>
          </template>

          <template v-slot:item.precio_compra="{ item }">
            <div class="text-right">
              <span>{{ common.roundOut(item.precio_compra, 2) | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.cantidad="{ item }">
            <div class="text-center">
              <strong class="primary--text">{{ item.cantidad }}</strong>
            </div>
          </template>
          <template v-slot:item.valor_venta="{ item }">
            <div class="text-right primary--text">
              {{ common.roundOut(item.valor_venta, 2) | formatearNumero }}
            </div>
          </template>
          <template v-slot:item.igv="{ item }">
            <div class="text-right primary--text">{{ common.roundOut(item.precio_venta - item.valor_venta, 2) |
              formatearNumero
              }}</div>
          </template>
          <template v-slot:item.precio_venta="{ item }">
            <div class="text-right">
              <strong class="primary--text">{{ common.roundOut(item.precio_venta, 2) |
                formatearNumero
                }}</strong>
            </div>
          </template>
          <template v-slot:item.ganancia="{ item }">
            <div class="text-right">
              <strong class="primary--text">{{ common.roundOut(item.precio_venta - item.precio_compra, 2) |
                formatearNumero
                }}</strong>
            </div>
          </template>

          <template slot="body.append">
            <tr>
              <td :colspan="5" class="text-right font-weight-bold">TOTAL:</td>
              <td class="text-center font-weight-bold">{{ totals.cantidad }}</td>
              <td class="text-right font-weight-bold">{{ common.roundOut(totals.precio_compra, 2) | formatearNumero }}
              </td>
              <td :colspan="2"></td>
              <td class="text-right font-weight-bold">{{ common.roundOut(totals.precio_venta, 2) | formatearNumero }}
              </td>
              <td class="text-right font-weight-bold">{{ common.roundOut(totals.precio_venta - totals.precio_compra, 2)
                | formatearNumero
                }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import XLSX from "xlsx";

export default {
  data() {
    return {
      ventasDetalle: [],
      dialog: false,
      cabeceraVentaDetallada: [
        //{ text: "Fecha", value: "fecha" },
        //{ text: "CdSc", value: "idsucursal" },
        //{ text: "CdUs", value: "idusuario" },
        //{ text: "CdMv", value: "idmovimiento" },
        { text: "Fecha", value: "fecha", sortable: false },
        { text: "Cliente", value: "cliente", sortable: false },
        { text: "Comprobante", value: "comprobante", sortable: false },
        { text: "Descripción", value: "descripcion_adicional", sortable: false },
        { text: "Unidad", value: "unidad", sortable: false, divider: true },
        { text: "Cant.", value: "xcantidad", sortable: false, divider: true },
        { text: "Precio Compra", value: "precio_compra", sortable: false, divider: true },
        { text: "Valor Venta", value: "valor_venta", sortable: false, },
        { text: "IGV", value: "igv", sortable: false, },
        { text: "Precio Venta", value: "precio_venta", sortable: false, divider: true },
        { text: "Ganancia", value: "ganancia", sortable: false },
      ],
      idsucursal: "",
      selectSucursales: [],
      buscar_sucursal: "",
      buscar: "",

      idtipo_pago: 1,
      tipo_pagos: [],

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      desabilitar: false,
      isValid: true,
      loading: false,
      desabilitarbuscar: true,
    };
  },

  computed: {
    ...mapState(["usuario"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    totals() {
      const totals = this.ventasDetalle.reduce((acc, d) => {
        acc.cantidad += d.cantidad
        acc.precio_compra += d.precio_compra
        acc.valor_venta += d.valor_venta
        acc.precio_venta += d.precio_venta
        return acc
      }, {
        cantidad: 0,
        precio_compra: 0,
        valor_venta: 0,
        precio_venta: 0
      })
      return totals
    },
  },

  created() {
    this.select();
    this.idsucursal = parseInt(this.usuario.idsucursal);
  },

  methods: {
    ...mapActions(["snackBar"]),

    /* Listar personas */
    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios.get("api/Movimientos/ReporteVentaDetallada", {
        params: {
          idsucursal: parseInt(me.idsucursal),
          fechaInicio: me.dates[0],
          fechaFin: me.dates[1] == undefined ? me.dates[0] + " 23:59:59" : me.dates[1] + " 23:59:59",
          idtipo_pago: me.idtipo_pago
        },
      })
        .then(function (response) {
          me.ventasDetalle = response.data;
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "success", msg: "Procesado correctamente." });
          //console.log(me.ventasDetalle);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "error", msg: "Ocurrio un error" });
          //console.log(err.response);
        });
    },

    /* Seleccionar tipos */
    select() {
      let me = this;
      var sucursalesArray = [];
      axios.get("api/Sucursales/Select")
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            me.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          console.log(error);
        });

      var tipo_pagosArray = [];
      axios.get("api/Tipo_Pagos/SelectVentaDetallada")
        .then(function (response) {
          tipo_pagosArray = response.data;
          tipo_pagosArray.map(function (x) {
            me.tipo_pagos.push({
              text: x.descripcion,
              value: x.idtipo_pago,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    crearXLSX() {
      var rows = [];

      for (var i = 0; i < this.ventasDetalle.length; i++) {
        rows.push({
          CdSc: this.ventasDetalle[i].idsucursal,
          CdUs: this.ventasDetalle[i].idusuario,
          CdMv: this.ventasDetalle[i].idmovimiento,
          "Tipo Pago": this.ventasDetalle[i].tipo_pago,
          Fecha: moment(this.ventasDetalle[i].fecha).format("DD/MM/YYYY"),
          Cliente: this.ventasDetalle[i].cliente,
          Comprobante: this.ventasDetalle[i].comprobante,
          "Cod. Producto": this.ventasDetalle[i].codigo_producto,
          Descripción: this.ventasDetalle[i].descripcion_adicional,
          Categoría: this.ventasDetalle[i].categoria,
          Unidad: this.ventasDetalle[i].unidad,
          Cantidad: this.ventasDetalle[i].xcantidad,
          "Precio Compra": this.common.roundOut(this.ventasDetalle[i].precio_compra, 2),
          "Valor Venta": this.common.roundOut(this.ventasDetalle[i].valor_venta, 2),
          "IGV": this.common.roundOut(this.ventasDetalle[i].precio_venta - this.ventasDetalle[i].valor_venta, 2),
          "Precio Venta": this.common.roundOut(this.ventasDetalle[i].precio_venta, 2),
          "Ganancia": this.common.roundOut(this.ventasDetalle[i].precio_venta - this.ventasDetalle[i].precio_compra, 2),
        });
      }

      var Detalle = XLSX.utils.json_to_sheet(rows);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, Detalle, "Ventas Detalladas");
      XLSX.writeFile(wb, "VentasDetalladas " + moment(this.dates[0]).format("DD-MM-YYYY") + " al " + moment(this.dates[1]).format("DD-MM-YYYY") + ".xlsx");
    },
  },
};
</script>
