<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <!-- Listado categorias -->
        <v-data-table :loading="loading" :headers="headers" :items="categorias" :items-per-page="15" :search="buscar"
          dense class="elevation-3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">CATEGORIAS</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Impresion categoría -->
              <v-btn icon small color="print" @click="crearPDF()" class="mr-1">
                <v-icon>print</v-icon>
              </v-btn>
              <!-- Fin impresion categoría -->
              <v-spacer />
              <!-- Formulario busqueda de categoría -->
              <v-text-field class="mr-1 text-xs-center" v-model.trim="buscar" dense label="Búsqueda" single-line
                hide-details></v-text-field>
              <!-- Fin Formulario Busqueda de categoría -->
              <v-spacer />
              <!-- Modal crear y modificar categoría -->
              <v-dialog v-model="dialog" max-width="500px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="hidden-sm-and-down" v-on="on" small outlined>Nuevo</v-btn>
                  <v-btn color="primary" class="hidden-md-and-up" v-on="on" fab x-small outlined>
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark dense class="primary" flat>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="nombre" label="Nombre" maxlength="50"
                            prepend-inner-icon="short_text"
                            :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 50) || 'Nombre debe tener entre 3 a 50 caracteres', Rules.unespacio]"
                            v-uppercase hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="descripcion" label="Descripcion"
                            :rules="[v => v.length <= 100 || 'La descripción no debe tener mas de 100 caracteres', Rules.unespacio]"
                            maxlength="100" prepend-inner-icon="notes" v-uppercase hide-details></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 px-4 justify-center">
                    <v-btn color="error" @click="close" small outlined>
                      <v-icon left>close</v-icon>Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                      small outlined>
                      <v-icon left>check</v-icon>Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal crear y modificar categoría -->

              <!-- Modal activar, desactivar categoria -->
              <v-dialog v-model="adModal" max-width="300">
                <v-card>
                  <v-card-title class="justify-center" v-if="adAccion == 1">¿Activar Categoria?</v-card-title>
                  <v-card-title class="justify-center" v-if="adAccion == 2">¿Desactivar Categoria?</v-card-title>
                  <v-card-text>
                    Estás a punto de
                    <span v-if="adAccion == 1">Activar</span>
                    <span v-if="adAccion == 2">Desactivar</span>
                    la Categoria {{ adNombre }}
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="success" @click="activarDesactivarCerrar" small outlined>Cancelar</v-btn>
                    <v-btn v-if="adAccion == 1" color="info" @click="activar" small outlined>Activar</v-btn>
                    <v-btn v-if="adAccion == 2" color="info" @click="desactivar" small outlined>Desactivar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal activar, desactivar categoria -->
            </v-toolbar>
          </template>
          <!-- Opciones editar, eliminar categoria -->
          <template v-slot:item.condicion="{ item }">
            <template v-if="item.condicion">
              <v-btn text small color="success" @click="activarDesactivarMostrar(2, item)">Activo</v-btn>
            </template>
            <template v-else>
              <v-btn text small color="info" @click="activarDesactivarMostrar(1, item)">Inactivo</v-btn>
            </template>
          </template>
          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="primary" @click="editItem(item)">edit</v-icon>
            </v-btn>
            <v-btn small icon>
              <v-icon color="error" @click="deleteItem(item)">delete_forever</v-icon>
            </v-btn>
          </template>
          <!-- Fin Opciones editar, eliminar categoria -->
        </v-data-table>
        <!-- Fin Listado categorias -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      categorias: [],
      dialog: false,
      headers: [
        { text: "NOMBRE", value: "nombre", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "CONDICIÓN", align: "center", value: "condicion", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 90 },
      ],
      loading: false,

      buscar: "",
      editedIndex: -1,
      id: "",
      nombre: "",
      descripcion: "",
      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",

      desabilitar: false,
      isValid: true,

      /* Validaciones */
      Rules: {
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["logo"]),

    formTitle() {
      return this.editedIndex === -1
        ? "Nueva categoría"
        : "Actualizar categoría";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
  },

  methods: {
    ...mapActions(["snackBar"]),

    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Categorias/Listar")
        .then(function (response) {
          me.categorias = response.data;
          me.loading = false;
        })
        .catch((err) => {
          //console.log(err.response);
        });
    },

    guardar() {
      let me = this;
      me.desabilitar = true;

      if (me.editedIndex > -1) {
        axios.put("api/Categorias/Actualizar", {
          idcategoria: me.id,
          nombre: me.nombre,
          descripcion: me.descripcion,
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      } else {
        axios.post("api/Categorias/Crear", {
          nombre: me.nombre,
          descripcion: me.descripcion,
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data.mensaje });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      }
    },

    deleteItem(item) {
      const respta = confirm(
        "¿Estás seguro de que deseas eliminar la categoria " + item.nombre + "?"
      );
      if (respta) {
        let me = this;
        axios.delete("api/Categorias/Eliminar/" + item.idcategoria)
          .then((response) => {
            me.snackBar({ cl: "success", msg: response.data });
            this.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    editItem(item) {
      this.id = item.idcategoria;
      this.nombre = item.nombre;
      this.descripcion = item.descripcion;
      this.editedIndex = 1;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    limpiar() {
      this.id = "";
      this.nombre = "";
      this.descripcion = "";
      this.editedIndex = -1;
    },

    /* Desactivar, activar categoría */
    desactivar() {
      let me = this;

      axios.put("api/Categorias/Desactivar/" + this.adId, {})
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    activarDesactivarMostrar(accion, item) {
      this.adModal = 1;
      this.adNombre = item.nombre;
      this.adId = item.idcategoria;
      if (accion == 1) {
        this.adAccion = 1;
      } else if (accion == 2) {
        this.adAccion = 2;
      } else {
        this.adModal = 0;
      }
    },

    activarDesactivarCerrar() {
      this.adModal = 0;
    },

    activar() {
      let me = this;
      axios.put("api/Categorias/Activar/" + this.adId, {})
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* Fin Desactivar, activar categoría */

    crearPDF() {
      var columns = [
        { header: "NOMBRE", dataKey: "nombre" },
        { header: "DESCRIPCION", dataKey: "descripcion" },
        { header: "CONDICION", dataKey: "condicion" },
      ];
      var body = [];

      this.categorias.map(function (x) {
        body.push({
          nombre: x.nombre,
          descripcion: x.descripcion,
          condicion: x.condicion == 1 ? "Activo" : "Inactivo",
        });
      });

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "bold");
        doc.setFontSize(10);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("LISTADO DE CATEGORIAS", doc.internal.pageSize.width / 2, 45, { align: "center" });
          doc.addImage(imgData, "JPEG", 40, 17, 70, 49.39);
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(6);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("Página " + String(i) + " de " + String(pageCount), doc.internal.pageSize.width / 2, 820, { align: "center" });
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        margin: { top: 70 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        columnStyles: {
          nombre: { cellWidth: "150" },
          descripcion: { cellWidth: "250" },
          condicion: { cellWidth: "115", halign: "center" },
        },
      });

      addHeaders(doc);
      addFooters(doc);
      doc.save("Categorias.pdf");
    },
  },
};
</script>
