<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <v-data-table :loading="loading" :headers="cabeceraStockProducto" :items="stockProductos" :search="buscar"
          :items-per-page="15" dense class="elevation-3">
          <template v-slot:top>
            <v-form v-model="isValid">
              <v-toolbar flat>
                <v-toolbar-title class="hidden-sm-and-down">STOCK DE PRODUCTOS</v-toolbar-title>
                <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
                <!-- Formulario busqueda -->
                <v-btn icon small color="print" @click="crearPDF()" :disabled="desabilitar || !isValid" class="mr-1">
                  <v-icon>print</v-icon>
                </v-btn>
                <v-btn icon small color="success" @click="crearXLSX()" :disabled="desabilitar || !isValid" class="mr-1">
                  <v-icon>description</v-icon>
                </v-btn>
                <v-spacer />
                <v-autocomplete dense v-model="idsucursal" :items="selectSucursales" :search-input.sync="buscar_sucursal"
                  label="Establecimiento" :rules="[v => !!v || 'Requerido']" @change="stockProductos = []" class="mr-1"
                  hide-details></v-autocomplete>
                <v-select dense v-model="filtro" :items="filtros" label="Filtro" class="mr-1" hide-details></v-select>
                <v-text-field v-model.trim="buscar" dense label="Búsqueda" single-line hide-details class="mr-1"
                  :disabled="desabilitar || !isValid"></v-text-field>
                <v-btn icon small color="primary" @click="listar" :loading="desabilitar"
                  :disabled="desabilitar || !isValid">
                  <v-icon>search</v-icon>
                </v-btn>
                <v-spacer />
                <!-- Fin Formulario Busqueda -->
              </v-toolbar>
            </v-form>
          </template>
          <template v-slot:item.codigos="{ item }">
            <strong class="primary--text">{{ item.codigo_producto }} </strong>
          </template>
          <template v-slot:item.marca="{ item }">
            <strong class="error--text">{{ item.marca }}</strong>
          </template>
          <template v-slot:item.precio_stockactual="{ item }">
            <div class="text-right">
              <strong>{{ item.precio_stockactual | formatearNumero }}</strong>
            </div>
          </template>

          <template v-slot:item.stockactual="{ item }">
            <template v-if="item.stockactual == item.stock_minimo">
              <v-chip color="warning" small dark>{{ item.stockactual }}</v-chip>
            </template>
            <template v-else-if="item.stockactual > item.stock_minimo">
              <v-chip color="success" small dark>{{ item.stockactual }}</v-chip>
            </template>
            <template v-else>
              <v-chip color="error" small dark>{{ item.stockactual }}</v-chip>
            </template>
          </template>

          <template v-slot:item.precio_stockvendido="{ item }">
            <div class="text-right">
              <span>{{ common.roundOut(item.precio_stockvendido, 2) | formatearNumero }}</span>
            </div>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState, mapActions } from "vuex";
import numeral from "numeral";
import XLSX from "xlsx";

export default {
  data() {
    return {
      stockProductos: [],
      dialog: false,
      cabeceraStockProducto: [
        { text: "idproducto", value: "idproducto", sortable: false },
        { text: "Códigos", value: "codigos", sortable: false },
        { text: "Nombre", value: "descripcion", sortable: false },
        { text: "Unidad", value: "unidad", sortable: false },
        { text: "Categoría", value: "categoria", sortable: false },
        { text: "Marca", value: "marca", sortable: false, divider: true },
        { text: "Stock Actual Nº", value: "stockactual", align: "center", sortable: false },
        { text: "Stock Actual S/", value: "precio_stockactual", sortable: false },
      ],
      loading: false,

      idsucursal: "",
      selectSucursales: [],
      buscar_sucursal: "",

      filtro: "",
      filtros: [
        { text: "", value: "T" },
        { text: "Verde", value: "V" },
        { text: "Amarillo", value: "A" },
        { text: "Rojo", value: "R" },
      ],

      buscar: "",

      desabilitar: false,
      isValid: true,
    };
  },

  computed: {
    ...mapState(["usuario", "logo"]),
  },

  created() {
    this.select();
    this.idsucursal = parseInt(this.usuario.idsucursal);
  },

  methods: {
    ...mapActions(["snackBar"]),

    /* Listar */
    listar() {
      let me = this;
      me.desabilitar = true;
      me.loading = true;

      axios.get("api/Productos/ReporteStockProducto", {
        params: {
          idsucursal: parseInt(me.idsucursal),
          filtro: me.filtro,
        },
      })
        .then(function (response) {
          me.stockProductos = response.data;
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "success", msg: "Procesado correctamente." });
          //console.log(me.stockProductos);
        })
        .catch((err) => {
          me.desabilitar = false;
          me.loading = false;
          me.snackBar({ cl: "error", msg: "Ocurrio un error" });
          //console.log(err.response);
        });
    },

    /* Seleccionar tipos */
    select() {
      let su = this;
      var sucursalesArray = [];
      axios.get("api/Sucursales/Select")
        .then(function (response) {
          sucursalesArray = response.data;
          sucursalesArray.map(function (x) {
            su.selectSucursales.push({ text: x.nombre, value: x.idsucursal });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    crearPDF() {
      var columns = [
        { header: "Código", dataKey: "codigo_producto" },
        { header: "Nombre", dataKey: "descripcion" },
        { header: "Unidad", dataKey: "unidad" },
        { header: "Categoría", dataKey: "categoria" },
        { header: "Marca", dataKey: "marca" },
        { header: "Stock Actual Nº", dataKey: "stockactual" },
        { header: "Stock Actual S/", dataKey: "precio_stockactual" },
      ];
      var body = [];
      var foot = [];

      var totalstockactual = 0;
      var totalprecio_stockactual = 0;

      for (var i = 0; i < this.stockProductos.length; i++) {
        totalstockactual = totalstockactual + parseFloat(this.stockProductos[i].stockactual);
        totalprecio_stockactual = totalprecio_stockactual + parseFloat(this.stockProductos[i].precio_stockactual);
        body.push({
          codigo_producto: this.stockProductos[i].codigo_producto,
          descripcion: this.stockProductos[i].descripcion,
          unidad: this.stockProductos[i].unidad,
          categoria: this.stockProductos[i].categoria,
          marca: this.stockProductos[i].marca,
          stock_minimo: this.stockProductos[i].stock_minimo,
          stockactual: this.stockProductos[i].stockactual,
          precio_stockactual: numeral(this.common.roundOut(this.stockProductos[i].precio_stockactual, 2)).format("###,##0.00"),
        });

        if (i === this.stockProductos.length - 1) {
          foot.push([
            {
              content: "Total ",
              colSpan: 4,
            },
            totalstockactual,
            numeral(totalprecio_stockactual).format("###,##0.00"),
          ]);
        }
      }

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 70, 49.39);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text("STOCK DE PRODUCTOS", doc.internal.pageSize.width / 2, 45, { align: "center" });
          doc.setFontSize(8);
          doc.text("ESTABLECIMIENTO: " + this.buscar_sucursal.substr(0, 69), 40, 72);
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(6);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("Página " + String(i) + " de " + String(pageCount), doc.internal.pageSize.width / 2, 800, { align: "center" });
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        foot,
        margin: { top: 80 },
        //styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 7 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          codigo_producto: { cellWidth: 50 },
          descripcion: { cellWidth: "auto" },
          categoria: { cellWidth: 70 },
          marca: { cellWidth: 60 },
          stockactual: { cellWidth: 45, halign: "right" },
          precio_stockactual: { cellWidth: 60, halign: "right" },
        },
        // En la columna stockactual ponemos condicion para poner colores segun condicion de stock_minimo
        didParseCell: function (cell, data) {
          //console.log("Data = ", data);
          //console.log("cell = ", cell);
          var sMinimo;
          var sActual;
          sMinimo = cell.row.raw.stock_minimo;
          sActual = cell.row.raw.stockactual;
          if (sActual >= sMinimo && sActual <= sMinimo && cell.column.raw.dataKey == "stockactual") {
            cell.cell.styles.fontStyle = "bold";
            cell.cell.styles.textColor = [255, 192, 67]; // Amarillo
          } else if (sActual > sMinimo && cell.column.raw.dataKey == "stockactual") {
            cell.cell.styles.fontStyle = "bold";
            cell.cell.styles.textColor = [66, 175, 93]; // Verde
          } else if (sActual < sMinimo && cell.column.raw.dataKey == "stockactual") {
            cell.cell.styles.fontStyle = "bold";
            cell.cell.styles.textColor = [255, 80, 86]; // Rojo
          }
        },
      });

      addHeaders(doc);
      addFooters(doc);
      doc.save("StockProductos.pdf");
    },

    crearXLSX() {
      var rows = [];

      for (var i = 0; i < this.stockProductos.length; i++) {
        rows.push({
          idproducto: this.stockProductos[i].idproducto,
          "Código Producto": this.stockProductos[i].codigo_producto,
          Nombre: this.stockProductos[i].descripcion,
          Unidad: this.stockProductos[i].unidad,
          Categoría: this.stockProductos[i].categoria,
          Marca: this.stockProductos[i].marca,
          "Stock Actual Nº": this.stockProductos[i].stockactual,
          "Stock Actual S/": this.common.roundOut(this.stockProductos[i].precio_stockactual, 2),
        });
      }
      var Detalle = XLSX.utils.json_to_sheet(rows);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, Detalle, "Stock Productos");
      XLSX.writeFile(wb, this.buscar_sucursal + " StockProductos.xlsx");
    },
  },
};
</script>

