<template>
  <v-layout justify-center>
    <v-flex>
      <v-carousel cycle hide-delimiter-background show-arrows-on-hover height="380">
        <v-carousel-item hide-delimiter-background v-for="(item,i) in items" :key="i" :src="item.src">
          <v-row class="fill-height" align="center">
            <v-card-text align="left">
              <div class="ml-15 text-h3 font-weight-bold white--text shadow-grey">{{ slides[i] }}</div>
              <div class="ml-15 text-h5 font-weight-regular primary--text">{{ slides2[i] }}</div>
            </v-card-text>
          </v-row>
        </v-carousel-item>
      </v-carousel>
      <v-container>
        <v-row align="center" justify="center" class="mt-5">
          <v-col cols="12">
            <!-- presentacion -->
            <v-card-title class="text-h3 font-weight-bold justify-center primary--text">Facturación electrónica sin
              límite</v-card-title>
            <v-card-title class="text-h4 font-weight-regular justify-center grey--text">Muy facil de usar</v-card-title>
          </v-col>
        </v-row>
      </v-container>
      <v-item-group class="mt-10 mb-10">
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12" md="4" sm="4" align="center">
              <!-- Servicio 1 -->
              <v-card>
                <br />
                <v-img :src="require('../assets/web/imagen.png')" height="100px" width="100px"></v-img>
                <v-card-title class="text-h5 justify-center">Personalida tu logo</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" sm="4" align="center">
              <!-- Servicio 2 -->
              <v-card>
                <br />
                <v-img :src="require('../assets/web/house.png')" height="100px" width="100px"></v-img>
                <v-card-title class="text-h5 justify-center">Establecimientos/Locales</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" sm="4" align="center">
              <!-- Servicio 3 -->
              <v-card>
                <br />
                <v-img :src="require('../assets/web/email.png')" height="100px" width="100px"></v-img>
                <v-card-title class="text-h5 justify-center">Envío por email</v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
      <v-item-group class="mt-10 mb-10">
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12" md="4" sm="4" align="center">
              <!-- Servicio 4 -->
              <v-card>
                <br />
                <v-img :src="require('../assets/web/tiempo.png')" height="100px" width="100px"></v-img>
                <v-card-title class="text-h5 justify-center">Activación con SUNAT</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" sm="4" align="center">
              <!-- Servicio 5 -->
              <v-card>
                <br />
                <v-img :src="require('../assets/web/soporte.png')" height="100px" width="100px"></v-img>
                <v-card-title class="text-h5 justify-center">Soporte</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" sm="4" align="center">
              <!-- Servicio 6 -->
              <v-card>
                <br />
                <v-img :src="require('../assets/web/grafico.png')" height="100px" width="100px"></v-img>
                <v-card-title class="text-h5 justify-center">Informe de ventas</v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
      <v-item-group class="mt-10 mb-10">
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12" md="4" sm="4" align="center">
              <!-- Servicio 4 -->
              <v-card>
                <br />
                <v-img :src="require('../assets/web/stock.png')" height="100px" width="100px"></v-img>
                <v-card-title class="text-h5 justify-center">Control de stock</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" sm="4" align="center">
              <!-- Servicio 5 -->
              <v-card>
                <br />
                <v-img :src="require('../assets/web/seguridad.png')" height="100px" width="100px"></v-img>
                <v-card-title class="text-h5 justify-center">Seguridad</v-card-title>
              </v-card>
            </v-col>
            <v-col cols="12" md="4" sm="4" align="center">
              <!-- Servicio 6 -->
              <v-card>
                <br />
                <v-img :src="require('../assets/web/contabilidad.png')" height="100px" width="100px"></v-img>
                <v-card-title class="text-h5 justify-center">Contabilidad</v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-item-group>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          src: require("../assets/web/slider.png"),
        },
        {
          src: require("../assets/web/slider2.png"),
        },
        {
          src: require("../assets/web/slider3.png"),
        },
      ],

      slides: [
        " EMITE FACTURAS Y BOLETAS",
        " EMPIEZA A CRECER",
        " ORDENA TU NEGOCIO",
      ],
      slides2: [
        " Software de Facturación Electronica",
        " y expande tu negocio",
        " Controla tu inventario",
      ],
    };
  },
};
</script>

<style>
.shadow-grey {
  text-shadow: 2px 2px 2px grey;
}
</style>