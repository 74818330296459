<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <!-- Listado unidad de medida -->
        <v-data-table :loading="loading" :headers="headers" :items="unidad_medidas" :items-per-page="15"
          :search="buscar" dense class="elevation-3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">UNIDAD DE MEDIDA</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <!-- Impresion unidad medida -->
              <v-btn icon small color="print" @click="crearPDF()" class="mr-1">
                <v-icon>print</v-icon>
              </v-btn>
              <!-- Fin Impresion unidad medida -->
              <v-spacer />
              <!-- Formulario busqueda-->
              <v-text-field class="mr-1 text-xs-center" v-model.trim="buscar" append-icon="search" dense
                label="Búsqueda" single-line hide-details></v-text-field>
              <!-- Fin Formulario Busqueda-->
              <v-spacer />
              <!-- Modal crear y modificar unidad medida -->
              <v-dialog v-model="dialog" max-width="500px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="hidden-sm-and-down" v-on="on" small outlined>Nuevo</v-btn>
                  <v-btn color="primary" class="hidden-md-and-up" v-on="on" fab x-small outlined>
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark dense class="primary" flat>
                    <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="6">
                          <v-text-field dense v-model.trim="codigo" label="Código"
                            :rules="[v => (!!v && v.length >= 2 && v.length <= 3) || 'El código debe tener de 2 a 3 caracteres', Rules.unespacio]"
                            maxlength="3" prepend-inner-icon="short_text" v-uppercase
                            :disabled="editedIndex == 1 ? true : false" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6">
                          <v-text-field dense v-model.trim="abreviatura" label="Abreviatura"
                            :rules="[v => (!!v && v.length == 3) || 'La abreviatura debe tener 3 caracteres', Rules.unespacio]"
                            maxlength="3" prepend-inner-icon="short_text" v-uppercase
                            hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field dense v-model.trim="nombre" label="Descripción"
                            :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 20) || 'Nombre puede tener de 3 a 20 caracteres', Rules.unespacio]"
                            maxlength="20" prepend-inner-icon="notes" v-uppercase hide-details></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 px-4 justify-center">
                    <v-btn color="error" @click="close" small outlined>
                      <v-icon left>close</v-icon>Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                      small outlined>
                      <v-icon left>check</v-icon>Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal crear y modificar unidad medida -->

              <!-- Modal activar, desactivar -->
              <v-dialog v-model="adModal" max-width="290">
                <v-card>
                  <v-card-title class="justify-center" v-if="adAccion == 1">¿Activar U. M.?</v-card-title>
                  <v-card-title class="justify-center" v-if="adAccion == 2">¿Desactivar U. M. ?</v-card-title>
                  <v-card-text>
                    Estás a punto de
                    <span v-if="adAccion == 1">Activar</span>
                    <span v-if="adAccion == 2">Desactivar</span>
                    la Unidad de Medida {{ adNombre }}
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="success" @click="activarDesactivarCerrar" small outlined>Cancelar</v-btn>
                    <v-btn v-if="adAccion == 1" color="info" @click="activar" small outlined>Activar</v-btn>
                    <v-btn v-if="adAccion == 2" color="info" @click="desactivar" small outlined>Desactivar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal activar, desactivar -->
            </v-toolbar>
          </template>
          <!-- Opciones -->
          <template v-slot:item.condicion="{ item }">
            <template v-if="item.condicion">
              <v-btn text small color="success" @click="activarDesactivarMostrar(2, item)">Activo</v-btn>
            </template>
            <template v-else>
              <v-btn text small color="info" @click="activarDesactivarMostrar(1, item)">Inactivo</v-btn>
            </template>
          </template>
          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="primary" @click="editItem(item)">edit</v-icon>
            </v-btn>
            <v-btn small icon>
              <v-icon color="error" @click="deleteItem(item)">delete_forever</v-icon>
            </v-btn>
          </template>
          <!-- Fin Opciones -->
        </v-data-table>
        <!-- Fin Listado unidad de medida -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      unidad_medidas: [],
      dialog: false,
      headers: [
        { text: "CÓDIGO", value: "codigo", sortable: false },
        { text: "ABREVIATURA", value: "abreviatura", sortable: false },
        { text: "DESCRIPCIÓN", value: "nombre", sortable: false },
        { text: "CONDICIÓN", value: "condicion", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 100 },
      ],
      loading: false,

      buscar: "",
      editedIndex: -1,
      idunidad_medida: "",
      codigo: "",
      abreviatura: "",
      nombre: "",

      adModal: 0,
      adAccion: 0,
      adNombre: "",
      adId: "",

      desabilitar: false,
      isValid: true,

      /* Validaciones */
      Rules: {
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["logo"]),

    formTitle() {
      return this.editedIndex === -1 ? "Nueva Unidad de Medida" : "Actualizar Unidad de Medida";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.listar();
  },
  methods: {
    ...mapActions(["snackBar"]),

    /* Listar unidad medida */
    listar() {
      let me = this;

      me.loading = true;
      axios.get("api/Unidad_Medidas/Listar")
        .then(function (response) {
          //console.log(response);
          me.unidad_medidas = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    /* Editar y guardar unidad medida */
    guardar() {
      let me = this;
      me.desabilitar = true;

      if (this.editedIndex > -1) {
        //Código para editar
        axios.put("api/Unidad_Medidas/Actualizar", {
          idunidad_medida: me.idunidad_medida,
          codigo: me.codigo,
          abreviatura: me.abreviatura,
          nombre: me.nombre,
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      } else {
        //Código para guardar
        axios.post("api/Unidad_Medidas/Crear", {
          codigo: me.codigo,
          abreviatura: me.abreviatura,
          nombre: me.nombre,
        })
          .then(function (response) {
            me.desabilitar = false;
            me.snackBar({ cl: "success", msg: response.data });
            me.close();
            me.listar();
            me.limpiar();
          })
          .catch(function (error) {
            me.desabilitar = false;
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      }
    },

    /* Eliminar unidad medida */
    deleteItem(item) {
      const respta = confirm("¿Estás seguro de que deseas eliminar la Unidad de Medida " + item.nombre + "?");
      if (respta) {
        let me = this;
        axios.delete("api/Unidad_Medidas/Eliminar/" + item.idunidad_medida)
          .then((response) => {
            me.snackBar({ cl: "success", msg: response.data });
            this.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    /* Editar unidad medida item */
    editItem(item) {
      this.idunidad_medida = item.idunidad_medida;
      this.codigo = item.codigo;
      this.abreviatura = item.abreviatura;
      this.nombre = item.nombre;
      this.editedIndex = 1;
      this.dialog = true;
    },

    /* Cerrar ventana unidad medida */
    close() {
      this.dialog = false;
      this.limpiar();
    },

    /* Limpiar unidad medida item */
    limpiar() {
      this.idunidad_medida = "";
      this.codigo = "";
      this.abreviatura = "";
      this.nombre = "";
      this.editedIndex = -1;
    },

    /* Desactivar, activar unidad medida */
    desactivar() {
      let me = this;

      axios.put("api/Unidad_Medidas/Desactivar/" + this.adId, {})
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    activarDesactivarMostrar(accion, item) {
      this.adModal = 1;
      this.adNombre = item.nombre;
      this.adId = item.idunidad_medida;
      if (accion == 1) {
        this.adAccion = 1;
      } else if (accion == 2) {
        this.adAccion = 2;
      } else {
        this.adModal = 0;
      }
    },

    activarDesactivarCerrar() {
      this.adModal = 0;
    },

    activar() {
      let me = this;
      axios.put("api/Unidad_Medidas/Activar/" + this.adId, {})
        .then(function (response) {
          me.adModal = 0;
          me.adAccion = 0;
          me.adNombre = "";
          me.adId = "";
          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    crearPDF() {
      var columns = [
        { header: "ID", dataKey: "idunidad_medida" },
        { header: "CÓDIGO", dataKey: "codigo" },
        { header: "ABREVIATURA", dataKey: "abreviatura" },
        { header: "DESCRIPCIÓN", dataKey: "nombre" },
        { header: "CONDICION", dataKey: "condicion" },
      ];
      var body = [];

      this.unidad_medidas.map(function (x) {
        body.push({
          idunidad_medida: x.idunidad_medida,
          codigo: x.codigo,
          abreviatura: x.abreviatura,
          nombre: x.nombre,
          condicion: x.condicion == 1 ? "Activo" : "Inactivo",
        });
      });

      //console.log(body);

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "bold");
        doc.setFontSize(10);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("LISTADO UNIDAD DE MEDIDA", doc.internal.pageSize.width / 2, 45, { align: "center" });
          doc.addImage(imgData, "JPEG", 40, 17, 70, 49.39);
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(6);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("Página " + String(i) + " de " + String(pageCount), doc.internal.pageSize.width / 2, 820, { align: "center" }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        margin: { top: 70 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        columnStyles: {
          idunidad_medida: { cellWidth: 30 },
          codigo: { cellWidth: 50 },
          abreviatura: { cellWidth: 50 },
          nombre: { cellWidth: "auto" },
          condicion: { cellWidth: 115, halign: "center" },
        },
      });

      addHeaders(doc);
      addFooters(doc);
      doc.save("Unidad de Medida.pdf");
    },
  },
};
</script>