<template>
  <v-container fluid style="max-width: 1920px;">
    <v-layout align-start>
      <v-flex>
        <!-- Producto-->
        <v-dialog v-model="verProductos" max-width="1200px">
          <v-card>
            <v-toolbar dark dense class="primary mb-1" flat>
              <v-toolbar-title>Productos</v-toolbar-title>
              <v-spacer />
              <v-text-field dense solo-inverted v-model.trim="brDescripcion" label="Buscar Productos"
                prepend-inner-icon="search" hide-details @keyup="listarProductoBuscar" clearable>
              </v-text-field>
              <v-spacer />
              <v-btn icon @click="ocultarProductos()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <!-- Mostrar imagen de producto -->
              <v-dialog v-model="selectedZoom" max-width="350px">
                <v-avatar tile size="350">
                  <img :src="'data:image/png;base64,' + imagen_zoom" />
                </v-avatar>
              </v-dialog>
              <!-- Fin Mostrar imagen de producto -->

              <v-data-table :headers="cabeceraBusquedaProductos" :items="productos" item-key="iddetalle_compra"
                show-expand class="elevation-3" @dblclick:row="dblClickRepuesto" dense>
                <template v-slot:item.seleccionar="{ item }">
                  <v-icon @click="agregarDetalle(item)" color="primary">control_point</v-icon>
                </template>

                <template v-slot:item.imagen="{ item }">
                  <template>
                    <v-avatar tile size="27">
                      <img :src="'data:image/png;base64,' + item.imagen" @click="verImagen(item)" />
                    </v-avatar>
                  </template>
                </template>
                <template v-slot:item.marca="{ item }">
                  <strong class="error--text">{{ item.marca }}</strong>
                </template>
                <template v-slot:item.fecha_vencimiento_prod="{ item }">
                  {{ item.fecha_vencimiento_prod | formatearFecha }}
                </template>
                <template v-slot:item.caja="{ item }">
                  <template v-if="item.cal_compr_factor > 1">
                    <strong>{{ Math.trunc(item.stock_actual / item.cal_compr_factor) }}</strong><br>
                    <span class="caption">[x{{ item.cal_compr_factor }}]</span>
                  </template>
                </template>
                <template v-slot:item.stock_actual="{ item }">
                  <strong>{{ item.stock_actual }}</strong>
                </template>
                <template v-slot:item.precio_compra="{ item }">
                  <div class="text-right">
                    <span>{{ item.idtipo_servicio == 3 ? "S/" : "$" }}{{ item.idtipo_servicio == 3 ?
                      (item.valor_unit_compra + item.valor_unit_compra * item.igv).toFixed(2) :
                      (item.valor_unit_compra + item.valor_unit_compra * item.igv).toFixed(2) | formatearNumero
                      }}</span>
                  </div>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length">
                    <template v-if="esAdministrador">
                      <strong>Precio Compra:</strong>
                      <span>{{ item.idtipo_servicio == 3 ? "S/" : "$" }}
                        {{ item.precio_unit_compra_factor.toFixed(2) | formatearNumero }}</span>
                      <template v-if="item.idtipo_servicio == 4">
                        <strong class="ml-2" v-if="esAdministrador">Precio Compra TC:</strong>
                        <span v-if="esAdministrador">S/ {{ (item.idtipo_servicio == 3 ?
                          item.precio_unit_compra_factor : item.precio_unit_compra_factor * tc).toFixed(2) |
                          formatearNumero }}</span>
                      </template>
                      <br />
                    </template>
                    <strong>PROVEEDOR:</strong>
                    {{ item.proveedor.substring(0, 30) }}
                    <strong class="ml-4">FEC.INGRESO:</strong>
                    {{ item.fecha | formatearFecha }}
                    <strong>Pedido Nº:</strong>
                    {{ item.idpedido }}
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Producto-->

        <!-- Listado Ajuste de Stock -->
        <v-data-table :loading="loading" :headers="cabeceraListarPedido" :items="pedidos" :items-per-page="15"
          item-key="idmovimiento" show-expand dense class="elevation-3" v-if="verNuevoPedido == 0">

          <template v-slot:top>
            <!-- Titulo y busqueda pedido persona -->
            <v-toolbar flat v-if="verNuevoPedido == 0">
              <v-toolbar-title class="hidden-sm-and-down">AJUSTES STOCK</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Formulario busqueda de pedido -->
              <v-text-field dense class="mr-1" v-model.trim="buscar" label="Búsqueda" @keyup="listar" single-line
                hide-details></v-text-field>
              <v-spacer />
              <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
                offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listar()" class="mr-1" style="max-width: 220px;"></v-text-field>
                </template>
                <v-date-picker v-model="dates"
                  @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listar()"
                  range></v-date-picker>
              </v-menu>
              <v-spacer />
              <!-- Fin Formulario busqueda de pedido -->

              <v-btn @click="mostrarNuevoPedido" color="primary" class="hidden-sm-and-down" small outlined>Nuevo</v-btn>
              <v-btn @click="mostrarNuevoPedido" color="primary" class="hidden-md-and-up" fab x-small outlined>
                <v-icon>add</v-icon>
              </v-btn>

            </v-toolbar>
            <!-- Fin Titulo y busqueda pedido persona -->

            <!-- Modal anular ajuste -->
            <v-dialog v-model="adModal" max-width="350">
              <v-card>
                <v-card-title class="headline">Eliminar: {{ serie_comprobante }}-{{ num_comprobante }}</v-card-title>
                <v-card-actions class="py-0 pb-4 justify-center">
                  <v-btn color="info" @click="anularCerrar" small outlined>Cancelar</v-btn>
                  <v-btn color="error" @click="anular" small outlined>Eliminar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- Fin Modal anular ajuste -->
          </template>
          <!-- Opciones editar, eliminar pedido persona y realizar venta -->

          <template v-slot:item.numero="{ item }">{{ item.serie_comprobante + "-" + item.num_comprobante }}</template>

          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>

          <template v-slot:item.estado="{ item }">
            <template v-if="item.estado != 'Anulado'">
              <span>{{ item.estado }}</span>
            </template>

            <template v-else>
              <span class="error--text">{{ item.estado }}</span>
            </template>
          </template>

          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="print" @click="mostrarComprobante(item)">print</v-icon>
            </v-btn>
            <template v-if="item.estado != 'Anulado' && item.idusuario == usuario.idusuario">
              <v-btn small icon>
                <v-icon color="error" @click="anularMostrar(item)">delete_forever</v-icon>
              </v-btn>
            </template>
          </template>
          <!-- Fin Opciones editar, eliminar pedido persona y realizar venta -->

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Registrado por:</strong>
              {{ item.usuario }}
            </td>
          </template>
        </v-data-table>
        <!--Fin Listado Ajuste de Stock -->

        <!-- Ventana crear y modificar ajuste -->
        <v-card v-if="verNuevoPedido == 1" class="elevation-3">
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>Nuevo Ajuste Stock</v-toolbar-title>
            <v-spacer />
            <v-text-field dense solo-inverted @keyup.enter="buscarCodigoBarra()" v-model="codigo_barra"
              label="Código Barra" maxlength="30" append-icon="qr_code_scanner" style="max-width: 220px;" hide-details>
            </v-text-field>
          </v-toolbar>
          <v-card-text grid-list-sm v-if="verNuevoPedido">
            <v-form v-model="isValid">
              <v-row>
                <v-col cols="10" md="3" sm="3">
                  <v-autocomplete dense v-model="motivo" :items="tipo_motivos" label="Motivo"
                    :rules="[v => !!v || 'El motivo es requerido']" hide-details></v-autocomplete>
                </v-col>
                <v-col cols="2" md="1" sm="1">
                  <div class="text-center">
                    <v-btn @click="mostrarProductos()" x-small fab color="primary" outlined>
                      <v-icon>list</v-icon>
                    </v-btn>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-data-table :headers="cabeceraDetallesPedidos" :items="detalles" disable-pagination
                    hide-default-footer dense class="elevation-3">
                    <template v-slot:item.num="{ item }">
                      <td>{{ detalles.indexOf(item) + 1 }}</td>
                    </template>
                    <template v-slot:item.borrar="{ item }">
                      <v-icon small color="error" @click="eliminarItemDetallePedido(detalles, item)">delete_forever
                      </v-icon>
                    </template>
                    <template v-slot:item.unidad="{ item, index }">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip small color="primary" @click="verPresentaciones(item.idproducto)" v-bind="attrs"
                            v-on="on"><strong>{{ item.unidad }}</strong></v-chip>
                        </template>
                        <v-list>
                          <v-list-item v-for="(item, i) in detallePresentaciones" :key="i" link>
                            <v-list-item-title @click="selectUnidad(item.idunidad_medida, index)">{{
                              item.unidad
                            }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                    <template v-slot:item.fecha_vencimiento_prod="{ item }">
                      {{ item.fecha_vencimiento_prod | formatearFecha }}
                    </template>
                    <template v-slot:item.stock_actual="{ item }">
                      <span class="font-weight-bold">{{ item.stock_actual }}</span><template
                        v-if="item.cal_compr_factor > 1">({{ Math.trunc(item.stock_actual / item.cal_compr_factor)
                        }})</template>
                    </template>
                    <template v-slot:item.xcantidad="{ item }">
                      <v-text-field dense type="text" @keyup.up="item.xcantidad++" @keyup.down="item.xcantidad--"
                        v-model="item.xcantidad"
                        :rules="[v => (!!v && v > 0) || 'Requerido', item.stock_actual >= item.xcantidad || 'No valido']"
                        maxlength="6" @keypress="common.isNum($event)" onClick="this.select()" style="max-width: 50px;"
                        class="center-input" hide-details>
                      </v-text-field>
                      <v-text-field dense type="text" @keyup.up="item.cantidad++" @keyup.down="item.cantidad--"
                        v-model="item.cantidad = item.xcantidad * item.factor"
                        :rules="[v => (!!v && v > 0) || 'Requerido', item.stock_actual >= item.cantidad || 'Cantidad no puede ser > a stock']"
                        style="max-width: 50px;" v-show="false" maxlength="20" hide-details></v-text-field>
                    </template>
                  </v-data-table>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="error" @click="ocultarNuevo()" small outlined>
              <v-icon left>close</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarDevolucion()" :loading="desabilitar"
              :disabled="desabilitar || !isValid" small outlined>
              <v-icon left>check</v-icon>Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Fin Ventana crear y modificar ajuste -->

        <!-- Modal impresión -->
        <v-dialog v-model="comprobanteModal" max-width="800px" scrollable>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobante()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf :src="stringPdf"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal impresión -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      pedidos: [],
      detalles: [], // Detalles de pedido
      detalle_ajustes: [], // Detalles de pedido para generar venta de persona

      cabeceraListarPedido: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "CdPd", value: "idpedido", sortable: false },
        { text: "MOTIVO", value: "motivo", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 90 },
      ],

      cabeceraDetallesPedidos: [
        { text: "", value: "borrar", sortable: false },
        { text: "#", value: "num", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "U/M", value: "unidad", sortable: false },
        { text: "MARCA", value: "marca", sortable: false },
        { text: "LOTE", value: "lote", sortable: false },
        { text: "FEC. VENC.", value: "fecha_vencimiento_prod", sortable: false },
        { text: "STOCK", value: "stock_actual", sortable: false, align: "center" },
        { text: "CANT.", value: "xcantidad", sortable: false, align: "center" },
      ],

      cabeceraBusquedaProductos: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", align: "center", sortable: false },
        { text: "IMG", value: "imagen", align: "center", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "U/M", value: "unidad", sortable: false },
        { text: "MARCA", value: "marca", sortable: false },
        { text: "LOTE", value: "lote", sortable: false },
        { text: "FEC. VENC.", value: "fecha_vencimiento_prod", sortable: false, divider: true },
        { text: "CAJA", value: "caja", align: "center", sortable: false, divider: true },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false },
        { text: "P.COMPRA", value: "precio_compra", sortable: false },
      ],
      loading: false,

      buscar: "",
      codigo_barra: "",
      productos: [],

      brDescripcion: "",

      idpedido: "",
      idpersona: "",

      igv: 0,

      selectedZoom: false,
      imagen_zoom: "",

      verNuevoPedido: 0,
      verProductos: 0,

      sucursal: "",
      persona: "",
      fecha: "",
      num_documento: "",

      comprobante: "",
      serie_comprobante: "",
      num_comprobante: "",

      comprobanteModal: 0,

      desabilitar: false,
      isValid: true,

      motivo: "",
      tipo_motivos: [
        "Dañado",
        "Error de fábrica",
        "Pérdida",
        "Recuento",
        "Vencido",
      ],

      adModal: 0,
      adId: "",

      idtipo_documento: "", // dni=1 ó ruc=6

      stringPdf: "",
      blobPdf: "",

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,

      detallePresentaciones: [],

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),
    ...mapGetters(["esAdministrador", "esCaja"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobante();
    },
    verProductos(val) {
      val || this.ocultarProductos();
    },
    brDescripcion(newVal) {
      if (newVal === null) {
        this.brDescripcion = "";
      }
    },
  },

  created() {
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
    this.listar();
  },

  methods: {
    ...mapActions(["snackBar"]),

    //#region LISTAR PEDIDOS
    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Movimientos/ListarAjusteStockFiltro", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          buscar: me.buscar == "" ? "-" : me.buscar,
          fechaInicio: me.dates[0],
          fechaFin: me.dates[1] == undefined ? me.dates[0] + " 23:59:59" : me.dates[1] + " 23:59:59",
        },
      })
        .then(function (response) {
          me.pedidos = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion

    //#region PEDIDO GUARDAR, EDITAR, MOSTRAR VENTANA Y ELIMINAR DETALLE
    guardarDevolucion() {
      let me = this;
      // Validamos detalle
      if (me.detalles.length <= 0) {
        return me.snackBar({ cl: "warning", msg: "Ingrese al menos un producto al detalle." });
      }

      me.desabilitar = true;
      me.detalle_ajustes = [];
      me.convertirDetalleAjuste();

      axios.post("api/Movimientos/CrearAjusteStock", {
        idsucursal: parseInt(me.usuario.idsucursal),
        idusuario: parseInt(me.usuario.idusuario),
        idpersona: 1,
        motivo: me.motivo,
        detalle_ajustes: me.detalle_ajustes,
      })
        .then(function (response) {
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta });
          me.ocultarNuevo();
          me.listar();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
          me.listar();
        });
    },

    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];

      axios.get("api/Pedidos/ListarDetallesPedidos/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear
          detallesArray.map(function (x) {
            me.detalles.push({
              idproducto: x.idproducto,
              codigo_producto: x.codigo_producto, // FE
              unidad: x.unidad,
              marca: x.marca,
              lote: x.lote,
              fecha_vencimiento_prod: x.fecha_vencimiento_prod,
              descripcion: x.descripcion,
              descripcion_adicional: x.descripcion_adicional,
              stock_actual: x.stock_actual,
              iddetalle_compra: x.iddetalle_compra,
              idunidad_medida: x.idunidad_medida,
              xcantidad: x.xcantidad,
              ref_idpedido: x.ref_idpedido,
            });
          });
          me.comprobanteModal == 1 ? me.crearPDF() : ""; // Solo para pedido pdf
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    convertirDetalleAjuste() {
      let me = this;
      for (var i = 0; i < me.detalles.length; i++) {
        me.detalle_ajustes.push({
          idproducto: me.detalles[i].idproducto,
          iddetalle_compra: me.detalles[i].iddetalle_compra,
          idunidad_medida: me.detalles[i].idunidad_medida,
          factor: parseInt(me.detalles[i].factor),
          xcantidad: parseInt(me.detalles[i].xcantidad),
          cantidad: parseInt(me.detalles[i].cantidad),
          descripcion_adicional: me.detalles[i].descripcion_adicional,
        });
      }
    },

    mostrarNuevoPedido() {
      this.verNuevoPedido = 1;
    },

    ocultarNuevo() {
      this.verNuevoPedido = 0;
      this.desabilitar = false;
      this.limpiar();
    },

    eliminarItemDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    /* Modal Anular */
    anular() {
      let me = this;

      axios.delete("api/Movimientos/EliminarAjusteStock/" + me.adId)
        .then(function (response) {
          me.snackBar({ cl: "success", msg: response.data }); // Respuesta de sunat
          me.adModal = 0;
          me.adId = "";

          me.listar();
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "error", msg: error.response.data });
          me.adModal = 0;
          me.adId = "";
        });
    },

    anularMostrar(item) {
      this.adModal = 1;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.adId = item.idmovimiento;
    },

    anularCerrar() {
      this.adModal = 0;
    },
    /* Fin Modal Anular*/
    //#endregion

    //#region IMPRESIÓN
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = "AjusteStock-" + this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace("data:application/pdf;filename=generated.pdf;base64,", ""),
        type: "pdf",
        base64: true,
      });
    },

    mostrarComprobante(item) {
      this.limpiar();
      this.idpedido = item.idpedido;
      this.sucursal = item.sucursal;
      this.persona = item.persona;
      this.num_documento = item.num_documento;

      this.motivo = item.motivo;
      this.comprobante = item.comprobante;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.fecha = item.fecha;
      this.idpedido = item.idpedido;

      this.comprobanteModal = 1;
      this.listarDetallePedidos(item.idpedido);
    },

    verImagen(item) {
      this.imagen_zoom = item.imagen;
      this.selectedZoom = true;
    },
    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    //#endregion IMPRESION

    //#region PRODUCTO
    buscarCodigoBarra() {
      let me = this;

      axios.get("api/Pedidos/BuscarCodigoBarraVenta/" + parseInt(me.usuario.idsucursal) + "/" + me.codigo_barra)
        .then(function (response) {
          //console.log(response);
          me.agregarDetalle(response.data);
          me.snackBar({ cl: "info", msg: "Se agrego " + response.data.descripcion + " al detalle." });
          me.codigo_barra = "";
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "warning", msg: "No hay registro de compra para el producto." });
          me.codigo_barra = "";
        });
    },

    listarProductoBuscar() {
      let me = this;

      axios.get("api/Pedidos/ListarProductoVenta", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          descripcion: me.brDescripcion == "" ? "-" : me.brDescripcion,
        },
      })
        .then(function (response) {
          me.productos = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    dblClickRepuesto(event, { item }) {
      this.agregarDetalle(item);
    },

    agregarDetalle(data = []) {
      let me = this;
      // Buscar el índice del detalle existente con el mismo iddetalle_compra
      const indiceExistente = me.detalles.findIndex(detalle => detalle.iddetalle_compra === data["iddetalle_compra"]);

      if (indiceExistente !== -1) {
        // Si el detalle ya existe, sumar la cantidad
        me.detalles[indiceExistente].xcantidad += 1;
      } else {
        me.detalles.push({
          idproducto: data["idproducto"],
          idunidad_medida: data["idunidad_medida"],
          unidad: data["unidad"],
          factor: data["factor"],
          xcantidad: 1,
          iddetalle_compra: data["iddetalle_compra"],
          codigo_producto: data["codigo_producto"], // FE
          descripcion: data["descripcion"],
          descripcion_adicional: data["descripcion_adicional"],
          marca: data["marca"],
          lote: data["lote"],
          fecha_vencimiento_prod: data["fecha_vencimiento_prod"],
          cal_compr_factor: data["cal_compr_factor"],
          stock_actual: data["stock_actual"],
          cantidad: 1,
        });
      }
    },

    mostrarProductos() {
      this.verProductos = 1;
    },

    ocultarProductos() {
      this.verProductos = 0;
      this.productos = [];
      this.brDescripcion = "";
      this.imagen_zoom = "";
    },

    verPresentaciones(id) {
      let me = this;
      var detallesArray = [];
      me.detallePresentaciones = [];

      axios.get("api/Productos/SelectPresentaciones/" + id)
        .then(function (response) {
          detallesArray = response.data;
          detallesArray.map(function (x) {
            me.detallePresentaciones.push({
              idproducto: x.idproducto,
              idunidad_medida: x.idunidad_medida,
              unidad: x.unidad,
              factor: x.factor,
              precio_unit: x.precio_unit
            });
          });
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    selectUnidad(idunidad, index) {
      const selectId = this.detallePresentaciones.find((elemento) => elemento.idunidad_medida === idunidad);

      if (selectId) {
        // Actualizamos valores de unidad
        this.detalles[index].idunidad_medida = selectId.idunidad_medida;
        this.detalles[index].unidad = selectId.unidad;
        this.detalles[index].factor = selectId.factor;
        this.detalles[index].precio_unit_venta = selectId.precio_unit;
        this.snackBar({ cl: "info", msg: "Se cambio la Unidad de medida: " + selectId.unidad + " X " + selectId.factor });
      }
    },
    //#endregion PRODUCTO

    limpiar() {
      this.igv = parseFloat(this.datos.igv); // El igv formateado

      this.idpedido = "";
      this.idpersona = "";
      this.num_documento = "";
      this.tipo_pedido = "";

      this.motivo = "";
      this.comprobante = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";

      this.detalles = [];
      this.detalle_ajustes = [];

      this.codigo_barra = "";
      this.productos = [];
      this.brDescripcion = "";

      this.idtipo_documento = ""; // dni=1 ó ruc=6
      this.stringPdf = "";
      this.blobPdf = "";
    },

    crearPDF() {
      var imgData = "data:image/png;base64," + this.logo;
      var ancho = 45;
      var alto = 28.45;
      var doc = new jsPDF("p", "mm", [210, 297]);
      var centro = doc.internal.pageSize.width / 2;

      var columns = [
        { header: "ITEM", dataKey: "item" },
        { header: "CANT.", dataKey: "xcantidad" },
        { header: "U/M", dataKey: "unidad" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion" },
        { header: "MARCA", dataKey: "marca" },
        { header: "LOTE", dataKey: "lote" },
        { header: "FEC. VENC.", dataKey: "fecha_vencimiento_prod" },
        { header: "RPC", dataKey: "ref_idpedido" },
      ];
      var body = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          item: i + 1,
          xcantidad: this.detalles[i].xcantidad,
          unidad: this.detalles[i].unidad,
          descripcion: this.detalles[i].descripcion,
          marca: this.detalles[i].marca,
          lote: this.detalles[i].lote,
          fecha_vencimiento_prod: this.detalles[i].fecha_vencimiento_prod ? moment(this.detalles[i].fecha_vencimiento_prod).format("DD/MM/YYYY") : "",
          ref_idpedido: this.detalles[i].ref_idpedido,
        });
      }

      //console.log(body);
      // Agregar Encabezado: titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 15, 15, ancho, alto);
          // Datos empresa
          doc.autoTable({
            startY: 17,
            theme: "plain",
            margin: { left: 40, right: 40 },
            bodyStyles: {
              cellPadding: 0.5,
              font: "helvetica",
              fontStyle: "normal",
              fontSize: 6,
              textColor: 0,
              halign: "center",
            },
            body: [
            [{ content: this.datos.empresa, styles: { fontSize: 8,fontStyle: "bold" } }],
              [{ content: this.datos.emp_comercial, styles: { fontSize: 7,fontStyle: "bold" } }],
              (this.datos.eslogan ? [{ content: this.datos.eslogan }] : undefined),
              [{ content: "Email: " + this.datos.mailsucursal + " Tel: " + this.datos.telsucursal }],
              [{ content: this.datos.dirsucursal }],
              [{ content: this.datos.dptosucursal + " - " + this.datos.provsucursal + " - " + this.datos.dttosucursal }]
            ].filter(item => item !== undefined),
          });

          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text("AJUSTE STOCK", centro + 70, 27, "center");
          doc.text(this.serie_comprobante + "-" + this.num_comprobante, centro + 70, 32, "center");

          // DESTINATARIO
          doc.autoTable({
            startY: 44,
            theme: "plain",
            tableLineColor: [82, 86, 89],
            tableLineWidth: 0.2,
            margin: { left: 15, right: 15 },
            bodyStyles: {
              cellPadding: 1,
              font: "helvetica",
              fontStyle: "normal",
              fontSize: 6,
              textColor: 0,
            },
            columnStyles: {
              0: { cellWidth: 17 },
              1: { cellWidth: 106 },
              2: { cellWidth: 23 },
              3: { cellWidth: 34 }
            },
            body: [
              [{ content: "Doc. Nº", styles: { fontStyle: "bold" } }, { content: ": " + this.num_documento }, { content: "Fecha Emisión", styles: { fontStyle: "bold" } }, { content: ": " + moment(this.fecha).format("DD/MM/YYYY") }],
              [{ content: "Cliente", styles: { fontStyle: "bold" } }, { content: ": " + this.persona }, { content: "Motivo", styles: { fontStyle: "bold" } }, { content: ": " + this.motivo }],
            ],
          });
        }
      };
      // Agregar pie de pagina: numeración
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(6);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("Página " + String(i) + " de " + String(pageCount), centro, 290, "center");
        }
      };

      // DETALLE DE VENTA
      doc.autoTable({
        theme: "grid",
        columns,
        body,
        margin: { left: 15, right: 15, top: 56 },
        //styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "bold",
          fontSize: 7,
          halign: "center",
          fillColor: [82, 86, 89],
          textColor: 255,
          lineColor: [82, 86, 89],
          lineWidth: 0.2,
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 7,
          textColor: 0,
          lineColor: [82, 86, 89],
          lineWidth: 0.2,
        },
        footStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fillColor: 255,
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          item: { cellWidth: 7, halign: "center" },
          xcantidad: { cellWidth: 10, halign: "center" },
          unidad: { cellWidth: 10, halign: "center" },
          descripcion: { cellWidth: "auto" },
          marca: { cellWidth: 20, halign: "center" },
          lote: { cellWidth: 20, halign: "center" },
          fecha_vencimiento_prod: { cellWidth: 15, halign: "center" },
          ref_idpedido: { cellWidth: 10, halign: "right" },
        },
      });

      addHeaders(doc);
      addFooters(doc);
      
      doc.setProperties({
        title: this.serie_comprobante + "-" + this.num_comprobante + ".pdf",
      });

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
    },

  },

  mounted() { },
};
</script>

<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>