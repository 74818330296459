<template>
  <v-container fluid style="max-width: 1920px;">
    <v-layout align-start>
      <v-flex>
        <!-- Modal persona -->
        <v-dialog v-model="verPersona" max-width="700px" persistent>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>Nuevo Cliente</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="guardarPersona" :loading="desabilitarPer" :disabled="desabilitarPer || !isValidPer">
                <v-icon>save</v-icon>
              </v-btn>
              <v-btn icon @click="closePersona">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="isValidPer">
                <v-row class="mt-2">
                  <v-col cols="6" sm="3">
                    <v-select dense v-model="idtipo_documento_pers" :items="tipo_documentos_cli" label="Tipo Documento"
                      :rules="[v => !!v || 'Tipo documento requerido']" @change="num_documento = ''" hide-details>
                    </v-select>
                  </v-col>
                  <v-col cols="10" sm="5">
                    <v-text-field dense v-model.trim="num_documento" label="Numero Documento"
                      :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento_pers == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                      :maxlength="idtipo_documento_pers == 1 ? 8 : 11"
                      :disabled="idtipo_documento_pers == 1 ? false : idtipo_documento_pers == 6 ? false : true"
                      @keypress="common.isNum($event)" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="1">
                    <div class="text-center">
                      <v-btn x-small fab color="primary" class="hide-detailswhite--text" :loading="desabilitarBuscar"
                        :disabled="(idtipo_documento_pers == 1 && num_documento.length == 8 ? false : idtipo_documento_pers == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                        @click="extraerDocumento(num_documento)">
                        <v-icon>search</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model.trim="nombre"
                      :label="idtipo_documento_pers == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                      :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 200) || 'Nombre debe tener entre 3 a 200 caracteres']"
                      maxlength="100" prepend-inner-icon="person" v-uppercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo" v-model="idubigeo"
                      :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                      prepend-inner-icon="pin_drop" clearable class="mr-1"></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model.trim="direccion" label="Direccion"
                      :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres']"
                      maxlength="200" prepend-inner-icon="place" v-uppercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field dense v-model.trim="email" label="Email"
                      :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                      maxlength="60" prepend-inner-icon="mail" v-lowercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field dense v-model.trim="telefono" label="Telefono"
                      :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                      maxlength="30" prepend-inner-icon="phone" v-uppercase hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal persona -->

        <!-- Producto -->
        <v-dialog v-model="verProductos" max-width="1200px">
          <v-card>
            <v-toolbar dark dense class="primary mb-1" flat>
              <v-toolbar-title>Productos</v-toolbar-title>
              <v-row>
                <v-spacer />
                <v-col cols="7" md="8" sm="8">
                  <v-text-field dense solo-inverted v-model.trim="brDescripcion" label="Buscar Productos"
                    prepend-inner-icon="search" clearable hide-details @keyup="listarProductoBuscar">
                  </v-text-field>
                </v-col>
                <v-spacer />
              </v-row>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="dpurple" @click="mostrarEstablecimientos()" v-on="on">
                    <v-icon>category</v-icon>
                  </v-btn>
                </template>
                <span>Buscar Stock Establecimientos</span>
              </v-tooltip>

              <v-btn icon @click="ocultarProductos()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <!-- Mostrar imagen de producto -->
              <v-dialog v-model="selectedZoom" max-width="350px">
                <v-avatar tile size="350">
                  <img :src="'data:image/png;base64,' + imagen_zoom" />
                </v-avatar>
              </v-dialog>
              <!-- Fin Mostrar imagen de producto -->
              <v-form v-model="isValidP">
                <v-data-table :headers="cabeceraBusquedaProductos" :items="productos" :items-per-page="15"
                  item-key="iddetalle_compra" show-expand class="elevation-3" @dblclick:row="dblClickRepuesto" dense>
                  <template v-slot:item.seleccionar="{ item }">
                    <v-icon @click="agregarDetalle(item)" color="primary" :disabled="!isValidP">control_point</v-icon>
                  </template>
                  <template v-slot:item.imagen="{ item }">
                    <template>
                      <v-avatar tile size="27">
                        <img :src="'data:image/png;base64,' + item.imagen" @click="verImagen(item)" />
                      </v-avatar>
                    </template>
                  </template>
                  <template v-slot:item.marca="{ item }">
                    <strong class="error--text">{{ item.marca }}</strong>
                  </template>
                  <template v-slot:item.fecha_vencimiento_prod="{ item }">
                    {{ item.fecha_vencimiento_prod | formatearFecha }}
                  </template>
                  <template v-slot:item.caja="{ item }">
                    <template v-if="item.cal_compr_factor > 1">
                      <strong>{{ Math.trunc(item.stock_actual / item.cal_compr_factor) }}</strong><br>
                      <span class="caption">[x{{ item.cal_compr_factor }}]</span>
                    </template>
                  </template>
                  <template v-slot:item.stock_actual="{ item }">
                    <strong>{{ item.stock_actual }}</strong>
                  </template>
                  <template v-slot:item.precio_unit_venta="{ item }">
                    <div class="text-right">
                      <span>{{ item.precio_unit_venta | formatearNumero }}</span>
                    </div>
                  </template>
                  <template v-slot:item.precios_ventas="{ item }">
                    <div class="text-right">
                      <strong>{{ item.precio_unit_venta | formatearNumero }}</strong>
                    </div>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-row class="mt-0 mb-0">
                        <v-col cols="6" md="9" sm="4">
                          <v-text-field dense type="text" label="Descripción Adicional"
                            :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 10 && v.length <= 240) || 'Nombre debe tener entre 10 a 240 caracteres', Rules.unespacio]"
                            v-model.trim="item.descripcion_adicional" maxlength="200" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" md="3" sm="4">
                          <v-select dense v-model="item.idtipo_afectacion" :items="tipo_afectacion"
                            label="Tipo Afectación" hide-details></v-select>
                        </v-col>
                      </v-row>
                      <template v-if="esAdministrador">
                        <strong>Precio Compra:</strong>
                        <span>{{ item.idtipo_servicio == 3 ? "S/" : "$" }}
                          {{ item.precio_unit_compra_factor.toFixed(2) | formatearNumero }}</span>
                        <template v-if="item.idtipo_servicio == 4">
                          <strong class="ml-2" v-if="esAdministrador">Precio Compra TC:</strong>
                          <span v-if="esAdministrador">S/ {{ (item.idtipo_servicio == 3 ?
                            (item.precio_unit_compra_factor) : (item.precio_unit_compra_factor) *
                            tc).toFixed(2) | formatearNumero }}</span>
                        </template>
                        <br />
                      </template>
                      <strong>PROVEEDOR:</strong>
                      {{ item.proveedor.substring(0, 30) }}
                      <strong class="ml-4">FEC.INGRESO:</strong>
                      {{ item.fecha | formatearFecha }}
                      <strong>Pedido Nº:</strong>
                      {{ item.idpedido }}
                    </td>
                  </template>
                </v-data-table>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Producto -->

        <!--Modal sucursal stock -->
        <v-dialog v-model="verEstablecimientosProductos" max-width="800px" scrollable persistent>
          <v-card>
            <v-toolbar dark dense class="primary mb-1">
              <v-autocomplete dense solo-inverted @keyup="selectProductos()"
                @change="listarStockEstablecimiento(idproducto), changeProductos(idproducto)"
                :search-input.sync="buscar_producto" :items="totalProductos" v-model="idproducto"
                label="Buscar Producto" :rules="[v => !!v || 'El producto es requerido']" prepend-inner-icon="category"
                required hide-details clearable>
              </v-autocomplete>
              <v-btn icon @click="ocultarEstablecimientos()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-chip class="ma-1" label>
                Precio Unitario Venta: S/ {{ precioVenta | formatearNumero }}
              </v-chip>
              <v-data-table :headers="cabeceraStockEstablecimiento" :items="stockEstablecimiento"
                class="elevation-3 ma-1" dense hide-default-footer>

                <template v-slot:item.caja="{ item }">
                  <template v-if="item.factor > 1">
                    <strong>{{ Math.trunc(item.stockactual / item.factor) }}</strong><br>
                    <span class="caption">[x{{ item.factor }}]</span>
                  </template>
                </template>

                <template v-slot:item.stockactual="{ item }">
                  <template v-if="item.stockactual == stockMinimo">
                    <v-chip color="warning" small dark>{{ item.stockactual }}</v-chip>
                  </template>
                  <template v-else-if="item.stockactual > stockMinimo">
                    <v-chip color="success" small dark>{{ item.stockactual }}</v-chip>
                  </template>
                  <template v-else>
                    <v-chip color="error" small dark>{{ item.stockactual }}</v-chip>
                  </template>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal sucursal stock -->

        <!-- Listado Pedido Cliente -->
        <v-data-table :loading="loading" :headers="cabeceraListarPedido" :items="pedidos" :items-per-page="15" dense
          class="elevation-3" v-if="verNuevoPedido == 0">

          <template v-slot:top>
            <!-- Titulo y busqueda pedido cliente -->
            <v-toolbar flat v-if="verNuevoPedido == 0">
              <v-toolbar-title class="hidden-sm-and-down">PEDIDO y PROFORMA CLIENTES</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Formulario busqueda de pedido -->
              <v-text-field dense class="mr-1" v-model.trim="buscar_pedido" label="Búsqueda" @keyup="listar" single-line
                hide-details></v-text-field>
              <v-spacer />
              <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listar()" class="mr-1" style="max-width: 220px;"></v-text-field>
                </template>
                <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                  @change="listar()" range></v-date-picker>
              </v-menu>
              <v-spacer />
              <!-- Fin Formulario busqueda de pedido -->
              <v-btn @click="mostrarNuevoPedido" color="primary" class="hidden-sm-and-down" small outlined>Nuevo</v-btn>
              <v-btn @click="mostrarNuevoPedido" color="primary" class="hidden-md-and-up" fab x-small outlined>
                <v-icon>add</v-icon>
              </v-btn>
            </v-toolbar>
            <!-- Fin Titulo y busqueda pedido cliente -->
          </template>
          <!-- Opciones editar, eliminar pedido cliente y realizar venta -->

          <template v-slot:item.usuario="{ item }">
            <span>{{ (item.usuario).substr(0, 12) }}</span>
          </template>

          <template v-slot:item.cliente="{ item }">
            <span>{{ (item.cliente).substr(0, 20) }}</span>
          </template>

          <template v-slot:item.moneda="{ item }">
            <div class="text-right">
              <strong>{{ item.moneda == "S" ? "S/" : "$" }}</strong>
            </div>
          </template>

          <template v-slot:item.importe_total="{ item }">
            <div class="text-right">
              <strong>{{ item.importe_total | formatearNumero }}</strong>
            </div>
          </template>

          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>

          <template v-slot:item.estado="{ item }">
            <template v-if="item.estado == 'Pendiente'">
              <span class="orange--text">Pendiente</span>
            </template>

            <template v-else>
              <span class="success--text">{{ item.estado }}</span>
            </template>
          </template>

          <template v-slot:item.gratuito="{ item }">
            <div class="text-right">
              <span>{{ item.gratuito | formatearNumero }}</span>
            </div>
          </template>

          <template v-slot:item.opciones="{ item }">
            <template v-if="item.tipo_pedido == 'Pedido' && esAdministrador || esCaja">
              <v-btn small icon>
                <v-icon color="success" @click="irVentas(item)">shopping_cart</v-icon>
              </v-btn>
            </template>
            <v-btn small icon>
              <v-icon color="print" @click="mostrarComprobante(item)">print</v-icon>
            </v-btn>
            <v-btn small icon>
              <v-icon color="primary" @click="verDetallesPedidos(item)">edit</v-icon>
            </v-btn>

            <template v-if="item.estado == 'Pendiente'">
              <v-btn small icon>
                <v-icon color="error" @click="deleteItemPedido(item)">delete_forever</v-icon>
              </v-btn>
            </template>
          </template>
          <!-- Fin Opciones editar, eliminar pedido cliente y realizar venta -->
        </v-data-table>
        <!--Fin Listado Pedido Cliente -->

        <!-- Ventana crear y modificar pedido  -->
        <v-card v-if="verNuevoPedido == 1" class="elevation-3">
          <v-toolbar dark dense class="primary" flat>
            <v-toolbar-title>{{ formTitle }} {{ tipo_pedido }} Cliente</v-toolbar-title>
            <v-spacer />
            <v-text-field dense solo-inverted @keyup.enter="buscarCodigoBarra()" v-model="codigo_barra"
              label="Código Barra" maxlength="30" append-icon="qr_code_scanner" style="max-width: 220px;" hide-details>
            </v-text-field>
          </v-toolbar>
          <v-card-text grid-list-sm v-if="verNuevoPedido">
            <v-form v-model="isValid">
              <v-row>
                <v-col cols="12" md="2" sm="2">
                  <v-select dense v-model="tipo_pedido" :items="tipos_pedido" label="Tipo"
                    :rules="[v => !!v || 'Tipo requerido']" hide-details></v-select>
                </v-col>
                <v-col cols="6" md="2" sm="2">
                  <v-select dense v-model="idtipo_servicio" :items="tipo_servicios" label="Moneda"
                    :rules="[v => !!v || 'Servicio requerido']" @change="detalles = []" hide-details></v-select>
                </v-col>
                <v-col cols="10" md="7" sm="5">
                  <v-autocomplete dense @keyup="selectCliente()" :search-input.sync="buscar_cliente" v-model="idpersona"
                    :items="clientes" label="Cliente" :rules="[v => !!v || 'El cliente es requerido']" clearable
                    append-outer-icon="person_add" @click:append-outer="mostrarPersona" hide-details></v-autocomplete>
                </v-col>
                <v-col cols="2" md="1" sm="2">
                  <div class="text-center">
                    <v-btn @click="mostrarProductos()" x-small fab color="primary" outlined>
                      <v-icon>list</v-icon>
                    </v-btn>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-data-table :loading="loadingDetalles" :headers="cabeceraDetallesPedidos" :items="detalles"
                    disable-pagination hide-default-footer dense class="elevation-3">

                    <template v-slot:item.num="{ item }">
                      <td>{{ detalles.indexOf(item) + 1 }}</td>
                    </template>
                    <template v-slot:item.borrar="{ item }">
                      <v-icon small color="error" @click="eliminarItemDetallePedido(detalles, item)">delete_forever
                      </v-icon>
                    </template>

                    <template v-slot:item.unidad="{ item, index }">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip small color="primary" @click="verPresentaciones(item.idproducto)" v-bind="attrs"
                            v-on="on"><strong>{{ item.unidad }}</strong></v-chip>
                        </template>
                        <v-list>
                          <v-list-item v-for="(item, i) in detallePresentaciones" :key="i" link>
                            <v-list-item-title @click="selectUnidad(item.idunidad_medida, index)">{{
                              item.unidad
                              }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>

                    <template v-slot:item.fecha_vencimiento_prod="{ item }">
                      {{ item.fecha_vencimiento_prod | formatearFecha }}
                    </template>
                    <template v-slot:item.stock_actual="{ item }">
                      <span class="font-weight-bold">{{ item.stock_actual }}</span><template
                        v-if="item.cal_compr_factor > 1">({{ Math.trunc(item.stock_actual / item.cal_compr_factor)
                        }})</template>
                    </template>

                    <template v-slot:item.xcantidad="{ item }">
                      <v-text-field dense type="text" @keyup.up="item.xcantidad++" @keyup.down="item.xcantidad--"
                        v-model="item.xcantidad"
                        :rules="[v => (!!v && v > 0) || 'Requerido', item.stock_actual >= item.xcantidad || 'No valido']"
                        maxlength="6" @keypress="common.isNum($event)" onClick="this.select()" style="max-width: 50px;"
                        class="center-input" hide-details>
                      </v-text-field>
                      <v-text-field dense type="text" @keyup.up="item.cantidad++" @keyup.down="item.cantidad--"
                        v-model="item.cantidad = item.xcantidad * item.factor"
                        :rules="[v => (!!v && v > 0) || 'Requerido', item.stock_actual >= item.cantidad || 'Cantidad no puede ser > a stock']"
                        style="max-width: 50px;" v-show="false" maxlength="20" hide-details></v-text-field>
                    </template>
                    <template v-slot:item.precio_unit_venta="{ item }">
                      <v-text-field dense type="text" v-model="item.precio_unit_venta"
                        :rules="[v => !!v && v > (item.precio_unit_compra_factor * item.factor) || 'Requerido']"
                        @keypress="common.numDec($event, item.precio_unit_venta, 4)" onClick="this.select()"
                        maxlength="9" hide-details></v-text-field>
                    </template>
                    <!-- <template v-slot:item.descto_item="{ item }">
                    <v-text-field
                      dense
                      type="text"
                      v-model="item.descto_item"
                      maxlength="6"
                      hide-details
                      class="right-input"
                      @keypress="common.numDec($event, item.descto_item, 4)" onClick="this.select()"
                    ></v-text-field>
                    </template>-->

                    <template v-slot:item.subtotal="{ item }">
                      <div class="text-right">
                        <span>{{ (item.xcantidad * item.precio_unit_venta - item.descto_item) | formatearNumero
                          }}</span>
                      </div>
                    </template>
                  </v-data-table>
                  <v-container>
                    <v-row no-gutters align="end" justify="end">
                      <!-- <v-col cols="10" align="end">
                      <strong>Descuento Global (-) S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        v-model="descto_global"
                        maxlength="7"
                        @keypress="common.numDec($event, descto_global, 2)" onClick="this.select()"
                        class="right-input"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="10" align="end">
                      <strong>Descuento por Item (-) S/</strong>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        disabled
                        v-model="descto_item_total=common.roundOut(common.valDec(calcularDescuentoItem),2).toFixed(2)"
                        class="right-input"
                        hide-details
                      ></v-text-field>
                      </v-col>-->
                      <v-col cols="10" align="end">
                        <strong>Gravada {{ idtipo_servicio == 5 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="subtotal_gravado = common.roundOut(common.valDec(importe_total) / (1 + igv), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>IGV {{ idtipo_servicio == 5 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="igv_gravado = common.roundOut(common.valDec(importe_total) - common.valDec(subtotal_gravado), 2).toFixed(2)"
                          class="right-input" hide-details></v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Importe Total {{ idtipo_servicio == 5 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="importe_total = common.roundOut(common.valDec(calcularTotal) - common.valDec(descto_global), 2).toFixed(2)"
                          :rules="[v => v > 0 || 'Importe mayor a cero']" class="right-input" hide-details>
                        </v-text-field>
                      </v-col>
                      <v-col cols="10" align="end">
                        <strong>Gratuita {{ idtipo_servicio == 5 ? "S/" : "$" }}</strong>
                      </v-col>
                      <v-col cols="2">
                        <v-text-field dense disabled
                          v-model="gratuito = common.roundOut(calcularGratuitoTotal, 2).toFixed(2)" class="right-input"
                          hide-details></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="py-0 pb-4 px-4 justify-center">
            <v-btn color="error" @click="ocultarNuevo()" small outlined>
              <v-icon left>close</v-icon>Cancelar
            </v-btn>
            <v-btn color="primary" @click="guardarPedido()" :loading="desabilitar" :disabled="desabilitar || !isValid"
              small outlined>
              <v-icon left>check</v-icon>Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Fin Ventana crear y modificar pedido  -->

        <!-- Modal impresión -->
        <v-dialog v-model="comprobanteModal" max-width="800px" scrollable>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>{{ tipo_pedido }} Cliente Nº {{ idpedido }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobante()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf v-for="i in numPages" :key="i" :src="srcPdf" :page="i"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal impresión -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { mapGetters, mapState, mapActions } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      pedidos: [],

      detalles: [], // Detalles de pedido
      detalle_pedidos: [], // Detalles formateado

      cabeceraListarPedido: [
        { text: "CdPd", value: "idpedido", sortable: false },
        { text: "USUARIO", value: "usuario", sortable: false },
        { text: "CLIENTE", value: "cliente", sortable: false },
        { text: "TIPO", value: "tipo_pedido", sortable: false },
        { text: "M", value: "moneda", sortable: false },
        { text: "IMPORTE", value: "importe_total", sortable: false },
        { text: "GRATUITO", value: "gratuito", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 145 },
      ],

      cabeceraDetallesPedidos: [
        { text: "", value: "borrar", sortable: false },
        { text: "#", value: "num", sortable: false },
        //{ text: "CÓDIGO", value: "codigo_producto", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false, },
        { text: "U/M", value: "unidad", sortable: false },
        { text: "MARCA", value: "marca", sortable: false },
        { text: "LOTE", value: "lote", sortable: false },
        { text: "FEC. VENC.", value: "fecha_vencimiento_prod", sortable: false },
        { text: "STOCK", value: "stock_actual", sortable: false, align: "center" },
        { text: "CANT.", value: "xcantidad", align: "center", sortable: false },
        { text: "PRECIO", value: "precio_unit_venta", sortable: false },
        //{ text: "DSCTO", value: "descto_item", sortable: false },
        { text: "TOTAL", value: "subtotal", sortable: false },
      ],

      cabeceraBusquedaProductos: [
        { text: "...", value: "data-table-expand", align: "center" },
        { text: "+", value: "seleccionar", align: "center", sortable: false },
        { text: "IMG", value: "imagen", align: "center", sortable: false },
        { text: "DESCRIPCIÓN", value: "descripcion", sortable: false },
        { text: "U/M", value: "unidad", sortable: false },
        { text: "MARCA", value: "marca", sortable: false },
        { text: "LOTE", value: "lote", sortable: false },
        { text: "FEC. VENC.", value: "fecha_vencimiento_prod", sortable: false, divider: true },
        { text: "CAJA", value: "caja", align: "center", sortable: false, divider: true },
        { text: "STOCK", align: "center", value: "stock_actual", sortable: false, divider: true },
        { text: "PRECIO S/", value: "precios_ventas", sortable: false },
      ],

      cabeceraStockEstablecimiento: [
        { text: "CdEt", value: "idsucursal", align: "center", sortable: false },
        { text: "ESTABLECIMIENTOS", value: "establecimiento", sortable: false, divider: true },
        { text: "CAJA", align: "center", value: "caja", sortable: false },
        { text: "STOCK", value: "stockactual", align: "center", sortable: false },
      ],

      loading: false,
      loadingDetalles: false,

      buscar_pedido: "",
      buscar_cliente: "",
      buscar_ubigeo: "",
      codigo_barra: "",
      productos: [],

      brDescripcion: "",

      idpedido: "",
      idpersona: "",
      clientes: [],
      idtipo_servicio: 5, // Pedido S/
      tipo_servicios: [],

      igv: 0,
      tc: 0,
      placa: "",
      descto_global: 0,
      descto_item_total: 0,
      subtotal_gravado: 0,
      gratuito: 0,
      igv_gravado: 0,
      importe_total: 0,

      selectedZoom: false,
      imagen_zoom: "",

      verEstablecimientosProductos: 0,
      buscar_producto: "",
      idproducto: "",
      totalProductos: [],

      stockEstablecimiento: [],
      precioVenta: "",

      stockMinimo: "",

      verNuevoPedido: 0,
      verProductos: 0,

      sucursal: "",
      cliente: "",
      fecha: "",
      num_documento: "",
      direccion: "",
      telefono: "",
      email: "",
      moneda: "",

      comprobanteModal: 0,

      fecha_vencimiento_formateada: "",
      fecha_vencimiento: new Date().toISOString().substr(0, 10),
      menuCalendario: false,

      desabilitar: false, // Desabilitamos boton guardar
      isValid: true, // validamos que todos lo campos sean correctos
      isValidP: true,

      // Persona
      nombre: "",
      idtipo_documento_pers: "",
      tipo_documentos_cli: [],
      num_documento: "",
      idubigeo: "", // select ubigeos
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",
      verPersona: false,
      desabilitarBuscar: false,
      desabilitarPer: false,
      isValidPer: true,
      // Persona
      tipo_afectacion: [],

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      detallePresentaciones: [],

      tipo_pedido: "Pedido",
      tipos_pedido: [
        { text: "Pedido", value: "Pedido" },
        { text: "Proforma", value: "Proforma" },
      ],

      stringPdf: "",
      srcPdf: "",
      blobPdf: "",
      numPages: undefined,

      editedIndex: -1,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),
    ...mapGetters(["esAdministrador", "esCaja"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    // Titulo de pedidos
    formTitle() {
      return this.editedIndex === -1 ? "" : "Actualizar";
    },

    calcularTotal: function () {
      let me = this;
      var resultado = 0.0;

      for (var i = 0; i < me.detalles.length; i++) {
        if (me.detalles[i].idtipo_afectacion == "10") {
          resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].xcantidad) * me.common.valDec(me.detalles[i].precio_unit_venta) - me.common.valDec(me.detalles[i].descto_item), 2);
        }
      }
      return resultado;
    },

    calcularGratuitoTotal: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        if (me.detalles[i].idtipo_afectacion == "31") {
          resultado = resultado + me.common.roundOut(me.common.valInt(me.detalles[i].xcantidad) * me.common.valDec(me.detalles[i].precio_unit_venta) - me.common.valDec(me.detalles[i].descto_item), 2);
        }
      }
      return resultado;
    },

    calcularDescuentoItem: function () {
      let me = this;
      var resultado = 0.0;
      for (var i = 0; i < me.detalles.length; i++) {
        resultado = resultado + me.common.roundOut(me.common.valDec(me.detalles[i].descto_item), 2);
      }
      return resultado;
    },
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobante();
    },
    verProductos(val) {
      val || this.ocultarProductos();
    },
    brDescripcion(newVal) {
      if (newVal === null) {
        this.brDescripcion = "";
      }
    },
  },

  created() {
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);

    this.listar();
    this.select();
  },

  methods: {
    ...mapActions(["snackBar"]),

    //#region LISTAR PEDIDOS
    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Pedidos/ListarFiltroPedidoVentas", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          buscar: me.buscar_pedido == "" ? "-" : me.buscar_pedido,
          fechaInicio: me.dates[0],
          fechaFin: me.dates[1] == undefined ? me.dates[0] + " 23:59:59" : me.dates[1] + " 23:59:59",
        },
      })
        .then(function (response) {
          me.pedidos = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion

    irVentas(item) {
      this.$router.push({
        name: "ventas",
        params: {
          depedido: true,
          deidtipo_servicio: item.idtipo_servicio,
          deidpersona: item.idpersona,
          deidpedido: item.idpedido,
        },
      });
    },

    //#region SELECT
    selectCliente(id) {
      let me = this;

      var clientesArray = [];

      if (id) {
        axios.get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            clientesArray = response.data;
            clientesArray.map(function (x) {
              me.clientes.push({
                text: x.num_documento + " " + x.nombre + " |" + x.distrito + " - " + x.direccion,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })

          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios.get("api/Personas/SelectPersonasFiltro", {
          params: {
            buscar: me.buscar_cliente == "" ? "-" : me.buscar_cliente == null ? "-" : me.buscar_cliente,
            tipo_doc: "-",
          },
        })
          .then(function (response) {
            clientesArray = response.data;
            clientesArray.map(function (x) {
              me.clientes.push({
                text: x.num_documento + " " + x.nombre + " |" + x.distrito + " - " + x.direccion,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })

          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios.get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Ubigeos/SelectUbigeosFiltro", {
          params: {
            buscar: me.buscar_ubigeo == "" ? "-" : me.buscar_ubigeo == null ? "-" : me.buscar_ubigeo,
          },
        })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    select() {
      let me = this;

      var tipo_serviciosArray = [];
      axios.get("api/Tipo_Servicios/SelectMonVenta")
        .then(function (response) {
          tipo_serviciosArray = response.data;
          tipo_serviciosArray.map(function (x) {
            me.tipo_servicios.push({
              text: x.nombre,
              value: x.idtipo_servicio,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
      var tipo_documentosArray = [];
      axios.get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos_cli.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      var tipo_afectacionArray = [];
      axios.get("api/Tipo_Afectacion/Select")
        .then(function (response) {
          tipo_afectacionArray = response.data;
          tipo_afectacionArray.map(function (x) {
            me.tipo_afectacion.push({
              text: x.descripcion,
              value: x.idtipo_afectacion,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion SELECT

    //#region PEDIDO
    guardarPedido() {
      let me = this;
      // Validamos detalle
      if (me.detalles.length <= 0) {
        return me.snackBar({ cl: "warning", msg: "Ingrese al menos un producto al detalle." });
      }

      me.desabilitar = true;
      me.detalle_pedidos = [];
      me.convertirDetalle();

      if (me.editedIndex > -1) {
        // Editar
        axios.put("api/Pedidos/ActualizarPedidoCliente", {
          idpedido: me.idpedido,
          idsucursal: parseInt(me.usuario.idsucursal),
          idpersona: me.idpersona,
          idtipo_servicio: me.idtipo_servicio,
          idusuario: parseInt(me.usuario.idusuario),
          tipo_pedido: me.tipo_pedido,
          descto_global: 0,
          descto_item_total: 0,
          otros_item_total: 0,
          seguro: 0,
          inafecto: 0,
          exonerado: 0,
          subtotal_gravado: parseFloat(me.subtotal_gravado),
          gratuito: parseFloat(me.gratuito),
          igv_gravado: parseFloat(me.igv_gravado),
          importe_total: parseFloat(me.importe_total),
          igv: me.igv,
          detalle_pedidos: me.detalle_pedidos,
        })
          .then(function (response) {
            me.snackBar({ cl: "success", msg: response.data });
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      } else {
        // Guardar
        axios.post("api/Pedidos/CrearPedidoCliente", {
          idsucursal: parseInt(me.usuario.idsucursal),
          idpersona: me.idpersona,
          idtipo_servicio: me.idtipo_servicio,
          idusuario: parseInt(me.usuario.idusuario),
          tipo_pedido: me.tipo_pedido,
          descto_global: 0,
          descto_item_total: 0,
          otros_item_total: 0,
          seguro: 0,
          inafecto: 0,
          exonerado: 0,
          subtotal_gravado: parseFloat(me.subtotal_gravado),
          gratuito: parseFloat(me.gratuito),
          igv_gravado: parseFloat(me.igv_gravado),
          importe_total: parseFloat(me.importe_total),
          igv: me.igv,
          detalle_pedidos: me.detalle_pedidos,
        })
          .then(function (response) {
            me.snackBar({ cl: "success", msg: response.data });
            me.ocultarNuevo();
            me.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
            me.listar();
          });
      }
    },

    convertirDetalle() {
      let me = this;
      for (var i = 0; i < me.detalles.length; i++) {
        me.detalle_pedidos.push({
          idproducto: me.detalles[i].idproducto,
          iddetalle_compra: me.detalles[i].iddetalle_compra,
          idunidad_medida: me.detalles[i].idunidad_medida,
          unidad: me.detalles[i].unidad,
          factor: parseInt(me.detalles[i].factor),
          xcantidad: parseInt(me.detalles[i].xcantidad),
          cantidad: parseInt(me.detalles[i].cantidad),
          valor_unit_venta: me.common.roundOut(parseFloat(me.detalles[i].precio_unit_venta) / (1 + me.igv), 4),
          precio_unit_venta: parseFloat(me.detalles[i].precio_unit_venta),
          descto_item: me.common.valDec(me.detalles[i].descto_item),
          valor_venta: me.common.roundOut((parseInt(me.detalles[i].xcantidad) * parseFloat(me.detalles[i].precio_unit_venta)) / (1 + me.igv), 2),
          precio_venta: me.common.roundOut(parseInt(me.detalles[i].xcantidad) * parseFloat(me.detalles[i].precio_unit_venta), 2),
          igv: me.igv * 100, // FE 18%
          codigo_producto: me.detalles[i].codigo_producto, // FE
          descripcion_adicional: me.detalles[i].descripcion_adicional,
          idprod_sunat: me.detalles[i].idprod_sunat, // FE
          idtipo_afectacion: me.detalles[i].idtipo_afectacion,
        });
      }
    },

    mostrarNuevoPedido() {
      this.verNuevoPedido = 1;
    },

    deleteItemPedido(item) {
      const respta = confirm(
        "¿Estás seguro de Eliminar el pedido Nº " + item.idpedido + " ?"
      );
      let me = this;

      if (respta) {
        axios.delete("api/Pedidos/Eliminar/" + item.idpedido)
          .then((response) => {
            me.snackBar({ cl: "info", msg: response.data });
            me.listar();
          })
          .catch(function (error) {
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    eliminarItemDetallePedido(arr, item) {
      var i = arr.indexOf(item);
      if (i !== -1) {
        arr.splice(i, 1);
      }
    },

    ocultarNuevo() {
      this.verNuevoPedido = 0;
      this.desabilitar = false;
      this.listar();
      this.limpiar();
    },
    //#endregion PEDIDO

    //#region IMPRESIÓN
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = this.tipo_pedido == "Pedido" ? "Pedido Cliente Nro" + this.idpedido : "Proforma Venta Nro" + this.idpedido;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace("data:application/pdf;filename=generated.pdf;base64,", ""),
        type: "pdf",
        base64: true,
      });
    },

    mostrarComprobante(item) {
      this.limpiar();
      this.idpedido = item.idpedido;
      this.tipo_pedido = item.tipo_pedido;
      this.idtipo_servicio = item.idtipo_servicio;
      this.sucursal = item.sucursal;
      this.cliente = item.cliente;
      this.num_documento = item.num_documento;
      this.fecha = item.fecha;
      this.igv = item.igv;
      this.moneda = item.moneda;
      this.idpedido = item.idpedido;
      this.subtotal_gravado = item.subtotal_gravado;
      this.gratuito = item.gratuito;
      this.igv_gravado = item.igv_gravado;
      this.importe_total = item.importe_total;
      this.comprobanteModal = 1;
      this.listarDetallePedidos(item.idpedido);
    },

    verImagen(item) {
      this.imagen_zoom = item.imagen;
      this.selectedZoom = true;
    },
    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },
    //#endregion IMPRESIÓN

    //#region DETALLES DE PEDIDOS
    // Ver pedido con detalles para modificar
    verDetallesPedidos(item) {
      this.limpiar();
      this.idpedido = item.idpedido;
      this.tipo_pedido = item.tipo_pedido;
      this.idtipo_servicio = item.idtipo_servicio;
      this.idpersona = item.idpersona;
      this.selectCliente(item.idpersona);
      this.listarDetallePedidos(item.idpedido);
      this.verNuevoPedido = 1;
      this.editedIndex = 1;
    },

    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];
      me.loadingDetalles = true;

      axios.get("api/Pedidos/ListarDetallesPedidos/" + id)
        .then(function (response) {
          detallesArray = response.data;
          detallesArray.map(function (x) {
            me.detalles.push({
              idproducto: x.idproducto,
              codigo_producto: x.codigo_producto, // FE
              idunidad_medida: x.idunidad_medida,
              unidad: x.unidad,
              codigo_unidad: x.codigo_unidad,
              factor: x.factor,
              xcantidad: x.xcantidad,
              marca: x.marca,
              descripcion: x.descripcion,
              descripcion_adicional: x.descripcion_adicional,
              marca: x.marca,
              lote: x.lote,
              fecha_vencimiento_prod: x.fecha_vencimiento_prod,
              cal_compr_factor: x.cal_compr_factor,
              stock_actual: x.stock_actual,
              iddetalle_compra: x.iddetalle_compra,
              idunidad_medida: x.idunidad_medida,
              cantidad: x.cantidad,
              valor_unit_venta: x.valor_unit_venta,
              precio_unit_venta: x.precio_unit_venta,
              descto_item: x.descto_item,
              valor_venta: x.valor_venta,
              precio_venta: x.precio_venta,
              idprod_sunat: x.idprod_sunat,
              idtipo_afectacion: x.idtipo_afectacion,
              precio_unit_compra_factor: me.common.roundOut(me.idtipo_servicio == 5 ? (x.idtipo_servicio == 3 ? x.precio_unit_compra_factor : x.precio_unit_compra_factor * me.tc) : (x.idtipo_servicio == 3 ? x.precio_unit_compra_factor / me.tc : x.precio_unit_compra_factor), 2) // > compra
            });
          });
          me.loadingDetalles = false;
          me.comprobanteModal == 1 ? me.crearPDF() : ""; // Solo para pedido pdf
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion  FIN DETALLES DE PEDIDOS

    //#region PRODUCTO
    buscarCodigoBarra() {
      let me = this;

      axios.get("api/Pedidos/BuscarCodigoBarraVenta/" + parseInt(me.usuario.idsucursal) + "/" + me.codigo_barra)
        .then(function (response) {
          //console.log(response.data);
          me.agregarDetalle(response.data);
          me.snackBar({ cl: "info", msg: "Se agrego " + response.data.descripcion + " al detalle." });
          me.codigo_barra = "";
        })
        .catch(function (error) {
          //console.log(error);
          me.snackBar({ cl: "warning", msg: "No hay registro de compra para el producto." });
          me.codigo_barra = "";
        });
    },

    listarProductoBuscar() {
      let me = this;

      axios.get("api/Pedidos/ListarProductoVenta", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          descripcion: me.brDescripcion == "" ? "-" : me.brDescripcion,
        },
      })
        .then(function (response) {
          me.productos = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    dblClickRepuesto(event, { item }) {
      this.agregarDetalle(item);
    },

    agregarDetalle(data = []) {
      let me = this;
      // Buscar el índice del detalle existente con el mismo iddetalle_compra
      const indiceExistente = me.detalles.findIndex(detalle => detalle.iddetalle_compra === data["iddetalle_compra"]);

      if (indiceExistente !== -1) {
        // Si el detalle ya existe, sumar la cantidad
        me.detalles[indiceExistente].xcantidad += 1;
      } else {
        me.detalles.push({
          idproducto: data["idproducto"],
          idunidad_medida: data["idunidad_medida"],
          unidad: data["unidad"],
          factor: data["factor"],
          xcantidad: 1,
          iddetalle_compra: data["iddetalle_compra"],
          codigo_producto: data["codigo_producto"], // FE
          marca: data["marca"],
          lote: data["lote"],
          fecha_vencimiento_prod: data["fecha_vencimiento_prod"],
          idtipo_afectacion: data["idtipo_afectacion"],
          descripcion: data["descripcion"],
          descripcion_adicional: data["descripcion_adicional"],
          cal_compr_factor: data["cal_compr_factor"],
          stock_actual: data["stock_actual"],
          idunidad_medida: data["idunidad_medida"],
          cantidad: 1,
          valor_unit_venta: 0,
          precio_unit_venta: me.idtipo_servicio == 5 ? me.common.roundOut(data["precio_unit_venta"], 2) : me.common.roundOut((data["precio_unit_venta"] / me.tc), 2), // Capturamos el precio seleccionado
          descto_item: 0,
          valor_venta: 0,
          precio_venta: 0,
          idprod_sunat: data["idprod_sunat"],
          precio_unit_compra_factor: me.common.roundOut(me.idtipo_servicio == 5 ? (data["idtipo_servicio"] == 3 ? data["precio_unit_compra_factor"] : data["precio_unit_compra_factor"] * me.tc) : (data["idtipo_servicio"] == 3 ? data["precio_unit_compra_factor"] / me.tc : data["precio_unit_compra_factor"]), 2) // > compra
        });
      }
    },

    mostrarProductos() {
      this.verProductos = 1;
    },

    ocultarProductos() {
      this.verProductos = 0;
      this.productos = [];
      this.imagen_zoom = "";
      this.brDescripcion = "";
      // limpiamos variables de buscar producto en establecimientos
      this.idproducto = "";
      this.buscar_producto = "";
      this.precioVenta = 0;
      //this.totalProductos = [];
      this.stockEstablecimiento = [];
      this.stockMinimo = 1;
    },


    changeProductos(valor) {
      this.precioVenta = 0;
      this.stockMinimo = 1;

      if (valor != null) {
        const selectId = this.totalProductos.find(
          (elemento) => elemento.value === valor
        );
        this.precioVenta = selectId.precioVenta;
        this.stockMinimo = selectId.stockMinimo;
      }
    },

    // Buscar productos
    selectProductos() {
      let me = this;
      var productosArray = []; // Limpiamos para cargar nuevamente

      axios.get("api/Productos/SelectBuscar", {
        params: {
          buscar: me.buscar_producto == "" ? "-" : me.buscar_producto == null ? "-" : me.buscar_producto
        },
      })
        .then(function (response) {
          productosArray = response.data;
          productosArray.map(function (x) {
            me.totalProductos.push({ text: x.codigo_producto + " " + x.descripcion + " " + x.categoria + " " + x.marca, value: x.idproducto, precioVenta: x.precio_unit_venta, stockMinimo: x.stock_minimo });
          });
        })
        .then((data) => { })
        .catch(function (error) {
          //console.log(error);
        });

    },

    listarStockEstablecimiento(id) {
      let me = this;

      axios.get("api/Productos/ListarStockEstablecimiento", {
        params: {
          idproducto: id
        },
      })
        .then(function (response) {
          me.stockEstablecimiento = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    mostrarEstablecimientos() {
      this.verEstablecimientosProductos = 1;
    },
    ocultarEstablecimientos() {
      this.verEstablecimientosProductos = 0;
    },

    verPresentaciones(id) {
      let me = this;
      var detallesArray = [];
      me.detallePresentaciones = [];

      axios.get("api/Productos/SelectPresentaciones/" + id)
        .then(function (response) {
          detallesArray = response.data;
          detallesArray.map(function (x) {
            me.detallePresentaciones.push({
              idproducto: x.idproducto,
              idunidad_medida: x.idunidad_medida,
              unidad: x.unidad,
              factor: x.factor,
              precio_unit: x.precio_unit
            });
          });
        })
        .catch(function (error) {
          // console.log(error);
        });
    },
    selectUnidad(idunidad, index) {
      const selectId = this.detallePresentaciones.find((elemento) => elemento.idunidad_medida === idunidad);

      if (selectId) {
        // Actualizamos valores de unidad
        this.detalles[index].idunidad_medida = selectId.idunidad_medida;
        this.detalles[index].unidad = selectId.unidad;
        this.detalles[index].factor = selectId.factor;
        // 5 = Soles y 6 = Dolares
        this.idtipo_servicio == 5 ? (this.detalles[index].precio_unit_venta = selectId.precio_unit) : (this.detalles[index].precio_unit_venta = this.common.roundOut((selectId.precio_unit / this.tc), 2));
        this.snackBar({ cl: "info", msg: "Se cambio la Unidad de medida: " + selectId.unidad + " X " + selectId.factor });
      }
    },
    //#endregion FIN PRODUCTO

    //#region PERSONA
    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;
      // https://apisperu.com
      var token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento_pers == 1) {
        fetch("https://dniruc.apisperu.com/api/v1/dni/" + num_documento + "?token=" + token)
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo);
              me.nombre = data.apellidoPaterno + " " + data.apellidoMaterno + " " + data.nombres;
            } else {
              me.nombre = "";
              me.snackBar({ cl: "info", msg: data.message }); // Sin resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.snackBar({ cl: "error", msg: "Error en el Nº DNI." });
          });
      } else if (me.idtipo_documento_pers == 6) {
        axios.post("api/Personas/Padron", {
          ruc: me.num_documento,
        })
          .then(function (response) {
            me.desabilitarBuscar = false;
            me.idubigeo = response.data.ubigeo;
            me.selectUbigeo(me.idubigeo);
            me.nombre = response.data.razon;
            me.direccion = response.data.direccion == null ? "" : response.data.direccion.trim();
            me.snackBar({ cl: "info", msg: "Contribuyente con estado " + response.data.estado + " y condición " + response.data.condicion });
          })
          .catch(function (error) {
            me.desabilitarBuscar = false;
            me.nombre = "";
            me.direccion = "";
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    guardarPersona() {
      let me = this;
      me.desabilitarPer = true;

      //Código para guardar
      axios.post("api/Personas/Crear", {
        tipo_persona: "Cliente",
        nombre: me.nombre,
        idtipo_documento: me.idtipo_documento_pers,
        num_documento: me.num_documento,
        idubigeo: me.idubigeo,
        direccion: me.direccion,
        telefono: me.telefono,
        email: me.email,
      })
        .then((response) => {
          me.idpersona = response.data.idpersona;
          me.selectCliente(me.idpersona);
          me.desabilitarPer = false;
          me.snackBar({ cl: "success", msg: response.data.mensaje });
          me.closePersona();
        })
        .catch(function (error) {
          me.desabilitarPer = false;
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    mostrarPersona() {
      this.verPersona = true;
    },

    closePersona() {
      this.verPersona = false;
      this.limpiarPersona();
    },

    limpiarPersona() {
      this.idtipo_documento_pers = "";
      this.num_documento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.direccion = "";
      this.email = "";
      this.telefono = "";
    },
    //#endregion PERSONA

    limpiar() {
      this.igv = parseFloat(this.datos.igv); // Requerido

      this.idpedido = "";
      this.idtipo_servicio = 5; // Pedido Soles
      this.idpersona = "";
      this.num_documento = "";
      this.tipo_pedido = "Pedido";

      this.subtotal_gravado = 0;
      this.gratuito = 0;
      this.igv_gravado = 0;
      this.importe_total = 0;

      this.detalles = [];
      this.detalle_pedidos = [];
      this.clientes = [];
      this.moneda = "";

      this.codigo_barra = "";

      this.stringPdf = "";
      this.srcPdf = "";
      this.blobPdf = "";

      this.editedIndex = -1;
    },

    crearPDF() {
      var imgData = "data:image/png;base64," + this.logo;
      var ancho = 45;
      var alto = 28.45;
      var doc = new jsPDF("p", "mm", [210, 297]);
      var centro = doc.internal.pageSize.width / 2;

      var columns = [
        { header: "ITEM", dataKey: "item" },
        { header: "CANT.", dataKey: "xcantidad" },
        { header: "U/M", dataKey: "unidad" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion" },
        { header: "MARCA", dataKey: "marca" },
        { header: "LOTE", dataKey: "lote" },
        { header: "FEC. VENC.", dataKey: "fecha_vencimiento_prod" },
        { header: "P.UNITARIO", dataKey: "precio_unit_venta" },
        { header: "TOTAL", dataKey: "subtotal" },
      ];
      var body = [];
      var foot = [];

      //if (this.tipo_pedido == "Pedido") {
      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          item: i + 1,
          xcantidad: this.detalles[i].xcantidad,
          unidad: this.detalles[i].unidad,
          descripcion: this.detalles[i].descripcion,
          marca: this.detalles[i].marca.slice(0, 5),
          lote: this.detalles[i].lote,
          fecha_vencimiento_prod: this.detalles[i].fecha_vencimiento_prod ? moment(this.detalles[i].fecha_vencimiento_prod).format("DD/MM/YYYY") : "",
          precio_unit_venta: numeral(this.detalles[i].precio_unit_venta).format("###,##0.00"),
          subtotal: numeral(this.detalles[i].precio_venta).format("###,##0.00"),
        });

        if (i === this.detalles.length - 1) {
          foot.push(
            [
              {
                content: "Importe Total " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 8,
              },
              numeral(this.importe_total).format("###,##0.00"),
            ],
            [
              {
                content: "Gratuita " + (this.moneda == "S" ? "S/" : "$"),
                colSpan: 8,
              },
              numeral(this.gratuito).format("###,##0.00"),
            ]
          );
        }
      }

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 15, 15, ancho, alto);
          // Datos empresa
          doc.autoTable({
            startY: 17,
            theme: "plain",
            margin: { left: 40, right: 40 },
            bodyStyles: {
              cellPadding: 0.5,
              font: "helvetica",
              fontStyle: "normal",
              fontSize: 6,
              textColor: 0,
              halign: "center",
            },
            body: [
              [{ content: this.datos.empresa, styles: { fontSize: 8, fontStyle: "bold" } }],
              [{ content: this.datos.emp_comercial, styles: { fontSize: 7, fontStyle: "bold" } }],
              (this.datos.eslogan ? [{ content: this.datos.eslogan }] : undefined),
              [{ content: "Email: " + this.datos.mailsucursal + " Tel: " + this.datos.telsucursal }],
              [{ content: this.datos.dirsucursal }],
              [{ content: this.datos.dptosucursal + " - " + this.datos.provsucursal + " - " + this.datos.dttosucursal }]
            ].filter(item => item !== undefined),
          });
          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.tipo_pedido == "Pedido" ? "PEDIDO DE VENTA" : "PROFORMA DE VENTA", centro + 70, 27, "center");
          doc.text("N°" + this.idpedido, centro + 70, 32, "center");

          // DESTINATARIO
          doc.autoTable({
            startY: 44,
            theme: "plain",
            tableLineColor: [82, 86, 89],
            tableLineWidth: 0.2,
            margin: { left: 15, right: 15 },
            bodyStyles: {
              cellPadding: 1,
              font: "helvetica",
              fontStyle: "normal",
              fontSize: 6,
              textColor: 0,
            },
            columnStyles: {
              0: { cellWidth: 17 },
              1: { cellWidth: 106 },
              2: { cellWidth: 23 },
              3: { cellWidth: 34 }
            },
            body: [
              [{ content: "Doc. Nº", styles: { fontStyle: "bold" } }, { content: ": " + this.num_documento }, { content: "Fecha Emisión", styles: { fontStyle: "bold" }  }, { content: ": " + moment(this.fecha).format("DD/MM/YYYY") }],
              [{ content: "Cliente", styles: { fontStyle: "bold" }  }, { content: ": " + this.cliente }, { content: "Establecimiento", styles: { fontStyle: "bold" }  }, { content: ": " + this.sucursal }],
            ],
          });
          // Fin Datos documento
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(6);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("Página " + String(i) + " de " + String(pageCount), centro, 290, "center"
          );
        }
      };

      // DETALLE DE VENTA
      doc.autoTable({
        theme: "grid",
        columns,
        body,
        foot,
        margin: { left: 15, right: 15, top: 56 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "bold",
          fontSize: 7,
          halign: "center",
          fillColor: [82, 86, 89],
          textColor: 255,
          lineColor: [82, 86, 89],
          lineWidth: 0.2,
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 7,
          textColor: 0,
          lineColor: [82, 86, 89],
          lineWidth: 0.2,
        },
        footStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fillColor: 255,
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          item: { cellWidth: 7, halign: "center" },
          xcantidad: { cellWidth: 10, halign: "center" },
          unidad: { cellWidth: 10, halign: "center" },
          descripcion: { cellWidth: "auto" },
          marca: { cellWidth: 13, halign: "center" },
          lote: { cellWidth: 20, halign: "center" },
          fecha_vencimiento_prod: { cellWidth: 15, halign: "center" },
          precio_unit_venta: { cellWidth: 20, halign: "right" },
          subtotal: { cellWidth: 20, halign: "right" },
        },
      });

      addHeaders(doc);
      addFooters(doc);

      doc.setProperties({
        title: this.tipo_pedido == "Pedido" ? "Pedido Venta Nro" : "Proforma Venta Nro" + this.idpedido + ".pdf",
      });

      //doc.save("PedidoClienteNro" + this.idpedido + ".pdf");
      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      // Para visualizar todas las paginas del pdf
      this.srcPdf = pdf.createLoadingTask(this.stringPdf);
      this.srcPdf.promise.then((pdf) => {
        this.numPages = pdf.numPages;
      });

    },
  },
};
</script>

<style scoped>
.right-input>>>input {
  text-align: right;
}

.center-input>>>input {
  text-align: center;
}
</style>