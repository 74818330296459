<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout alig-start>
      <v-flex>
        <!-- Modal persona -->
        <v-dialog v-model="verPersona" max-width="700px" persistent>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>Nuevo Proveedor</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="guardarPersona" :loading="desabilitarPer" :disabled="desabilitarPer || !isValidPer">
                <v-icon>save</v-icon>
              </v-btn>
              <v-btn icon @click="closePersona">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-form v-model="isValidPer">
                <v-row class="mt-2">
                  <v-col cols="6" sm="3">
                    <v-select dense v-model="idtipo_documento_pers" :items="tipo_documentos_cli" label="Tipo Documento"
                      :rules="[v => !!v || 'Tipo documento requerido']" @change="num_documento = ''" hide-details>
                    </v-select>
                  </v-col>
                  <v-col cols="10" sm="5">
                    <v-text-field dense v-model.trim="num_documento" label="Numero Documento"
                      :rules="[v => !!v || 'Numero documento requerido', v => (idtipo_documento_pers == 1 ? v.length == 8 || 'Numero DNI' : v.length == 11 || 'Numero RUC')]"
                      :maxlength="idtipo_documento_pers == 1 ? 8 : 11"
                      :disabled="idtipo_documento_pers == 1 ? false : idtipo_documento_pers == 6 ? false : true"
                      @keypress="common.isNum($event)" hide-details></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="1">
                    <div class="text-center">
                      <v-btn x-small fab color="primary" class="white--text" :loading="desabilitarBuscar"
                        :disabled="(idtipo_documento_pers == 1 && num_documento.length == 8 ? false : idtipo_documento_pers == 6 && num_documento.length == 11 ? false : true) || desabilitarBuscar"
                        @click="extraerDocumento(num_documento)" hide-details>
                        <v-icon>search</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model.trim="nombre"
                      :label="idtipo_documento_pers == 1 ? 'Apellidos y Nombres' : 'Nombre'"
                      :rules="[v => !!v || 'Nombre requerido', v => (!!v && v.length >= 3 && v.length <= 200) || 'Nombre debe tener entre 3 a 200 caracteres']"
                      maxlength="100" prepend-inner-icon="person" v-uppercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete dense @keyup="selectUbigeo()" :search-input.sync="buscar_ubigeo" v-model="idubigeo"
                      :items="ubigeos" label="Ubigeo" :rules="[v => !!v || 'Ubigeo requerido']"
                      prepend-inner-icon="pin_drop" clearable hide-details></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field dense v-model.trim="direccion" label="Direccion"
                      :rules="[v => v.length <= 200 || 'La dirección no debe tener mas de 200 caracteres']"
                      maxlength="200" prepend-inner-icon="place" v-uppercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field dense v-model.trim="email" label="Email"
                      :rules="[v => v == '' ? !v : /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                      maxlength="60" prepend-inner-icon="mail" v-lowercase hide-details></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field dense v-model.trim="telefono" label="Telefono"
                      :rules="[v => v.length <= 30 || 'El Telefono no debe tener mas de 30 caracteres', Rules.unespacio]"
                      maxlength="30" prepend-inner-icon="phone" v-uppercase hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Modal persona -->

        <!-- Listado caja -->
        <v-data-table :loading="loading" :headers="headers" :items="movimientos" :items-per-page="15" dense
          class="elevation-3">
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down">CAJA</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <!-- Formulario busqueda de movimiento caja -->
              <v-text-field class="mr-1" v-model.trim="buscar" dense label="Búsqueda" @keyup="listar()" single-line
                hide-details></v-text-field>
              <v-spacer />
              <v-menu v-model="menuFechaBuscar" :close-on-content-click="!menuFechaBuscar" transition="scale-transition"
                offset-y min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listar()" class="mr-1" style="max-width: 220px;"></v-text-field>
                </template>
                <v-date-picker v-model="dates"
                  @input="dates.length == 2 ? menuFechaBuscar = false : menuFechaBuscar = true" @change="listar()"
                  range></v-date-picker>
              </v-menu>
              <v-spacer />
              <!-- Fin Formulario busqueda de movimiento caja -->

              <!-- Modal crear movimiento caja -->
              <v-dialog v-model="dialog" max-width="800px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn color="primary" class="hidden-sm-and-down" v-on="on" small outlined>Nuevo</v-btn>
                  <v-btn color="primary" class="hidden-md-and-up" v-on="on" fab x-small outlined>
                    <v-icon>add</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-toolbar dark dense class="primary" flat>
                    <v-toolbar-title>Nuevo Movimiento Caja</v-toolbar-title>
                  </v-toolbar>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-row class="mt-2">
                        <v-col cols="12" sm="4">
                          <v-select dense v-model="idtipo_servicio" :items="tipo_servicios" label="Moneda"
                            :rules="[v => !!v || 'La moneda es requerido']" hide-details></v-select>
                        </v-col>
                        <v-col cols="12" sm="5">
                          <v-select dense v-model="idtipo_movimiento" :items="tipo_movimientos" label="Tipo Movimiento"
                            :rules="[v => !!v || 'El movimiento es requerido']" hide-details></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-menu v-model="menuFecha" :close-on-content-click="false" :nudge-right="40"
                            transition="scale-transition" offset-y min-width="290px">
                            <template v-slot:activator="{ on }">
                              <v-text-field dense v-model="fecha_comprobante_formateada" label="Fecha comprobante"
                                readonly v-on="on" prepend-inner-icon="event" hide-details></v-text-field>
                            </template>
                            <v-date-picker v-model="fecha_comprobante" @input="menuFecha = false"
                              @change="fechaSelectComprob(fecha_comprobante)"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field dense v-model.trim="importe_total" label="Importe"
                            :rules="[v => !!v || 'Requerido', v => (!!v && v.length >= 1 && v.length <= 8) || 'Importe debe tener más de 1 y menos de 9 caracteres']"
                            :prefix="idtipo_servicio == '' ? '' : idtipo_servicio == 1 ? 'S/' : '$'" maxlength="9"
                            @keypress="common.numDec($event, importe_total, 2)" onClick="this.select()"
                            hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="9">
                          <v-text-field dense v-model.trim="descripcion" label="Descripción"
                            :rules="[v => (v.length >= 10 && v.length <= 140) || 'La descripción puede tener de 10 a 140 caracteres', Rules.unespacio]"
                            maxlength="140" prepend-inner-icon="notes" v-uppercase hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="9">
                          <v-autocomplete dense @keyup="selectPersona()" :search-input.sync="buscar_proveedor"
                            v-model="idpersona" :items="personas" label="Razón Social"
                            :rules="[v => !!v || 'La razón social es requerido']" clearable hide-details
                            append-outer-icon="person_add" @click:append-outer="mostrarPersona"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field dense v-model.trim="cuenta" label="Cuenta Contable"
                            :rules="[v => v.length <= 10 || 'La cuenta no debe tener mas de 10 caracteres.']"
                            maxlength="10" @keypress="common.isNum($event)" hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="5">
                          <v-select dense v-model="comprobantes" :items="tipo_comprobantes" label="Tipo Documento"
                            :rules="[v => !!v || 'El comprobantes es requerido']"
                            @change="comprobanteSeleccionado(comprobantes)" hide-details></v-select>
                        </v-col>
                        <v-col cols="12" sm="3">
                          <v-text-field dense v-model.trim="serie_comprobante"
                            :rules="[v => v.length == 4 || 'Serie requerido', Rules.sinespacio]" label="Serie Doc."
                            maxlength="4" :disabled="disabled" v-uppercase hide-details>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-text-field dense v-model.trim="num_comprobante" label="Numero Doc"
                            :rules="[v => !!v || 'Numero requerido']" maxlength="8"
                            @blur.prevent="llenarCeroNumComprobante(num_comprobante)" :disabled="disabled"
                            @keypress="common.isNum($event)" hide-details></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-3 justify-center">
                    <v-btn color="error" @click="close" small outlined>
                      <v-icon left>close</v-icon>Cancelar
                    </v-btn>
                    <v-btn color="primary" @click="guardar" :loading="desabilitar" :disabled="desabilitar || !isValid"
                      small outlined>
                      <v-icon left>check</v-icon>Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal crear movimiento caja -->

              <!-- Modal anular Caja -->
              <v-dialog v-model="adModal" max-width="330">
                <v-card>
                  <v-card-title class="justify-center">¿Anular Movimiento Caja?</v-card-title>
                  <v-card-text>
                    Estás a punto de
                    <span>Anular</span>
                    el movimiento de Caja Nº {{ adId }}
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="info" @click="anularCerrar" small outlined>Cancelar</v-btn>
                    <v-btn color="error" @click="anular" small outlined>Anular</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal anular Caja -->
            </v-toolbar>
          </template>

          <!-- Opciones -->
          <template v-slot:item.comprobante="{ item }">
            <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
          </template>
          <template v-slot:item.ingreso="{ item }">
            <div class="text-right">
              <span>{{ item.ingreso | formatearNumero }}</span>
            </div>
          </template>
          <template v-slot:item.egreso="{ item }">
            <div class="text-right">
              <span>{{ item.egreso | formatearNumero }}</span>
            </div>
          </template>

          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>

          <template v-slot:item.moneda="{ item }">
            <template v-if="item.moneda == 'S'">
              <span>Soles</span>
            </template>
            <template v-else>
              <span>Dolares</span>
            </template>
          </template>

          <template v-slot:item.estado="{ item }">
            <template v-if="item.estado != 'Anulado'">
              <span>{{ item.estado }}</span>
            </template>
            <template v-else>
              <span class="error--text">{{ item.estado }}</span>
            </template>
          </template>

          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="print" @click="mostrarComprobante(item)">print</v-icon>
            </v-btn>
            <template v-if="item.estado != 'Anulado'">
              <v-btn small icon>
                <v-icon color="error" @click="anularMostrar(item)">delete</v-icon>
              </v-btn>
            </template>
          </template>
          <!-- Fin Opciones -->
        </v-data-table>
        <!-- Fin Listado caja -->

        <!-- Modal Impresion-->
        <v-dialog v-model="comprobanteModal" max-width="600px" scrollable>
          <v-card>
            <v-toolbar dark dense class="primary" flat>
              <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobante()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf :src="stringPdf"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Modal Impresion-->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import numeral from "numeral";
import moment from "moment";
import jsPDF from "jspdf";
import pdf from "vue-pdf";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      movimientos: [],
      dialog: false,
      headers: [
        //{ text: "CdMv", value: "idmovimiento" },
        //{ text: "USUARIO", value: "usuario" },
        { text: "TIPO MOVIMIENTO", value: "movimiento", sortable: false },
        { text: "COMPROBANTE", value: "comprobante", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "MONEDA", value: "moneda", sortable: false },
        { text: "INGRESOS", value: "ingreso", sortable: false },
        { text: "EGRESOS", value: "egreso", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false, width: 90 },
      ],
      loading: false,

      buscar: "",
      buscar_proveedor: "",
      buscar_ubigeo: "",

      idmovimiento: "",
      importe_total: "",
      idtipo_movimiento: "",
      tipo_movimientos: [],
      idtipo_servicio: "",
      tipo_servicios: [],
      descripcion: "",

      idtipo_comprobante: "",
      comprobante: "",
      comprobantes: "",

      tipo_comprobantes: [],
      serie_comprobante: "",
      descripcion_caja: "",
      idpersona: "",
      personas: [],
      cuenta: "",
      num_comprobante: "",
      ruc_comprobante: "",
      denom_comprobante: "",
      moneda: "",
      sucursal: "",
      movimiento: "",
      pago: 0,

      adModal: 0,
      adFecha: "",
      adId: "",
      fecha: "",

      // Persona
      nombre: "",
      idtipo_documento_pers: "",
      tipo_documentos_cli: [],
      num_documento: "",
      idubigeo: "",
      ubigeos: [],
      direccion: "",
      telefono: "",
      email: "",
      verPersona: false,
      desabilitarBuscar: false,
      desabilitarPer: false,
      isValidPer: true,

      fecha_comprobante_formateada: "",
      fecha_comprobante: moment().format("YYYY-MM-DD"),
      menuFecha: false,

      comprobanteModal: 0,

      desabilitar: false,
      isValid: true,
      disabled: true, // Serie y numero comprobantes desabilitados

      stringPdf: "",
      blobPdf: "",

      dates: [
        moment().startOf("year").format("YYYY-MM-DD"),
        moment().endOf("year").format("YYYY-MM-DD"),
      ],
      menuFechaBuscar: false,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.fechaSelectComprob(this.fecha_comprobante);
    this.listar();
    this.select();
  },

  methods: {
    ...mapActions(["snackBar"]),

    fechaSelectComprob(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_comprobante_formateada = ddmmyy;
    },

    //#region SELECT
    selectPersona(id) {
      let me = this;

      var personasArray = []; // Limpiamos para cargar nuevamente

      if (id) {
        axios.get("api/Personas/SelectPersonas/" + id)
          .then(function (response) {
            personasArray = response.data;
            personasArray.map(function (x) {
              me.personas.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .then((data) => { })
          .catch(function (error) {
            //console.log(error);
          });
      } else {
        axios.get("api/Personas/SelectPersonasFiltro", {
          params: {
            buscar:
              me.buscar_proveedor == ""
                ? "-"
                : me.buscar_proveedor == null
                  ? "-"
                  : me.buscar_proveedor,
            tipo_doc: "-",
          },
        })
          .then(function (response) {
            personasArray = response.data;
            personasArray.map(function (x) {
              me.personas.push({
                text: x.num_documento + " " + x.nombre,
                value: x.idpersona,
                tipo_documento: x.idtipo_documento,
              });
            });
          })
          .then((data) => { })
          .catch(function (error) {
            //console.log(error);
          });
      }
    },

    // Seleccionar ubigeo por busqueda
    selectUbigeo(id) {
      let me = this;

      var ubigeosArray = [];

      if (id) {
        axios.get("api/Ubigeos/SelectUbigeos/" + id)
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      } else {
        axios.get("api/Ubigeos/SelectUbigeosFiltro", {
          params: {
            buscar: me.buscar_ubigeo == "" ? "-" : me.buscar_ubigeo == null ? "-" : me.buscar_ubigeo,
          },
        })
          .then(function (response) {
            ubigeosArray = response.data;
            ubigeosArray.map(function (x) {
              me.ubigeos.push({
                text: x.depprovdist,
                value: x.idubigeo,
              });
            });
          })
          .catch(function (error) {
            //console.log(url);
          });
      }
    },

    /* Seleccionar tipos */
    select() {
      let ts = this;
      var tipo_serviciosArray = [];
      axios.get("api/Tipo_Servicios/SelectMonCaja")
        .then(function (response) {
          tipo_serviciosArray = response.data;
          tipo_serviciosArray.map(function (x) {
            ts.tipo_servicios.push({
              text: x.nombre,
              value: x.idtipo_servicio,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      let tm = this;
      var tipo_movimientosArray = [];
      axios.get("api/Tipo_Movimientos/Select")
        .then(function (response) {
          tipo_movimientosArray = response.data;
          tipo_movimientosArray.map(function (x) {
            tm.tipo_movimientos.push({
              text: x.nombre,
              value: x.idtipo_movimiento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      let td = this;
      var tipo_comprobantesArray = [];
      axios.get("api/Tipo_Comprobantes/SelectCaja")
        .then(function (response) {
          tipo_comprobantesArray = response.data;
          tipo_comprobantesArray.map(function (x) {
            td.tipo_comprobantes.push({
              text: x.descripcion,
              value: x.idtipo_comprobante + "-" + x.descripcion,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });

      let me = this;
      var tipo_documentosArray = [];
      axios.get("api/Tipo_Documentos/Select")
        .then(function (response) {
          tipo_documentosArray = response.data;
          tipo_documentosArray.map(function (x) {
            me.tipo_documentos_cli.push({
              text: x.descripcion,
              value: x.idtipo_documento,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    /* Fin Seleccionar tipos */
    //#endregion SELECT

    //#region CAJA
    listar() {
      let me = this;
      me.loading = true;

      axios.get("api/Movimientos/ListarCajaFiltro", {
        params: {
          idsucursal: parseInt(me.usuario.idsucursal),
          idusuario: parseInt(me.usuario.idusuario),
          buscar: me.buscar == "" ? "-" : me.buscar,
          fechaInicio: me.dates[0],
          fechaFin: me.dates[1] == undefined ? me.dates[0] + " 23:59:59" : me.dates[1] + " 23:59:59",
        },
      })
        .then(function (response) {
          //console.log(response);
          me.movimientos = response.data;
          me.loading = false;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    guardar() {
      let me = this;
      me.desabilitar = true;

      axios.post("api/Movimientos/CrearCaja", {
        idsucursal: parseInt(me.usuario.idsucursal),
        idusuario: parseInt(me.usuario.idusuario),
        idtipo_movimiento: me.idtipo_movimiento,
        idtipo_servicio: me.idtipo_servicio,
        idtipo_comprobante: me.idtipo_comprobante,
        serie_comprobante: me.serie_comprobante,
        num_comprobante: me.num_comprobante,
        importe_total: parseFloat(me.importe_total),
        // Detalle Caja
        descripcion: me.descripcion,
        fecha_comprobante: me.fecha_comprobante,
        idpersona: me.idpersona,
        cuenta: me.cuenta,
      })
        .then(function (response) {
          me.desabilitar = false;
          me.snackBar({ cl: "success", msg: response.data });
          me.close();
          me.limpiar();
          me.listar();
        })
        .catch(function (error) {
          me.desabilitar = false;
          me.snackBar({ cl: "error", msg: error.response.data });
          me.listar();
        });
    },

    llenarCeroNumComprobante(num_comprobante) {
      this.num_comprobante = ("00000000" + num_comprobante).slice(-8);
    },

    comprobanteSeleccionado(comprobantes) {
      let arrayComprobante = comprobantes.split(["-"]);
      this.idtipo_comprobante = arrayComprobante[0]; // idtipo_comprobante
      this.comprobante = arrayComprobante[1]; // nombre comprobante
      if (this.idtipo_comprobante == "00") {
        // Si es Recibo interno
        this.disabled = true;
        this.serie_comprobante = "0000";
        this.num_comprobante = "00000000";
      } else {
        // Si es factura, boleta, guia
        this.disabled = false;
        this.serie_comprobante = "";
        this.num_comprobante = "";
      }
    },

    close() {
      this.dialog = false;
      this.limpiar();
    },

    ocultarComprobante() {
      this.comprobanteModal = 0;
      this.limpiar();
    },

    /* Anular */
    anularMostrar(item) {
      this.adModal = 1;
      this.adId = item.idmovimiento;
      this.adFecha = item.fecha;
    },

    anularCerrar() {
      this.adModal = 0;
    },

    anular() {
      let me = this;

      axios.put("api/Movimientos/AnularCaja/" + me.adId + "/" + me.adFecha + "/" + me.usuario.idsucursal + "/" + me.usuario.idusuario)
        .then(function (response) {
          me.snackBar({ cl: "success", msg: response.data });
          me.adModal = 0;
          me.adId = "";
          me.adFecha = "";
          me.listar();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
          me.adModal = 0;
          me.adId = "";
          me.adFecha = "";
        });
    },
    /* Fin Anular */
    //#endregion CAJA

    //#region IMPRESION
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName =
        "Caja-" + this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    printExternal() {
      printJS({
        printable: this.stringPdf.replace("data:application/pdf;filename=generated.pdf;base64,", ""),
        type: "pdf",
        base64: true,
      });
    },

    mostrarComprobante(item) {
      this.limpiar();
      this.idmovimiento = item.idmovimiento;
      this.sucursal = item.sucursal;
      this.movimiento = item.movimiento;
      this.pago = item.pago;
      this.comprobante = item.comprobante;
      this.serie_comprobante = item.serie_comprobante;
      this.num_comprobante = item.num_comprobante;
      this.descripcion_caja = item.descripcion_caja;
      this.ruc_comprobante = item.ruc_comprobante;
      this.denom_comprobante = item.denom_comprobante;
      this.fecha = item.fecha;
      this.moneda = item.moneda;
      this.crearPDF();
      this.comprobanteModal = 1;
    },
    /* Fin Mostrar movimiento caja item */
    //#endregion IMPRESION

    limpiar() {
      this.idmovimiento = "";
      this.idsucursal = "";
      this.idusuario = "";
      this.idtipo_movimiento = "";
      this.idtipo_servicio = "";
      this.idtipo_comprobante = "";
      this.comprobante = "";
      this.comprobantes = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";

      this.moneda = "";
      this.importe_total = "";
      this.pago = 0;

      // Detalle Caja
      this.descripcion = "";

      this.fecha_comprobante = moment().format("YYYY-MM-DD");
      this.fechaSelectComprob(this.fecha_comprobante); // X defecto

      this.idpersona = "";
      this.cuenta = "";
      this.ruc_comprobante = "";
      this.denom_comprobante = "";

      this.stringPdf = "";
      this.blobPdf = "";
    },

    //#region PERSONA
    extraerDocumento(num_documento) {
      let me = this;
      me.desabilitarBuscar = true;

      var token =
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImpmcmFpbkBob3RtYWlsLmVzIn0.tT4tLoVKqPHNbwZsFbim_mUkJkz5zZtseefC0s7RBFs";
      if (me.idtipo_documento_pers == 1) {
        fetch(
          "https://dniruc.apisperu.com/api/v1/dni/" +
          num_documento +
          "?token=" +
          token
        )
          .then((response) => response.json())
          .then(function (data) {
            me.desabilitarBuscar = false;
            if (data.message == undefined) {
              me.idubigeo = "000000";
              me.selectUbigeo(me.idubigeo);
              me.nombre = data.apellidoPaterno + " " + data.apellidoMaterno + " " + data.nombres;
            } else {
              me.nombre = "";
              me.snackBar({ cl: "info", msg: data.message }); // Sin resultados
            }
          })
          .catch(function () {
            me.desabilitarBuscar = false;
            me.snackBar({ cl: "error", msg: "Error en el Nº DNI." });
          });
      } else if (me.idtipo_documento_pers == 6) {
        axios.post("api/Personas/Padron", {
          ruc: me.num_documento,
        })
          .then(function (response) {
            me.desabilitarBuscar = false;
            me.idubigeo = response.data.ubigeo;
            me.selectUbigeo(me.idubigeo);
            me.nombre = response.data.razon;
            me.direccion = response.data.direccion == null ? "" : response.data.direccion.trim();
            me.snackBar({ cl: "info", msg: "Contribuyente con estado " + response.data.estado + " y condición " + response.data.condicion });
          })
          .catch(function (error) {
            me.desabilitarBuscar = false;
            me.nombre = "";
            me.direccion = "";
            me.snackBar({ cl: "error", msg: error.response.data });
          });
      }
    },

    guardarPersona() {
      let me = this;
      me.desabilitarPer = true;

      //Código para guardar
      axios.post("api/Personas/Crear", {
        tipo_persona: "Proveedor",
        nombre: me.nombre,
        idtipo_documento: me.idtipo_documento_pers,
        num_documento: me.num_documento,
        idubigeo: me.idubigeo,
        direccion: me.direccion,
        telefono: me.telefono,
        email: me.email,
      })
        .then((response) => {
          me.idpersona = response.data.idpersona;
          me.selectPersona(me.idpersona);
          me.desabilitarPer = false;
          me.snackBar({ cl: "success", msg: response.data.mensaje });
          me.closePersona();
        })
        .catch(function (error) {
          me.desabilitarPer = false;
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    mostrarPersona() {
      this.verPersona = true;
    },

    closePersona() {
      this.verPersona = false;
      this.limpiarPersona();
    },

    limpiarPersona() {
      this.idtipo_documento_pers = "";
      this.num_documento = "";
      this.idubigeo = "";
      this.ubigeos = [];
      this.nombre = "";
      this.direccion = "";
      this.email = "";
      this.telefono = "";
    },
    //#endregion PERSONA

    crearPDF() {
      var columns = [
        {
          header: "DESCRIPCIÓN",
          dataKey: "descripcion",
        },
        {
          header: "IMPORTE",
          dataKey: "pago",
        },
      ];
      var body = [
        {
          descripcion: this.movimiento + " - " + this.descripcion_caja,
          pago: numeral(this.pago).format("###,##0.00"),
        },
      ];
      var foot = [
        {
          descripcion: "Total " + (this.moneda == "S" ? "S/" : "$"),
          pago: numeral(this.pago).format("###,##0.00"),
        },
      ];

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 70, 49.39);
          // Datos empresa
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.datos.empresa, 140, 28);
          doc.setFont("helvetica", "normal");
          doc.setFontSize(8);
          doc.text(this.datos.dirsucursal, 140, 38);
          doc.text("Telf.: " + this.datos.telsucursal, 140, 48);
          doc.text("E-Mail: " + this.datos.mailsucursal, 140, 58);
          // Fin Datos empresa

          // Datos proveedor
          doc.text("Razón Social: " + this.denom_comprobante, 40, 75);
          doc.text("Ruc N°: " + this.ruc_comprobante, 40, 90);
          doc.text("Establecimiento: " + this.datos.sucursal, 420, 75);
          doc.text("Fecha: " + moment(this.fecha).format("DD/MM/YYYY"), 420, 90);
          // Fin Datos proveedor

          // Datos documento
          doc.setFont("helvetica", "bold");
          doc.setFontSize(9);
          doc.text(this.comprobante, doc.internal.pageSize.width / 2 + 140, 42, "center");
          doc.text(this.serie_comprobante + "-" + this.num_comprobante, doc.internal.pageSize.width / 2 + 140, 54, "center");
          // Fin Datos documento
        }
      };

      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(6);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text("Página " + String(i) + " de " + String(pageCount), doc.internal.pageSize.width / 2, 820, "center");
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        //theme: "grid",
        //tableLineWidth: 1,
        columns,
        body,
        foot,
        margin: { top: 100 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 8 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          descripcion: { cellWidth: "auto" },
          pago: { cellWidth: 50, halign: "right" },
        },
      });

      addHeaders(doc);
      addFooters(doc);
      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
    },
  },
};
</script>