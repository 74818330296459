<template>
  <v-container fluid style="max-width: 1280px;">
    <v-layout justify-center>
      <v-flex>
        <v-container>
          <v-row class="mt-10 mb-10" align="center" justify="center">
            <v-col cols="10" md="5" sm="5">
              <!-- URPIFACT -->
              <v-card-title class="text-h4 font-weight-bold justify-center primary--text mb-3">UrpiFact
                Web</v-card-title>
              <v-card-subtitle class="text-h6 font-weight-regular text-justify grey--text">
                UrpiFact hace que la facturación y sus procesos sean faciles. Controla tu negocio y emite comprobantes
                electrónicos de una manera rápida y segura, a través de Internet.
                Ya no tendrás que gastar dinero en papeles.
              </v-card-subtitle>
            </v-col>
            <v-col cols="10" md="5" sm="5">
              <!-- imagen -->
              <v-img :src="require('../assets/web/facturacion.jpg')" height="300px" width="900px"></v-img>
            </v-col>
          </v-row>

          <v-row align="center" justify="center">
            <v-col cols="12">
              <!-- PLANES -->
              <v-card-title class="text-h4 font-weight-bold justify-center primary--text">Planes UrpiFact Web
              </v-card-title>
            </v-col>
            <v-col cols="10" md="5" sm="5" align="center">
              <!-- imagen -->
              <v-card class="mx-auto" max-width="420">
                <v-card-subtitle
                  class="text-h6 font-weight-regular justify-center grey--text">SOFTWARE</v-card-subtitle>
                <v-img :src="require('../assets/web/software.png')" height="100px" width="150px"></v-img>
                <v-card-title class="text-h6 font-weight-bold justify-center primary--text">Facturación Electronica Plus
                </v-card-title>
                <v-card-subtitle class="text-h6 font-weight-regular justify-center grey--text">Mensual S/69.90
                </v-card-subtitle>
                <v-card-subtitle class="text-h7 font-weight-regular text-left grey--text">
                  <v-icon slot="icon" color="primary">done</v-icon>Activación con la SUNAT en 24 horas.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Diseños adaptativo (PC, TABLET, CELULARES).
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Emisión ilimitada de comprobantes de pago
                  electrónico.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Emisión de comprobantes con el logo de tu empresa.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Descarga de reporte estandar (Excel).
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Copias de seguridad automáticas.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Confidencialidad de la información.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Representación impresa en tamaños A4, TICKET.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Hasta 2 usuarios y 1 sucursal.
                  <!-- <br />
                <v-icon slot="icon" color="primary">done</v-icon>Emisión de guías de remisión. -->
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Módulo de ventas, compras, productos, caja, clientes,
                  etc.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Mucho más.
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col cols="10" md="5" sm="5" align="center">
              <!-- imagen -->
              <v-card class="mx-auto" max-width="420">
                <v-card-subtitle
                  class="text-h6 font-weight-regular justify-center grey--text">SOFTWARE</v-card-subtitle>
                <v-img :src="require('../assets/web/software.png')" height="100px" width="150px"></v-img>
                <v-card-title class="text-h6 font-weight-bold justify-center primary--text">Facturación Electronica
                  Premium</v-card-title>
                <v-card-subtitle class="text-h6 font-weight-regular justify-center grey--text">Mensual S/89.90
                </v-card-subtitle>
                <v-card-subtitle class="text-h7 font-weight-regular text-left grey--text">
                  <v-icon slot="icon" color="primary">done</v-icon>Activación con la SUNAT en 24 horas.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Diseños adaptativo (PC, TABLET, CELULARES).
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Emisión ilimitada de comprobantes de pago
                  electrónico.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Emisión de comprobantes con el logo de tu empresa.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Descarga de reporte estandar (Excel).
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Copias de seguridad automáticas.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Confidencialidad de la información.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Representación impresa en tamaños A4, TICKET.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Hasta 5 usuarios y 2 sucursales.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Emisión de guías de remisión.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Módulo de ventas, compras, productos, caja, clientes,
                  etc.
                  <br />
                  <v-icon slot="icon" color="primary">done</v-icon>Mucho más.
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>

          <v-row class="mt-10 mb-10" align="center" justify="center">
            <v-col cols="10" md="5" sm="5">
              <!-- imagen -->
              <v-img :src="require('../assets/web/soporte.jpg')" height="300px" width="900px"></v-img>
            </v-col>
            <v-col cols="10" md="5" sm="5">
              <!-- URPIFACT -->
              <v-card-title class="text-h4 font-weight-bold justify-center primary--text mb-3">Capacitaciones
              </v-card-title>
              <v-card-subtitle class="text-h6 font-weight-regular text-justify grey--text">
                ¿Necesitas ayuda con el manejo del sistema?
                Urpifact pone a tu disposición capacitaciones online mediante la aplicación AnyDesk, nuestros
                representantes te enseñarán todas las funcionalidades de nuestro software.
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-container>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
